import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import store from './app/store';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ScrollToTop from './utils/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';
import LoginModal from './modals/LoginModal';
import RegistrationModal from './modals/RegistrationModal';
import ForgotPasswordModal from './modals/ForgotPasswordModal';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
      >
        <ScrollToTop />
        <Provider store={store}>
          <HelmetProvider>
            <App />
            <LoginModal />
            <RegistrationModal />
            <ForgotPasswordModal />
          </HelmetProvider>
        </Provider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

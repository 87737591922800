import React from 'react';
import Button from '../../components/UI/Button/Button';
import classes from './Verification.module.css';
import successCheck from '../../assets/verification-img/verified.png';
import dangerCheck from '../../assets/verification-img/warning.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmail } from '../../features/user/userSlice';
import { Helmet } from 'react-helmet-async';
const Verification = () => {
  const { tokenError } = useSelector((state) => state.user);
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(verifyEmail(token));
  }, [dispatch, token]);
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | בית חדש לחיים שאחרי</title>
        <meta
          property="og:url"
          content={`https://lifecloud-qr.com/verify-email/${token}`}
        />
      </Helmet>
      <section className={classes.verification_section}>
        <div className={classes.verification_card}>
          <div className={classes.verification_img}>
            <img src={tokenError ? dangerCheck : successCheck} alt="" />
          </div>
          <div className={classes.verification_text}>
            {tokenError ? (
              <h2>Invalid token or has expired </h2>
            ) : (
              <h2> Email Verification Successful</h2>
            )}

            {tokenError ? (
              <p>
                Your token has expired or invalid.
                <br />
                Please sign up or login.
              </p>
            ) : (
              <p>
                Your account has been verified successfully.
                <br /> You can now login to your account.
              </p>
            )}

            <Button onClick={() => navigate('/login')}>Login</Button>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Verification;

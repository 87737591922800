import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/UI/Button/Button';
import SocialLogin from '../../components/SocialLogin/SocialLogin';
import { userLogin } from '../../features/user/userSlice';
import toastCreator from '../../utils/toastifyCreator';
import classes from './Auth.module.css';
import InputError from '../../components/InputError/InputError';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import { Helmet } from 'react-helmet-async';
import { hideModal, showModal } from '../../features/modal/modalSlice';

//Form validation schema
const schema = yup.object().shape({
  email: yup
    .string()
    .email('!המייל שהוזן אינו תקין*')
    .required('!מייל הינו שדה חובה*'),
  password: yup
    .string()
    .required('!דרושה סיסמא*')
    .min(6, '!הסיסמא צריכה להכיל לפחות 6 תווים*'),
  phone: yup.string(),
});

const Login = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | התחברות לאתר</title>
        <meta property="og:title" content="LifeCloud | התחברות לאתר" />
        <meta property="og:url" content="https://lifecloud-qr.com/login" />
        <meta
          name="description"
          content="ליצירת עמוד הנצחה בפלטפורמה יש להתחבר למשתמש באתר, לאחר ההתחברות ניתן לשתף זכרונות, להגיב, להדליק נרות ולהניח פרחים."
        />
        <meta
          property="og:description"
          content="ליצירת עמוד הנצחה בפלטפורמה יש להתחבר למשתמש באתר, לאחר ההתחברות ניתן לשתף זכרונות, להגיב, להדליק נרות ולהניח פרחים."
        />
      </Helmet>
      <CenterContentLayout cloudPos="right-left">
        <LoginBody />
      </CenterContentLayout>
    </React.Fragment>
  );
};
export const LoginBody = ({ modal }) => {
  const location = useLocation();
  const { loading } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const hideAuthModal = () => {
    dispatch(hideModal('loginModal'));
  };
  const showNotification = (status, payload) => {
    if (status === 'error') return toastCreator('error', payload);
    hideAuthModal();
    navigate(location.pathname);
  };

  /* Form Submit Handler */
  const onSubmit = async (formData) => {
    dispatch(userLogin({ formData, showNotification }));
  };
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className={`${modal ? 'col-12' : 'col-xxl-10 mx-auto'}`}>
            {!modal && (
              <React.Fragment>
                <h2 className="page_title">ברוכים השבים</h2>
                <h3 className={classes.login_subtitle} dir="rtl">
                  על מנת לבצע פעולות באתר ולרגש אחרים, יש להתחבר או להרשם
                </h3>
              </React.Fragment>
            )}
            <div className={`row ${classes.form}`}>
              <div
                className={`col-xl-3 col-lg-4 order-lg-1 order-2 ${classes.left_panel}`}
              >
                <div className="d-flex flex-column justify-content-between h-100">
                  <div>
                    <h3 dir="rtl">אינכם רשומים עדיין?</h3>
                    {modal ? (
                      <Button
                        className={`w-100 ${classes.form_btn}`}
                        onClick={() => {
                          dispatch(showModal('registrationModal'));
                        }}
                      >
                        להרשמה
                      </Button>
                    ) : (
                      <Link to="/registration">
                        <Button className={`w-100 ${classes.form_btn}`}>
                          להרשמה
                        </Button>
                      </Link>
                    )}
                  </div>
                  <hr />
                  <div className={classes.need_help}>
                    <h4 dir="rtl">צריכים עזרה?</h4>
                    <h5 dir="rtl">כמה קישורים שיוכלו לעזור!</h5>
                    {modal ? (
                      <span
                        onClick={() => {
                          dispatch(showModal('forgotPasswordModal'));
                        }}
                      >
                        שכחתי את הסיסמא
                      </span>
                    ) : (
                      <Link to="/forgot-password" onClick={hideAuthModal}>
                        שכחתי את הסיסמא
                      </Link>
                    )}

                    <Link to="/contact" onClick={hideAuthModal}>
                      יצירת קשר לקבלת סיוע
                    </Link>
                    <Link to="/faq" onClick={hideAuthModal}>
                      שאלות ותשובות נפוצות
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className={`col-xl-9 col-lg-8 order-lg-2 order-1 ${classes.right_panel}`}
              >
                <h2>התחברות משתמשים קיימים</h2>
                <SocialLogin />
                <hr />
                <h4>או התחברו באמצעות אימייל וסיסמא</h4>​
                <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-md-12">
                    <div className={classes.input_container}>
                      <input
                        type="email"
                        {...register('email')}
                        placeholder="*מייל..."
                        className={`form-control ${classes.input}`}
                      />
                      <InputError error={errors.email?.message} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className={classes.input_container}>
                      <input
                        type="password"
                        {...register('password')}
                        placeholder="*בחרו סיסמא..."
                        className={`form-control ${classes.input}`}
                      />

                      <InputError error={errors.password?.message} />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <Button
                      className={`w-100 ${classes.submit_btn}`}
                      // loading={loading}
                    >
                      התחברות 
                    </Button>

                    {modal ? (
                      <span
                        onClick={() => {
                          dispatch(showModal('forgotPasswordModal'));
                        }}
                        className={classes.forgot_password}
                        role="button"
                      >
                        שכחתי סיסמא
                      </span>
                    ) : (
                      <Link
                        to="/forgot-password"
                        className={classes.forgot_password}
                        onClick={hideAuthModal}
                      >
                        שכחתי סיסמא
                      </Link>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;

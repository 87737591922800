import React from 'react';

const AllModals = () => {
  return (
    <div>
      <h1 className="display-1">HI There! ✋</h1>
    </div>
  );
};

export default AllModals;

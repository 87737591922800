const emailTextAndSubject = (type, profileInfo, userInfo = {}, candleFlowerInfo = undefined) => {
  
  // candleFlowerInfo = isGuest boolean name
  
  const profileName = profileInfo.organizationName
    ? profileInfo.organizationName
    : `${profileInfo.firstName} ${profileInfo.lastName}`;
  const userName = userInfo.organizationName
    ? userInfo.organizationName
    : `${userInfo.firstName} ${userInfo.lastName}`;
  /* 
  subject= Email Subject
  emailText= Email Body
  text=> Site Notification Text
   */
  switch (type) {
    case 'friendRequests':
      return {
        type,
        subject: `LifeCloud | חברים חדשים רוצים להתחבר 👥`,
        text: `${userName}  מבקש/ת לקחת חלק בהנצחה ולהצטרף לקהילה לזכר ${profileName}
         ז"ל`,
        // ${profileInfo.profileImg} ${userInfo.profilePicture}
        emailText: `בנייה והקמה של קהילה חזקה לזכרם של יקירנו עוזרת להנציח את מורשתם וסיפור חייהם. הקהילה תאפשר בנוחות לתמוך במשפחה, החברים והמכרים ולשתף את הזכרונות והחוויות עם כולם.`,
        emailBody: `בנייה והקמה של קהילה חאנו ממליצים להוסיף את כל האנשים הרלוונטים לקהילה המנציחה על מנת שתוכלו להנות מעמוד מכובד ועשיר בתכנים וזיכרונות אשר ישמש אתכם, את המשפחה והחברים למשך שנים..`,
      };
    case 'profileAdmins':
      return {
        type,
        subject: `LifeCloud | ברכות על המינוי החדש`,
        text: `קיבלת מינוי למנהל בעמוד של ${profileName}`,
        emailText: `קיבלת מינוי חדש כמנהל בפרופיל של ${profileName}. כעת ניתן לבצע פעולות מנהליות בעמוד ולהשפיע.`,
        emailBody: `בתור מנהלי קהילה בעמוד פרופיל של יקירכם, חשוב ליצור מרחב נוח ותומך לחברים ומשפחה על ידי ניהול נכון. ניתן להרחיב או לסנן את הקהילה כרצונכם ובנוסף לערוך את העמוד ולהוסיף תכנים חדשים.`,
      };
    case 'profileFriends':
      return {
        type,
        subject: `LifeCloud | ברוכים הבאים לקהילה החדשה שלכם 🏘️️`,
        text: `ברכות חמות על הצטרפותך לקהילה שהוקמה לזכר  ${profileName}
        ז"ל`,
        // ${profileInfo.profileImg} ${userInfo.profilePicture}
        emailText:`בקשתך להצטרף לקהילה לזכר ${profileName} ז"ל אושרה בואו לשתף חוויות ולהנציח את סיפור החיים של יקיריכם! `,
        emailBody: `בנייה והקמה שלחברים בעמוד הפרופיל ישנה חשיבות גדולה בתור שותפים לקהילה המנצחיה. בעזרת שיתופי חוויות, העלאת תמונות וסירטונים, הפצת הפרופיל לחברים חדשים נשמר את מורשתם של יקירנו שאינם עוד איתנו.`,
      };
    case 'newMemeberJoined':
      return {
        type,
        text: ` `,
        subject: `${userName} הצטרף לקהילה לזכר ${profileName} ז"ל 🏘️`,
        emailBody: ` `
        //`${userInfo.profilePicture? userInfo.profilePicture : profileName }`,
      };
    case 'welcomeToCommunity':
      return {
        type,
        subject: ` LifeCloud | ברוכים הבאים לקהילה החדשה שלכם 🏘️️️️`,
        text: `ברכות חמות על הצטרפותך לעמוד שהוקם לזכר ${profileInfo.firstName} ${profileInfo.lastName} ז"ל`,
        emailBody: ` `
        // ${profileInfo.profileImg}
        
      };
        
    case 'profileVisit':
      return {
        type,
        subject: `LifeCloud | הפרופיל שלכם מקבל ביקורים חדשים`,
        text: `${userName} ביקר בעמוד של ${profileName}`,
        emailText: `${userName} ביקר בעמוד של ${profileName}`,
        emailBody: `ביקורים של חברים בעמוד הפרופיל של יקירינו הם יקרי ערך, הם מביעים את השתתפותם בהמשך זכרונו של האדם שאיבדנו והשארתו בתודעה לדורות הבאים והמשך סיפור חייהם.`,
      };
      
    case 'memoryCreation':
      return {
        type,
        subject: `LifeCloud | נוסף זיכרון חדש ע"י ${userName} 📝`,
        text: `${userName} כתב/ה זיכרון חדש בעמוד של ${profileName}
        ז"ל
        `,
        //${profileInfo.profileImg} ${userInfo.profilePicture}
        emailText: `כתיבת זכרונות מחייהם של יקירנו מחממים לנו את הלב, מעשירים את העמוד ומגדילה את הסיפור המפורסם של האנשים שכבר לא יספרו את סיפורם.`,
        emailBody: `הנצחת מנוח יכולה להיות אישית בליבנו, אך לשיתוף זיכרונות בעמוד יש חשיבות ענקית ואנו שואפים לשתף כמה שיותר סיפורים מהחיים כדי לחלוק עם הסובבים את האדם שהיה מנקודת המבט שלנו ואת הסיפורים שלא יסופרו מלבד כאן.`,
      };
      
    case 'candleFlower':
      return {
        type,
        subject: `LifeCloud | נר או פרח חדשים הונחו בפרופיל`,
        text: `נר או פרח חדשים הודלקו בעמוד של ${profileName} על ידי ${userName}`,
        emailText: `בעמוד של ${profileName} הונחו נר או פרח חדשים על ידי ${userName}. בואו להציץ בפרופיל!`,
        emailBody: `הדלקת נר או הנחת פרח לזכרם של יקירנו על ידי חברים היא מקבילה לאותה פעולה בקבר עצמו, רק שבלייף קלאוד - כל החברים מקבלים התראה ומעלים את הפרופיל חזרה לתודעה.`,
      };
    case 'memoryComment':
      return {
        type,
        subject: `LifeCloud | תגובה חדשה נוספה בזיכרון ע"י ${userName}💭 `,
        text: ` $ {userName} הגיב/ה על זיכרון שרשמת בעמוד לזכר ${profileName}  ז"ל`,
        // ${profileInfo.profileImg} ${userInfo.profilePicture}
        emailText: `פעילות בעמוד של יקירינו כמו שיתוף זכרונות או תגובות על זכרונות הן הדרך של חברינו להביע את כבודם ובנוסף, הדרך שלהם גם להמשיך את מורשתם בתודעה.`,
        emailBody: `בהנצחת יקירנו רצוי לחלוק את המלאכה עם כמה שיותר בני משפחה וחברים מסביבתנו מאחר וסיפור חייו של המנוח לא מסתכם במילים בודדות. ככל שנהיה מעורבים יותר כך העמוד יהיה מכובד ועשיר, לכן כאשר אחד מהמכרים מגיב על זיכרון נרצה להגיב ולכבד אותו בחזרה.`,
      };
    case 'memorialDay ':
      return {
        type,
        subject: `LifeCloud | יום אזכרה של ${profileName} מתקרב`,
        text: `יום האזכרה של ${profileName} מתקרב ויתקיים בעוד 10 ימים.`,
        emailText: `יום האזכרה של ${profileName} מתקיים בעוד 10 ימים. שתפו את הסיפורים בעמוד כדי שהזכרונות יונצחו עם כולם.`,
        emailBody: `יום האזכרה הוא יום השנה לזכור ולכבד את יקירינו שאינם כבר איתנו. ביום זה אנשים נוהגים לעלות לקבר ולהביע את הערכתם, במידה ולא באפשרותכם להגיע אנו ממליצים להביע את השתתפותכם בעמוד על ידי שיתוף זיכרון, הדלקת נר, הנחת פרח או הודעה למשפחה.`,
      };
    case 'qrScan ':
      return {
        type,
        subject: `LifeCloud | זכרון חדש נוסף לפרופיל`,
        text: `מישהו ביקר בקבר של ${profileName} וסרק את הQR.`,
        emailText: `מישהו ביקר בקבר של ${profileName} וסרק את הQR.`,
        emailBody: `ביקור בקבר יכול להיות חוויה עוצמתית. בשביל חלקנו, זה יכול להיות המקום הכי קרוב לאדם שאינו עוד איתנו ובשביל אחרים זה יכול להיות המקום הכי נוח להעלות זכרונות ומחשבות בשלווה. הביקור בקבר לרוב מסמל הערכה לאדם וחשיבותו למבקר.`,
      };

    default:
      return {
        type: '',
        subject: '',
        text: ' ',
        emailText: '',
      };
  }
};
export default emailTextAndSubject;

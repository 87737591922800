import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import lifeAxios from '../../utils/lifeAxios';

/* Initial State for blog */
const initialState = {
  blogs: [],
  blog: {},
  loading: false,
  error: '',
  blogLoading: false,
};

/* For Create blog */
export const createBlog = createAsyncThunk(
  'blog/createBlog',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/blogs/`,
        formData
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* For Getting Single blog */
export const getBlog = createAsyncThunk(
  'blog/getBlog',
  async (blogId, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/blogs/${blogId}`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Update blogs */
export const updateBlog = createAsyncThunk(
  'blog/updateBlog',
  async ({ formData, blogId }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/blogs/${blogId}`,
        formData
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* For Getting All blogs */
export const getAllBlogs = createAsyncThunk(
  'blog/getAllBlogs',
  async (_, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/blogs`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* Send Blog Contact */
export const sendBlogContact = createAsyncThunk(
  'blog/sendBlogContact',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/blogs/contact`,
        formData
      );
      return response.data.status;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const blogSlice = createSlice({
  name: 'blog',
  initialState,

  extraReducers: (builder) => {
    /* Single blog */
    builder.addCase(getBlog.pending, (state, action) => {
      state.blog = {};
      state.blogLoading = true;
    });
    builder.addCase(getBlog.fulfilled, (state, action) => {
      state.blog = action.payload;
      state.blogLoading = false;
    });
    builder.addCase(getBlog.rejected, (state, action) => {
      state.blog = {};
      state.error = action.payload;
      state.blogLoading = false;
    });
    /* All blogs */
    builder.addMatcher(isAnyOf(getAllBlogs.pending), (state, action) => {
      state.blogs = [];
      state.blogLoading = true;
    });
    builder.addMatcher(isAnyOf(getAllBlogs.fulfilled), (state, action) => {
      state.blogs = action.payload;
      state.blogLoading = false;
    });
    builder.addMatcher(isAnyOf(getAllBlogs.rejected), (state, action) => {
      state.blogs = [];
      state.error = action.payload;
      state.blogLoading = false;
    });
    /* Rest Builder */
    builder.addMatcher(
      isAnyOf(createBlog.pending, sendBlogContact.pending, updateBlog.pending),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        createBlog.fulfilled,
        sendBlogContact.fulfilled,
        updateBlog.fulfilled
      ),
      (state) => {
        state.loading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        createBlog.rejected,
        sendBlogContact.rejected,
        updateBlog.rejected
      ),
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default blogSlice.reducer;

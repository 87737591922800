import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import lifeAxios from '../../utils/lifeAxios';
import { add_analytics } from "../gtagFunction.js";

/* Initial State for profile */
const initialState = {
  organizationProfile: null,
  loading: false,
  error: '',
  organizationProfileLoading: false,
};
/* For Getting Single Profile */
export const getOrganizationProfile = createAsyncThunk(
  'organizationProfile/getOrganizationProfile',
  async (profileId, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/organizationProfiles/${profileId}`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* For Create Profile */
export const createOrganizationProfile = createAsyncThunk(
  'organizationProfile/createOrganizationProfile',
  async ({ formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/organizationProfiles`,
        formData
      );
      try {
        add_analytics("Create-Organization-Profile", response, "none");
      }
      catch (ex) {
        console.error(ex.message);
      }  

      showNotification('success', response.data.data.data._id);
      return response.data.data.data;
    } 
    catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* For Edit Profile */
export const editOrganizationProfile = createAsyncThunk(
  'organizationProfile/editOrganizationProfile',
  async ({ formData, profileId, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/organizationProfiles/${profileId}`,
        formData
      );
      showNotification('success');
      return response.data.data.data;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* For Getting All Profiles of the user*/
export const getUserProfile = createAsyncThunk(
  'organizationProfile/getUserProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/organizationProfiles/user/myProfiles`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* Add User to profile */
export const addOrganizationUser = createAsyncThunk(
  'organizationProfile/addUser',
  async ({ formData, showNotification }, { rejectWithValue, dispatch }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/organizationProfiles/${formData.profileId}/add`,
        formData
      );

      const user = response.data.data.data[formData.field].find(
        (user) => user._id === formData.userId
      );
      let message = '';
      if (formData.field === 'friendRequests')
        message = 'בקשת חברות נשלחה בהצלחה';
      else if (formData.field === 'profileAdmins')
        message = `${
          user.organizationName || user.firstName
        } קיבל.ה הרשאת מנהל.ת`;
      else if (formData.field === 'profileFriends')
        message = `${user.organizationName || user.firstName} עכשיו חבר.ה`;

      showNotification(
        'success',
        message,
        formData.field !== 'friendRequests' && {
          ...user,
          field: formData.field,
        }
      );

      dispatch(getOrganizationProfile(formData.profileId));
      return response.data.data.data;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Remove User to profile */
export const removeOrganizationUser = createAsyncThunk(
  'organizationProfile/removeUser',
  async ({ formData, showNotification }, { rejectWithValue, dispatch }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/organizationProfiles/${formData.profileId}/remove`,
        formData
      );
      dispatch(getOrganizationProfile(formData.profileId));
      return response.data.data.data;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
const organizationProfileSlice = createSlice({
  name: 'organizationProfile',
  initialState,
  extraReducers: (builder) => {
    /* All Profiles */
    builder.addMatcher(
      isAnyOf(getUserProfile.pending, getOrganizationProfile.pending),
      (state, action) => {
        state.organizationProfileLoading = true;
        state.organizationProfile = null;
      }
    );
    builder.addMatcher(
      isAnyOf(getUserProfile.fulfilled, getOrganizationProfile.fulfilled),
      (state, action) => {
        state.organizationProfile = action.payload;
        state.organizationProfileLoading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(getUserProfile.rejected, getOrganizationProfile.rejected),
      (state, action) => {
        state.organizationProfile = null;
        state.error = action.payload;
        state.organizationProfileLoading = false;
      }
    );
    /* Rest Builder */
    builder.addMatcher(
      isAnyOf(
        createOrganizationProfile.pending,
        editOrganizationProfile.pending,
        addOrganizationUser.pending,
        removeOrganizationUser.pending
      ),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        createOrganizationProfile.fulfilled,
        editOrganizationProfile.fulfilled,
        addOrganizationUser.fulfilled,
        removeOrganizationUser.fulfilled
      ),
      (state) => {
        state.loading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        createOrganizationProfile.rejected,
        editOrganizationProfile.rejected,
        addOrganizationUser.rejected,
        removeOrganizationUser.rejected
      ),
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default organizationProfileSlice.reducer;

import React from 'react';
import classes from './Review.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import Section from '../layout/Section';
const Review = () => {
  return (
    <Section
      className={classes.review}
      gap={false}
      padding
      colorful
      cloudPos="down-right"
    >
      <div className="container">
        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          navigation={true}
          className="reviewSwiper"
        >
          <SwiperSlide>
            <div className={classes.review_text}>
              <h3>"העמוד של אמא נראה ממש כמו הפייסבוק שמעולם לא היה לה"</h3>
              <h5>-אריאל שמחה-</h5>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={classes.review_text}>
              <h3>
                "הבנתי שאם אני לא מקימה פרופיל לאמא שלי בלייף קלאוד, נכדי לא
                יזכו להכיר אותה"
              </h3>
              <h5>-עידית צעירי-</h5>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={classes.review_text} dir="rtl">
              <h3>
                "בזכות העלאה ושיתוף סיפורים תמונות וסרטונים של חברים ומכרים,
                נחשפתי לצדדים חדשים ומרגשים של אהובי"
              </h3>
              <h5>-ס״א-</h5>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={classes.review_text} dir="rtl">
              <h3>
                "צפיתי שוב ושוב בפרופיל של אימי, הלילה מרוב התרגשות לא נרדמתי.
                תודה רבה!"
              </h3>
              <h5>-הגר יצחקי-</h5>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </Section>
  );
};

export default Review;

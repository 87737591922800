import React from 'react';
import { useForm } from 'react-hook-form';
import classes from '../Form.module.css';
import MainLayout from '../../components/layout/MainLayout';
import arrow from '../../assets/registration-img/arrow.png';
import Button from '../../components/UI/Button/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../features/user/userSlice';
import toastCreator from '../../utils/toastifyCreator';
import alertCreator from '../../utils/alertCreator';
import InputError from '../../components/InputError/InputError';
import { Helmet } from 'react-helmet-async';
import { hideModal, showModal } from '../../features/modal/modalSlice';

//Form validation schema
const schema = yup.object().shape({
  email: yup
    .string()
    .email('*Email is invalid!')
    .required('!מייל הינו שדה חובה*'),
});

const ForgotPassword = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title dir="rtl">LifeCloud | ?שכחת את הסיסמא</title>
        <meta
          property="og:url"
          content="https://lifecloud-qr.com/forgot-password"
        />
      </Helmet>
      <MainLayout gap={true}>
        <ForgotPasswordBody />
      </MainLayout>
    </React.Fragment>
  );
};

export const ForgotPasswordBody = ({ modal }) => {
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const showNotification = (status, payload) => {
    if (status === 'error') return toastCreator('error', payload);
    alertCreator({
      icon: 'success',
      title: 'לינק לאיפוס סיסמתכם נשלח למייל',
      text: 'יש לבדוק את תיבה 📧',
    });
  };
  const onSubmit = async (formData) => {
    dispatch(hideModal('forgotPasswordModal'));
    dispatch(forgotPassword({ formData, showNotification }));
  };
  return (
    <section className={`${modal && classes.forgot_modal}`}>
      <div className="container">
        <div className="row">
          <div className={`${modal ? 'col-12' : 'col-xxl-10 mx-auto'}`}>
            {!modal && (
              <h2 className={`page_title`} dir="rtl">
                שכחת את הסיסמא?
              </h2>
            )}

            <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12">
                  <div className={classes.input_container}>
                    <input
                      type="email"
                      {...register('email')}
                      placeholder="*מייל"
                      className={`form-control ${classes.input} ${
                        modal && classes.modal_input
                      }`}
                    />
                    <InputError error={errors.email?.message} />
                  </div>
                </div>
                <div>
                  {modal ? (
                    <span
                      onClick={() => {
                        dispatch(showModal('loginModal'));
                      }}
                      className={classes.form_footer_text}
                      role="button"
                    >
                      התחברות (משתמש קיים)
                    </span>
                  ) : (
                    <Link to="/login" className={classes.form_footer_text}>
                      התחברות (משתמש קיים)
                    </Link>
                  )}
                </div>
                <div className="col-12">
                  <Button
                    className={`w-100 ${classes.submit_btn}`}
                    loading={loading}
                  >
                    שליחה
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;

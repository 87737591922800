import React from 'react';
const InputError = ({ error, message, className }) => {
  return (
    <React.Fragment>
      {error && (
        <small className={`${className} error`}>{message || error}</small>
      )}
    </React.Fragment>
  );
};

export default InputError;

import React, { useState } from 'react';
import Section from '../layout/Section';
import classes from './ProfileLifeAxis.module.css';
import axisImg from '../../assets/profile-life-axis-img/axis.png';
import Button from '../UI/Button/Button';
import Linkify from 'react-linkify';

const ProfileLifeAxis = ({ profile }) => {
  const { lifeAxis } = profile;
  const [showAll, setShowAll] = useState(false);
  const lifeAxisList = showAll ? lifeAxis : lifeAxis.slice(0, 1);
  return (
    <Section
      className={classes.life_axis}
      bg="#dcecf4"
      colorful={false}
      cloudPos="down-right"
      gap={false}
      padding
    >
      <div className="container">
        <h2 className="profile_title">נקודות ציון</h2>
        <div className="row">
          <div
            className={`col-lg-6 col-md-8 mx-auto ${classes.axis_container}`}
          >
            {lifeAxisList.length > 0 ? (
              lifeAxisList.map((axis, i) => (
                <div
                  key={i}
                  className={`${classes.life_axis_item} d-flex align-items-center justify-content-end gap-4`}
                >
                  <div>
                    <h3 dir="rtl">{axis.axisTitle}</h3>
                    <p dir="rtl">
                      <Linkify>{axis.axisDescription}</Linkify>
                    </p>
                    <p className={` ${classes.life_axis_date}`} dir="rtl">
                      {axis.axisDate}
                    </p>
                  </div>
                  <div>
                    <img src={axis.axisImage || axisImg} alt="life axis" />
                  </div>
                </div>
              ))
            ) : (
              <h3 className="not_found" dir="rtl">
                לא נוספו נקודות ציון, כדאי להוסיף ☁
              </h3>
            )}
            <div className="text-center">
              {lifeAxis.length > 1 && (
                <Button
                  outline={true}
                  onClick={() => setShowAll((prev) => !prev)}
                >
                  {showAll ? 'הראה פחות' : 'הראה עוד'}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ProfileLifeAxis;

import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { hideModal } from '../features/modal/modalSlice';
import { makeNotificationsViewed } from '../features/notification/notificationSlice';
import { NotificationBody } from '../pages/Notification/Notification';
import classes from './NotificationModal.module.css';

const NotificationModal = () => {
  const { notificationModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  return (
    <div>
      <Modal
        show={notificationModal}
        onHide={() => {
          dispatch(makeNotificationsViewed());
          dispatch(hideModal('notificationModal'));
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="qr_modal"
      >
        <Modal.Header
          closeButton
          style={{
            background: '#DCECF4',
            padding: '1.5rem 1rem',
          }}
        >
          <Modal.Title className={classes.title}>התראות</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NotificationBody
            modal={true}
            className={classes.notification_modal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NotificationModal;

import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import classes from './SocialFooter.module.css';
import Section from '../layout/Section';
import facebook from '../../assets/social-footer-img/facebook.png';
import instagram from '../../assets/social-footer-img/instagram.png';
const SocialFooter = ({
  cloudPos = 'down-right',
  bg = '#dcecf4',
  showCloud = true,
}) => {
  return (
    <Section
      className={`${classes.social_footer}`}
      bg={bg}
      gap={false}
      padding
      showCloud={showCloud}
      cloudPos={cloudPos}
      colorful={false}
    >
      <h2 className={`text-center ${bg === '#1A4E74' && 'text-white'}`}>
        עקבו אחרינו
      </h2>
      <ul className={classes.social_links}>
        <li>
          <a
            href="https://www.facebook.com/lifecloudqr/"
            target="_blank"
            rel="noreferrer"
          >
            {/* <FaFacebookF /> */}
            <img src={facebook} alt="Facebook" height="50px" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/lifecloudqr/"
            target="_blank"
            rel="noreferrer"
          >
            {/* <FaInstagram /> */}
            <img src={instagram} alt="Instagram" height="50px" />
          </a>
        </li>
      </ul>
    </Section>
  );
};

export default SocialFooter;

import React, { useEffect, useRef, useState } from 'react';
import menubar from '../../assets/header-img/bar.png';
import SearchShow from '../SearchShow/SearchShow';
import menucross from '../../assets/header-img/bar_cross.png';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classes from './Header.module.css';
import { DebounceInput } from 'react-debounce-input';
import { searchProfiles } from '../../features/profile/profileSlice';
import { NavLink, useNavigate } from 'react-router-dom';
import NotificationModal from '../../modals/NotificationModal';
import { showModal } from '../../features/modal/modalSlice';
import { userLogout } from '../../features/user/userSlice';
import TeaserVideoModal from '../TeaserVideoModal/TeaserVideoModal';
import { googleLogout } from '@react-oauth/google';
import {
  getMyNotifications,
  makeNotificationsViewed,
} from '../../features/notification/notificationSlice';
import HeaderNotification from './HeaderNotification';
const Header = ({ bg }) => {
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const [isSearchShown, setIsSearchShown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showNav, setShowNav] = useState(false);

  const handleSearchInput = (event) => {
    setSearchValue(event.target.value);
    dispatch(searchProfiles(event.target.value));
  };
  const navigate = useNavigate();
  const handleSearch = (event) => {
    event.preventDefault();
    setIsSearchShown(false);
    navigate(`/search/${searchValue}`);
  };
  const handleLogout = async () => {
    await googleLogout();
    await dispatch(userLogout());
    window.location.reload();
  };
  const { user, loading } = useSelector((state) => state.user);

  return (
    <header
      className={classes.header}
      style={{ backgroundClip: bg ? bg : 'transparent' }}
    >
      {!user._id && !loading && (
        <div
          className={classes.login_remainder}
          onClick={() => dispatch(showModal('registrationModal'))}
          role="button"
        >
          <p>
            להצטרפות <span>ללא עלות</span> וביצוע פעולות יש להירשם / להתחבר
          </p>
        </div>
      )}
      <div className={classes.wrapper}>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Link to="/">
              <img
                src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1676458846/logo-blue_1_q4df1s.png"
                alt="LifeCloud Logo"
                className={classes.logo}
              />
            </Link>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-3">
            {/* Desktop Search */}

            <form
              className={`d-md-block d-none ${classes.search}`}
              role="search"
              onSubmit={(e) => handleSearch(e)}
            >
              <DebounceInput
                value={searchValue}
                type="search"
                aria-label="Search"
                onChange={handleSearchInput}
                placeholder="חיפוש פרופיל / עמותה"
                className={`form-control ${classes.desktop_input}`}
                minLength={2}
                debounceTimeout={500}
                inputRef={inputRef}
              />
              <SearchShow
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                className={classes.search_results}
              />
            </form>
            {/* Mobile Search */}
            <form
              className={`d-md-none d-block  ${classes.search2} ${classes.form}`}
              role="search"
              onSubmit={(e) => handleSearch(e)}
            >
              <div
                className={`${classes.search_wrapper}  ${
                  isSearchShown && classes.active
                }`}
              >
                {
                isSearchShown&& 
                <DebounceInput
                  value={searchValue}
                  type="search"
                  aria-label="Search"
                  onChange={handleSearchInput}
                  placeholder="...חיפוש"
                  className={` ${classes.search_input} form-control`}
                  minLength={2}
                  debounceTimeout={500}
                />
                }
                <SearchShow
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                  className={classes.search_results2}
                />
              </div>
            </form>

            <button
              className={`${classes.search_btn} btn p-0`}
              onClick={() => {
                setIsSearchShown(!isSearchShown);
                inputRef.current.focus();
              }}
              type="button"
            >
              <img
                src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1676725168/search_1_2_jrx397.png"
                alt="search"
              />
            </button>
            {user._id ? (
              <React.Fragment>
                <Link to="/create-profile" className="d-md-block d-none">
                  <button className={classes.header_btn}>יצירת פרופיל</button>
                </Link>
                <HeaderNotification />
                <Link to={user.isOrgAdmin?"/organization-user-profile":"/user-profile"}>
                  <img
                    src={user.profilePicture}
                    alt="User"
                    className={classes.user_img}
                  />
                </Link>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <button
                  className={classes.header_btn}
                  onClick={() => dispatch(showModal('loginModal'))}
                >
                  יצירת פרופיל
                </button>

                <button
                  onClick={() => dispatch(showModal('registrationModal'))}
                  className={`${classes.header_btn} ${classes.header_btn_active}`}
                >
                  הרשמה
                </button>
              </React.Fragment>
            )}

            <div className="mt-md-2 ms-md-3 position-relative">
              <img
                src={showNav ? menucross : menubar}
                alt="Menu Burger"
                className={classes.menu_burger}
                onClick={() => setShowNav(!showNav)}
              />
              {showNav && (
                <ul className={classes.navigation}>
                  <li>
                    <Link to="/create-profile">יצירת פרופיל</Link>
                  </li>
                  <li>
                    <span
                      onClick={() => dispatch(showModal('teaserVideoModal'))}
                    >
                      סירטון הדרכה
                    </span>
                  </li>
                  <li>
                    <a href="https://api.whatsapp.com/send/?text=ראיתי%20אתר%20מדהים%20שקשור%20להנצחה%20שבטוח%20תאהבו,%20כנסו%20לראות:%0ahttps://lifecloud-qr.com/qr-landing%20☁️">
                      שיתוף האתר
                    </a>
                  </li>
                  <li>
                    <Link to="/about">אודות</Link>
                  </li>
                  <li>
                    <Link to="/contact">צרו קשר</Link>
                  </li>

                  {user._id ? (
                    <React.Fragment>
                      <li>
                        <Link to={user.isOrgAdmin?"/organization-user-profile":"/user-profile"}>איזור אישי</Link>
                      </li>
                      <li>
                        <Link to="/" onClick={handleLogout}>
                          התנתקות
                        </Link>
                      </li>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <li>
                        <span onClick={() => dispatch(showModal('loginModal'))}>
                          התחברות
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() =>
                            dispatch(showModal('registrationModal'))
                          }
                        >
                          הרשמה
                        </span>
                      </li>
                    </React.Fragment>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <TeaserVideoModal />
      <NotificationModal />
    </header>
  );
};

export default Header;

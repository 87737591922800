import React, { useState } from 'react';
import Button from '../../components/UI/Button/Button';
import classes from './OrganizationProfileCreate.module.css';
import formClasses from '../../pages/Form.module.css';
import uploadImg from '../../assets/profile-create-img/upload.svg';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ProfileAccordion from '../../components/ProfileAccordion/ProfileAccordion';
import toastCreator from '../../utils/toastifyCreator';
import alertCreator from '../../utils/alertCreator';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import useGeoLocation from '../../utils/useGeoLocation';
import location_icon from '../../assets/profile-create-img/location.png';
import { createOrganizationProfile } from '../../features/organizationProfile/organizationProfileSlice';
import { Helmet } from 'react-helmet-async';

const OrganizationProfileCreate = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.organizationProfile);
  const dispatch = useDispatch();
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [profileImg, setProfileImg] = useState('');
  const [wallImg, setWallImg] = useState('');
  const [gallery, setGallery] = useState([]);
  const [privacy, setPrivacy] = useState('public');
  const [cemeteryLocation, setCemeteryLocation] = useState('');
  const getLatLng = (lat, lng) => {
    setCemeteryLocation(`${lat},${lng}`);
  };
  const { location, getGeoLocation } = useGeoLocation({ getLatLng });

  const getCurrentGeoLocation = () => {
    getGeoLocation();
    toggleAccordion('privacyAccordion');
  };

  const [accordion, setAccordion] = useState({
    profileAccordion: true,
    wallAccordion: false,
    galleryAccordion: false,
    mapAccordion: false,
    privacyAccordion: false,
  });

  const toggleAccordion = (accordionName) => {
    setAccordion((prev) => ({
      ...prev,
      [accordionName]: true,
    }));
  };

  const onChangeProfilePicture = (event, setImg) => {
    if (event.target.files[0]) {
      const src = URL.createObjectURL(event.target.files[0]);
      setProfileImg(src);
      toggleAccordion('wallAccordion');
    }
  };
  const onChangeWallPicture = (event, setImg) => {
    if (event.target.files[0]) {
      const src = URL.createObjectURL(event.target.files[0]);
      setWallImg(src);
    }
  };
  const onChangeGalleryPicture = (event, setImg) => {
    if (event.target.files.length > 0) {
      setGallery((prev) => [...prev, ...event.target.files]);
      toggleAccordion('descriptionAccordion');
    }
  };
  const onRemoveGallery = (index) => {
    setGallery((prev) => prev.filter((img, i) => i !== index));
  };

  const showNotification = (status, payload) => {
    if (status === 'error') return toastCreator('error', payload);
    alertCreator({
      icon: 'success',
      title: 'הפרופיל נשמר בהצלחה, כעת ניתן לשתפו עם חברים',
    }).then(() => {
      navigate(`/organization-profile/${payload}`);
    });
  };
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('originalUser', user._id);
    if (data.profileImg[0]) {
      formData.append('profileImg', data.profileImg[0]);
    }
    if (data.wallImg[0]) {
      formData.append('wallImg', data.wallImg[0]);
    }

    formData.append('organizationName', data.organizationName);
    formData.append('description', data.description);

    for (let i = 0; i < gallery.length; i++) {
      formData.append('gallery', gallery[i]);
    }

    formData.append('cemetery', data.cemetery);
    formData.append('cemeteryLocation', cemeteryLocation);
    formData.append('privacy', privacy);

    dispatch(createOrganizationProfile({ formData, showNotification }));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | יצירת פרופיל עמותה</title>
        <meta
          property="og:url"
          content="https://lifecloud-qr.com/organization-create-profile"
        />
      </Helmet>
      <CenterContentLayout cloudPos="left-right">
        <div className={`container ${classes.profile_create} py-5`}>
          <div className={`${classes.profile_header} text-center`}>
            <h2 className="page_title">יצירת פרופיל עמותה</h2>
            <Link to="/profile/62930e650fc791cf90ac210c">
              <Button className={classes.create_button_top}>
                !לחץ לפרופיל לדוגמא
              </Button>
            </Link>
          </div>
          <form className="row" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-lg-8 col-md-10 mx-auto">
              <ProfileAccordion
                isFilled={getValues('profileImg')?.length}
                show={accordion.profileAccordion}
                target="profileImg"
                title="בחר תמונת פרופיל"
                modalText="יש להעלות קובץ תמונה שיופיע כתמונה ראשית בעמוד הפרופיל"
              >
                <label htmlFor="profileImg">
                  <div className={classes.upload}>
                    <img
                      src={profileImg || uploadImg}
                      alt="upload"
                      className={`${profileImg && classes.img_active}`}
                    />
                  </div>
                  <h3 className={classes.upload_button}>בחרו תמונה להעלאה</h3>
                  <input
                    type="file"
                    id="profileImg"
                    className="d-none"
                    accept="image/*"
                    {...register('profileImg', {
                      onChange: onChangeProfilePicture,
                    })}
                  />
                </label>
              </ProfileAccordion>

              <ProfileAccordion
                isFilled={getValues('wallImg')?.length}
                target="wallImg"
                title="בחר תמונת קאבר"
                modalText="יש להעלות קובץ תמונה שיופיע כתמונה משנית בעמוד הפרופיל"
                show={accordion.wallAccordion}
              >
                <label htmlFor="wallImg">
                  <div className={classes.upload}>
                    <img
                      src={wallImg || uploadImg}
                      alt="upload"
                      className={`${wallImg && classes.img_active}`}
                    />
                  </div>
                  <h3 className={classes.upload_button}>בחרו תמונה להעלאה</h3>
                  <input
                    type="file"
                    id="wallImg"
                    className="d-none"
                    accept="image/*"
                    {...register('wallImg', {
                      onChange: onChangeWallPicture,
                    })}
                  />
                </label>
              </ProfileAccordion>

              <div className={`row ${classes.row_gap}`}>
                <div className="col-12">
                  <input
                    type="text"
                    placeholder="*שם העמותה..."
                    className={`form-control ${formClasses.input} ${classes.input} ${classes.name_input}`}
                    {...register('organizationName', {
                      onChange: () => toggleAccordion('galleryAccordion'),
                    })}
                  />
                </div>
              </div>

              <ProfileAccordion
                isFilled={getValues('gallery')?.length}
                target="gallery"
                title="העלאת מדיה לגלריה"
                modalText="הגלריה מציגה אלבום מדיה של יקירכם, רצוי להעלות עד 8 קבצים בכל העלאה."
                show={accordion.galleryAccordion}
              >
                <label htmlFor="profile">
                  <div
                    className={`${classes.upload} d-flex gap-4 justify-content-center`}
                  >
                    {gallery.length > 0 ? (
                      gallery.map((img, i) => (
                        <div key={i}>
                          <img
                            src={
                              img.type.startsWith('video')
                                ? 'https://res.cloudinary.com/lifecloud-qr/image/upload/v1672574174/photo-1611162616475-46b635cb6868_l2gzrj.avif'
                                : URL.createObjectURL(img)
                            }
                            alt="upload"
                            className={classes.img_active}
                          />
                          <p
                            className="text-white"
                            onClick={() => onRemoveGallery(i)}
                          >
                            מחיקה
                          </p>
                        </div>
                      ))
                    ) : (
                      <img src={uploadImg} alt="upload" />
                    )}
                  </div>

                  <h3 className={classes.upload_button}>בחרו קובץ להעלאה</h3>
                  <h5 className="text-white fw-lighter mt-3">
                    יש לבחור עד 8 קבצים בכל העלאה*
                  </h5>
                  <input
                    type="file"
                    id="profile"
                    multiple
                    className="d-none"
                    accept="image/*, video/*"
                    {...register('gallery', {
                      onChange: onChangeGalleryPicture,
                    })}
                  />
                </label>
              </ProfileAccordion>
              <ProfileAccordion
                isFilled={getValues('description')}
                target="description"
                title="אודות העמותה"
                modalText="ספרו קצת על יקיריכם שתרצו להנציח, שגם החברים יוכלו להכיר יותר, גם המשפחה ואפילו אלה שלא זכו להכיר."
                show={accordion.descriptionAccordion}
              >
                <textarea
                  {...register('description', {
                    onChange: () => toggleAccordion('mapAccordion'),
                  })}
                  placeholder="טקסט חופשי..."
                  className={`form-control ${formClasses.input} ${formClasses.textarea} ${classes.input_focus} bg-transparent mb-4`}
                ></textarea>
              </ProfileAccordion>
              <ProfileAccordion
                target="map"
                title="מיקום האנדרטה"
                modalText="בסעיף זה, ניתן להוסיף את מיקום הקבר המדוייק על ידי נעיצת סיכה בגוגל מפות ובנוסף גם להוסיף הכוונות הגעה לבית הקברות עצמו על ידי הוספת מיקום בוויז."
                show={accordion.mapAccordion}
                isFilled={getValues('cemetery')}
              >
                <div className={`row gx-5 ${classes.map}`}>
                  <div className="col-md-6 order-md-1 order-2">
                    <Button
                      className={`${classes.upload_button} w-100 mt-0 py-2 mb-3 d-flex align-items-center justify-content-center`}
                      type="button"
                      onClick={getCurrentGeoLocation}
                      disabled={!getValues('cemetery')}
                    >
                      <img
                        src={location_icon}
                        alt="location"
                        height="25px"
                        className="me-2"
                      />
                      {!location.loaded && 'נעיצת מיקומכם הנוכחי כמיקום הקבר'}
                      {location.loaded &&
                        location.coordinates &&
                        'מיקום נוכחי נוסף'}
                      {location.loaded &&
                        location.error &&
                        'יש לאשר שימוש בשירותי מיקום בהגדרות המכשיר'}
                    </Button>
                  </div>
                  <div className="col-md-6 order-md-2 order-1">
                    <input
                      type="text"
                      placeholder="שם בית הקברות"
                      className={`form-control ${formClasses.input} ${classes.input_focus}  bg-transparent mb-4`}
                      {...register('cemetery', {
                        onChange: () => toggleAccordion('privacyAccordion'),
                      })}
                    />
                  </div>
                </div>
              </ProfileAccordion>

              <ProfileAccordion
                target="privacy"
                title="פרטיות"
                modalText="יש לבחור את פרטיות הפרופיל החדש:
            ציבורי - פתוח לקהל הרחב ללא הגלבת תכנים
            פרטי - ניתן לראות תכנים רק על ידי אישור חברות
            "
                show={accordion.privacyAccordion}
                isFilled={privacy}
              >
                <div className="d-flex justify-content-center gap-4">
                  <Button
                    className={`${
                      privacy === 'public' && classes.active_privacy
                    } ${classes.privacy_button}`}
                    onClick={() => setPrivacy('public')}
                    type="button"
                  >
                    פרופיל ציבורי
                  </Button>
                  <Button
                    className={`${
                      privacy === 'private' && classes.active_privacy
                    } ${classes.privacy_button}`}
                    onClick={() => setPrivacy('private')}
                    type="button"
                  >
                    פרופיל פרטי
                  </Button>
                </div>
              </ProfileAccordion>

              <div
                className={`${classes.profile_create_button} d-flex justify-content-center`}
              >
                <Button type="button" onClick={() => navigate(-1)}>
                  <BsArrowLeft className="me-2" />
                  חזרה
                </Button>
                <Button loading={loading} className={classes.submit}>
                  שמירה
                </Button>
              </div>
            </div>
          </form>
        </div>
      </CenterContentLayout>
    </React.Fragment>
  );
};

export default OrganizationProfileCreate;

import React from 'react';
import { Link } from 'react-router-dom';
import classes from './NewFooter.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import alertCreator from '../../utils/alertCreator';
import { createContact } from '../../features/contact/contactSlice';
import InputError from '../../components/InputError/InputError';


const schema = yup.object().shape({
  firstName: yup.string().required('!שם הינו שדה חובה*'),
  phone: yup.string().required('!טלפון הינו שדה חובה*'),

  email: yup
    .string()
    .email('!המייל שהוזן אינו תקין*')
    .required('!מייל הינו שדה חובה*'),
});

export default function NewFooter() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const showNotification = () => {
    alertCreator({
      icon: 'success',
      title: 'תודה על פנייתכם,\n נחזור אליכם בהקדם האפשרי :)',
    });
  };
  //Form  submit handler
  const onSubmit = async (formData) => {
    // console.log(formData);
    dispatch(createContact({ formData, showNotification }));
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <footer className={`${classes.footer}`}>
        <div className={`container ${classes.upperFooter} px-4`}>
          <div className="row gy-4 gy-md-0">
            <div className="col-md-4 order-md-0 order-2">

              <div className={`${classes.contactInfo} ${classes.maxContent}`}>
                <h4 className="text-end">השאירו פרטים</h4>
                <div className="d-flex gap-2">
                  <div className="d-flex flex-column justify-content-md-end justify-content-between">
                    {/*<div className={`d-flex align-items-center gap-2 justify-content-end mt-md-2 mt-0 ${classes.socialMedia} d-md-none`}>
                      <a
                        href="https://www.facebook.com/lifecloudqr/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={require('../../assets/newFooter/facebook.png')} alt="Facebook" />
                      </a>
                      <a
                        href="https://www.instagram.com/lifecloudqr/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={require('../../assets/newFooter/instagram.png')} alt="Instagram" />
                      </a>
                    </div>*/}
                    <button className="btn">שלח!</button>
                  </div>
                  <div className="input-container">
                    <input {...register('firstName')} type="text" className="form-control" placeholder="שם מלא" />
                    <InputError error={errors.name?.message} />
                    <input {...register('phone')} type="text" className="form-control my-2" placeholder="טלפון" />
                    <InputError error={errors.phone?.message} />
                    <input {...register('email')} type="text" className="form-control" placeholder="מייל" />
                    <InputError error={errors.email?.message} />
                  </div>

                </div>

                {/*<div className="d-md-flex align-items-center gap-2 justify-content-end mt-2 social-media d-none">
                  <a
                    href="https://www.facebook.com/lifecloudqr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={require('../../assets/newFooter/facebook.png')} alt="Facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/lifecloudqr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={require('../../assets/newFooter/instagram.png')} alt="Instagram" />
                  </a>
                </div>*/}
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className={`${classes.maxContent}`}>
                <h4>קישורים נוספים</h4>
                <ul className="d-flex flex-column gap-2">

                  <li>
                    <Link to="/blog">
                      <span>בלוג</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/qa">
                      <span>שאלות ותשובות</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/policy">
                      <span>מדיניות פרטיות</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <span>אודות</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <span>צרו קשר</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className={`${classes.maxContent}`}>
                <h4>פעולות באתר</h4>
                <ul className="d-flex flex-column gap-2">
                  <li>
                    <Link to="/registration">
                      <span>הרשמה</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/create-profile">
                      <span>יצירת פרופיל</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/regulations">
                      <span>תקנון</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <span>ביטול עסקה</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className={`${classes.lowerFooter} text-center`}>
          <img src={require('../../assets/newFooter/logo.png')} alt="LifeCloud" />
          <p>(C) all rights reserved to lifecloud-qr.com 2023</p>
        </div>
      </footer>
    </form>

  )
}
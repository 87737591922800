import React from 'react';
import classes from './Memorial.module.css';
import moment from 'moment';
import Section from '../layout/Section';
import { showModal } from '../../features/modal/modalSlice';
import { useDispatch } from 'react-redux';
import alertCreator from '../../utils/alertCreator';
const Memorial = ({
  profile,
  title,
  isOrganization,
  isFriend,
  isOwnerOrAdmin,
}) => {
  const dispatch = useDispatch();

  const newMemorialDate = moment(profile.memorialDate).isBefore()
    ? moment(profile.memorialDate).add(1, 'years').format('DD.MM.YYYY')
    : moment(profile.memorialDate).format('DD.MM.YYYY');

  const shareURL = profile.isOrganization
    ? `
  https://api.whatsapp.com/send/?text=בואו לצפות בעמוד המרגש של ${profile.organizationName} שהוקם בLifeCloud! %0a %0a
  ניתן למצוא הכוונה לאנדרטה, תמונות, סיפור העמותה ואת כל חללי העמותה ☁ ${window.location.href}`
    : `https://api.whatsapp.com/send/?text=בואו לצפות בעמוד המרגש של ${profile.firstName} ${profile.lastName} שהוקם בLifeCloud! %0a %0a
    ניתן למצוא הכוונה לבית העלמין, תמונות, סיפור חיים ואפילו סיפורים וזיכרונות שחברים משתפים
    ☁ ${window.location.href}`;
  const openEventModal = () => {
    if (isFriend || isOwnerOrAdmin) {
      dispatch(showModal('memorialModal'));
    } else {
      alertCreator({
        icon: 'info',
        title: 'אתה צריך להיות חבר בפרופיל הזה כדי לראות את ההזמנה',
      });
    }
  };
  return (
    <Section
      bg="#DCECF4"
      padding
      gap={0}
      cloudPos="up-right"
      className={classes.memorial}
    >
      <div className="container">
        <h2 className="profile_title mb-3">{title ? title : 'תאריך אזכרה'}</h2>
        <hr className={classes.hr} />
        <div className={classes.memorial_content}>
          {!isOrganization && (
            <React.Fragment>
              <p>{profile.hebDeathDate}</p>
              {profile.hebDeathDate && <span>|</span>}
              <p>{profile.memorialDate ? newMemorialDate : 'טרם נקבע'}</p>
              {profile.cemetery && <span>|</span>}
            </React.Fragment>
          )}

          <p>{profile.cemetery}</p>
        </div>
        <hr className="d-md-none d-block" />
        <div className={classes.memorial_action}>
          <a
            href={
              profile.cemeteryLocation
                ? `https://waze.com/ul?ll=${profile.cemeteryLocation}&navigate=yes`
                : `https://waze.com/ul?q=${profile.cemetery}`
            }
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1673530004/waze_1_aqwwpb.png"
              alt="waze"
              className={classes.img}
            />
          </a>
          <button onClick={openEventModal}>
            {isOwnerOrAdmin ? 'יצירת הזמנה' : 'צפייה בהזמנה'}
          </button>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${
              profile.cemeteryLocation
                ? profile.cemeteryLocation
                : profile.cemetery
            }`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1673530069/google-maps_wppcpj.png"
              alt="Google Map"
              className={classes.img}
            />
          </a>
        </div>
      </div>
    </Section>
  );
};

export default Memorial;

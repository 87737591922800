import React from 'react';
import classes from './CenterContentLayout.module.css';
import upCloudLeft from '../../assets/error-page-img/upcloud-left.png';
import upCloudRight from '../../assets/error-page-img/upcloud-right.png';
import downCloudLeft from '../../assets/error-page-img/downcloud-left.png';
import downCloudRight from '../../assets/error-page-img/downcloud-right.png';

import upColorCloudLeft from '../../assets/error-page-img/blue-upcloud-left.png';
import upColorCloudRight from '../../assets/error-page-img/blue-upcloud-right.png';
import downColorCloudLeft from '../../assets/error-page-img/blue-downcloud-left.png';
import downColorCloudRight from '../../assets/error-page-img/blue-downcloud-right.png';

import MainLayout from './MainLayout';
import SocialFooter from '../SocialFooter/SocialFooter';
const CenterContentLayout = ({
  children,
  bg,
  colorful,
  cloudPos = 'left-right',
}) => {
  const [upCloudPos, downCloudPos] = cloudPos.split('-');
  const upCloudImg =
    upCloudPos === 'left'
      ? colorful
        ? upColorCloudLeft
        : upCloudLeft
      : colorful
      ? upColorCloudRight
      : upCloudRight;
  const downCloudImg =
    downCloudPos === 'left'
      ? colorful
        ? downColorCloudLeft
        : downCloudLeft
      : colorful
      ? downColorCloudRight
      : downCloudRight;
  return (
    <MainLayout bg={bg} className="position-relative">
      <img
        src={upCloudImg}
        alt="cloud"
        className={`${classes.cloudImg} ${classes.cloudImgUp} ${classes[upCloudPos]}`}
      />
      <section className={classes.center_content}>
        <div className={`container text-center ${classes.error_container}`}>
          {children}
        </div>
      </section>
      <SocialFooter bg={bg} showCloud={false} />
      <img
        src={downCloudImg}
        alt="cloud"
        className={`${classes.cloudImg} ${classes.cloudImgDown} ${classes[downCloudPos]}`}
      />
    </MainLayout>
  );
};

export default CenterContentLayout;

import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { createNotification } from '../../features/notification/notificationSlice';
import emailTextAndSubject from '../../utils/emailTextAndSubject';
import toastCreator from '../../utils/toastifyCreator';
import Section from '../layout/Section';
import Button from '../UI/Button/Button';
import classes from './ProfileNavigator.module.css';
import { AiFillLock } from 'react-icons/ai';
import PhysicalQrModal from '../../modals/PhysicalQrModal';
import
  {
    addOrganizationUser,
    removeOrganizationUser,
  } from '../../features/organizationProfile/organizationProfileSlice';
import { showModal } from '../../features/modal/modalSlice';
import { removeUser } from '../../features/profile/profileSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OrganizationProfileNavigator = ({
  showSection,
  setShowSection,
  isFriend,
  isOwnerOrAdmin,
  user,
  profile,
}) =>
{
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const showNotification = (status, payload) => toastCreator(status, payload);
  const addUserToProfile = () =>
  {
    if (!user._id) return dispatch(showModal('loginModal'));
    // ADD FRIEND WITH REQUEST -> NEEDS TO BE ACCEPTED BY ADMIN
    //   const formData = {
    //     field: 'friendRequests',
    //     profileId: profile._id,
    //     userId: user._id,
    //   };

    // const notification = {
    //   ...emailTextAndSubject(
    //     'friendRequests',
    //     {
    //       organizationName: profile.organizationName,
    //       firstName: profile.firstName,
    //       lastName: profile.lastName,
    //     },
    //     {
    //       organizationName: user.organizationName,
    //       firstName: user.firstName,
    //       lastName: user.lastName,
    //     }
    //   ),
    //   emails: [
    //     profile.originalUser.email,
    //     ...profile.profileAdmins.map((admin) => admin.email),
    //   ],
    //   firstName:
    //     profile.originalUser.organizationName || profile.originalUser.firstName,
    //   profile: profile._id,
    //   creator: user._id,
    //   receivers: [
    //     profile.originalUser._id,
    //     ...profile.profileAdmins.map((admin) => admin._id),
    //   ],
    //   url: window.location.href,
    // };
    //   dispatch(createNotification(notification));
    //   dispatch(addOrganizationUser({ formData, showNotification }));
    // };

    const formData = {
      field: 'profileFriends',
      profileId: profile._id,
      userId: user._id,
    };

    const sendTo = [...new Set([
      profile.originalUser._id,
      ...profile.profileAdmins.map((admin) => admin._id),
      ...profile.profileFriends.map((friends) => friends._id),

    ])];

    const notification = {
      ...emailTextAndSubject(
        'newMemeberJoined',
        {
          organizationName: profile.organizationName,
          firstName: profile.firstName,
          lastName: profile.lastName,
        },
        {
          organizationName: user.organizationName,
          firstName: user.firstName,
          lastName: user.lastName,
        }
      ),
      profile: profile._id,
      creator: user._id,
      receivers: sendTo,
      url: window.location.href,
    };

    const notification2 = {
      ...emailTextAndSubject(
        'welcomeToCommunity',
        {
          organizationName: profile.organizationName,
          firstName: profile.firstName,
          lastName: profile.lastName,
        },
        {
          organizationName: user.organizationName,
          firstName: user.firstName,
          lastName: user.lastName,
        }
      ),
      profile: profile._id,
      creator: user._id,
      receivers: [
        user._id
      ],
      url: window.location.href,
    };
    // dispatch(createNotification(notification)); //TODO: Server notifications
    // dispatch(createNotification(notification2)); //TODO: Server notifications
    dispatch(addOrganizationUser({ formData, showNotification }));
    toast.success(`אתה עכשיו חבר של העמותה  ${profile.organizationName}`, {
      autoClose: 5000,
    });
  };

  // 
  // const removeUserToProfile = () => {
  //   const formData = {
  //     userId: user._id,
  //     field: 'friendRequests',
  //     profileId: profile._id,
  //   };
  //   dispatch(removeOrganizationUser({ formData, showNotification }));
  // };
  const removeUserToProfile = () =>
  {
    const formData = {
      userId: user._id,
      field: 'profileFriends',
      profileId: profile._id,
    };
    dispatch(removeOrganizationUser({ formData, showNotification }));
  };
  return (
    <Section className={classes.profile_navigator} showCloud={false}>
      <div className="container">
        <div
          className={`d-flex justify-content-center align-items-center gap-sm-5 gap-3`}
        >

          {!isOwnerOrAdmin&&(
            profile.friendRequests.find((u) => u._id === user._id) ?
              (
                <Button onClick={removeUserToProfile} className={classes.active}>
                  בקשה נשלחה
                </Button>
              )
              :
              (
                !isFriend && <Button onClick={addUserToProfile}>בקשת חברות</Button>
              ))
            }

          {!isOwnerOrAdmin && isFriend && (
            <Button onClick={removeUserToProfile}
              className={`${classes.active}`}
            >הנך חבר/ה</Button>
          )}
          {
            isOwnerOrAdmin && <Link to={`/edit-organization-profile/${profile._id}`}>עריכה</Link>
          }
          <Button
            onClick={() => setShowSection('wallFriend')}
            className={`${showSection === 'wallFriend' && classes.active
              } d-flex align-items-center`}
          >
            חברים{' '}
          </Button>
          <Button data-bs-toggle="modal" data-bs-target="#physicalQrModal">
            שיתוף{' '}
          </Button>
          <Button
            onClick={() => setShowSection('wall')}
            className={`${showSection === 'wall' && classes.active
              } d-flex align-items-center`}
          >
            פרופיל{' '}
            {profile.privacy === 'private' && <AiFillLock className="ms-2" />}
          </Button>
        </div>
      </div>
    </Section>
  );
};

export default OrganizationProfileNavigator;

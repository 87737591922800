import React, { useState } from 'react';
import PillBox from '../../components/PillBox/PillBox';
import classes from './MemoryDetails.module.css';
import formClasses from '../Form.module.css';
import Button from '../../components/UI/Button/Button';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Linkify from 'react-linkify';
import {
  deleteMemory,
  deleteMemoryComment,
  getMemory,
  memoryComment,
  memoryLike,
} from '../../features/memory/memorySlice';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Loader from '../../components/Loader/Loader';
import alertCreator from '../../utils/alertCreator';
import toastCreator from '../../utils/toastifyCreator';
import InputError from '../../components/InputError/InputError';
import ReactPlayer from 'react-player';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import getFileType from '../../utils/getFileType';
import { BsArrowLeft } from 'react-icons/bs';
import moment from 'moment';
import emailTextAndSubject from '../../utils/emailTextAndSubject';
import { createNotification } from '../../features/notification/notificationSlice';
import { clearAllListeners } from '@reduxjs/toolkit';
import whatsAppImg from '../../assets/wall-friend-img/whatsapp.png';
import { Helmet } from 'react-helmet-async';
import { showModal } from '../../features/modal/modalSlice';
import { Link } from 'react-router-dom';
//Form validation schema
const schema = yup.object().shape({
  text: yup.string().required('*שדה התגובה ריק!'),
});
const MemoryDetails = () => {
  const [showCommentButton, setShowCommentButton] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { memoryId } = useParams();
  const { memory, memoryLoading, loading } = useSelector(
    (state) => state.memory
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });
  /* Get the memory details by memory id getting from url */
  useEffect(() => {
    dispatch(getMemory(memoryId));
  }, [dispatch, memoryId, reset]);

  /* Add memory comment */
  const onSubmit = async (formData) => {
    const updatedFormData = { ...formData, userId: user._id };

    const notification = {
      ...emailTextAndSubject(
        'memoryComment',
        {
          firstName: memory.profile.firstName,
          lastName: memory.profile.lastName,
        },
        {
          organizationName: user.organizationName,
          firstName: user.firstName,
          lastName: user.lastName,
        }
      ),
      creator: user._id,
      receivers: [memory.profile.originalUser, ...memory.profile.profileAdmins],
      url: window.location.href,
    };
    // dispatch(createNotification(notification)); //TODO: Server notifications
    dispatch(memoryComment({ memoryId, formData: updatedFormData }));
    reset();
  };
  /* Delete Memory */
  const showNotification = async (status, payload) => {
    if (status === 'error') toastCreator('error', payload);
    alertCreator({
      icon: 'success',
      title: 'זיכרון נמחק בהצלחה!',
    }).then(() => {
      navigate(`/profile/${memory.profile._id}`);
    });
  };
  const handleMemoryDelete = (memoryId) => {
    alertCreator({
      icon: 'warning',
      title: 'יש לאשר שאתם בוודאות רוצים למחוק זיכרון זה',
      text: 'האם אתם בטוחים שתרצו למחוק זכרון זה? לא ניתן לשחזר זכרונות שפורסמו',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteMemory({ memoryId, showNotification }));
      }
    });
  };
  /* Memory Like */
  const handleMemoryLike = (memoryId, userId) => {
    if (!user._id) return dispatch(showModal('loginModal'));
    dispatch(memoryLike({ memoryId, userId }));
  };
  /* Memory Comment Delete */
  const handleCommentDelete = (memoryId, commentId) => {
    dispatch(deleteMemoryComment({ memoryId, commentId }));
  };
  /* If loading show loading spinner */
  if (memoryLoading) return <Loader />;
  /* If memory not found with that id redirect 404 page */
  if (!memory) return <Navigate to="/login" />;
  /* Check if the current login user is the owner or admin of visited profile */
  const isOwnerOrAdmin =
    memory.originalUser?._id === user._id ||
    memory.profile.originalUser === user._id ||
    memory.profile.profileAdmins.find((admin) => admin === user._id);

  const isFriend = memory.profile.profileFriends.find(
    (friend) => friend === user._id
  );

  const handleShowComment = () => {
    if (!user._id) return dispatch(showModal('loginModal'));
    setShowCommentButton(false);
  };
  const shareURL = `https://api.whatsapp.com/send/?text=זוכרים את ${memory.profile.firstName} ${memory.profile.lastName}? בואו לראות זכרון מדהים שעלה בפרופיל בLifeCloud, אם הפרופיל מוגדר כפרטי תצטרכו להרשם :) ☁️
  %0a %0a https://lifecloud-qr.com${window.location.pathname}`;

  const defaultImg =
    'https://res.cloudinary.com/lifecloud-qr/image/upload/v1667992386/download_1_pe6nwb.png';
  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url + '</a>';
    });
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }
  // console.log(moment(memory.createdAt).format('DD.MM.YYYY'))
  return (
    <React.Fragment>
      <Helmet>
        <title>
          LifeCloud |{' '}
          {memory.title || moment(memory.createdAt).format('DD.MM.YYYY')}
        </title>
        <meta name="description" content={memory.description} />
        <meta
          property="og:url"
          content={`https://lifecloud-qr.com/memory/${memoryId}`}
        />
        <meta
          property="og:title"
          content={`LifeCloud |
          ${memory.title || moment(memory.createdAt).format('DD.MM.YYYY')}`}
        />
        <meta property="og:description" content={memory.description} />
        <meta property="og:image" content={memory.media} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <CenterContentLayout gap={true} cloudPos="right-left" bg="#dcecf4">
        <section className={classes.memory}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 mx-auto">
                <h2 className="page_title" dir="rtl">
                  {memory.title ||
                    moment(memory.createdAt).format('DD.MM.YYYY')}{' '}
                    
                  | {memory.guestName?memory.guestName:memory.originalUser?.firstName}{' '}
                  {memory.guestName?"":memory.originalUser?.lastName}
                </h2>
                <div className={classes.memory_details}>
                  <div className={classes.memory_details_img}>
                    {defaultImg !== memory.media &&
                      getFileType(memory.media) === 'image' && (
                        <img src={memory.media} alt="memory" />
                      )}
                    {getFileType(memory.media) === 'video' && (
                      <div className="ratio ratio-16x9">
                        <ReactPlayer
                          url={memory.media}
                          width="100%"
                          height="100%"
                          controls
                        />
                      </div>
                    )}
                    <div
                      className={`text-center ${classes.icons} ${
                        defaultImg === memory.media && classes.no_img_icon
                      }`}
                    >
                      {(isOwnerOrAdmin || isFriend || !user._id) && (
                        <div className="d-flex flex-column">
                          <i
                            onClick={() =>
                              handleMemoryLike(memory._id, user._id)
                            }
                            className={`fas fa-heart ${
                              memory.likes.includes(user._id) && 'text-danger'
                            }`}
                          ></i>
                          <span>{memory.likes.length}</span>
                        </div>
                      )}
                      <a href={shareURL} target="_blank" rel="noreferrer">
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </div>
                  </div>
                  <Linkify>
                    <p dir="rtl" className={classes.memory_description}>
                      {memory.description}
                    </p>
                  </Linkify>
                </div>
                {isOwnerOrAdmin && (
                  <Link to={`/edit-memory/${memoryId}`}>
                    <Button className="px-5" outline>
                      עריכה
                    </Button>
                  </Link>
                )}
                {(isOwnerOrAdmin || isFriend || !user._id) && (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h3 dir="rtl" className="mt-5">
                      תגובות
                    </h3>
                    {memory.comments.length === 0 && (
                      <h4 className="not_found" dir="rtl">
                        בזיכרון זה עדיין אין תגובות, בואו להיות הראשונים ☁
                      </h4>
                    )}
                    {[...memory.comments]
                      .sort((a, b) => new Date(b.date) - new Date(a.date))
                      .map((comment) => (
                        <PillBox
                          key={comment._id}
                          img={comment.user?.profilePicture}
                          userName={`${comment.user?.firstName} ${comment.user?.lastName}`}
                          event={comment.text}
                          date={comment.date}
                          position="end"
                          imgPos="right"
                          className={classes.pillbox}
                          onClick={
                            isOwnerOrAdmin || comment.user._id === user._id
                              ? () =>
                                  handleCommentDelete(memory._id, comment._id)
                              : null
                          }
                        />
                      ))}

                    <div className={classes.memory_comment}>
                      {!showCommentButton && (
                        <React.Fragment>
                          <textarea
                            className={`form-control ${formClasses.input} ${formClasses.textarea} ${classes.textarea}`}
                            placeholder="כתבו תגובתכם..."
                            {...register('text')}
                          ></textarea>
                        </React.Fragment>
                      )}

                      <InputError error={errors.text?.message} />
                    </div>
                    {!showCommentButton && (
                      <div className={classes.btn_group}>
                        <Button
                          className={`w-50 `}
                          onClick={() => setShowCommentButton(true)}
                          type="button"
                        >
                          ביטול
                        </Button>
                        <Button className={`w-50 `} loading={loading}>
                          פרסם
                        </Button>
                      </div>
                    )}
                  </form>
                )}

                <div
                  className={`${classes.row_buttons} row g-5 align-items-center justify-content-center`}
                >
                  <div className="col-md-3 col-6">
                    <Link
                      to={`/profile/${memory.profile._id}`}
                      className={classes.back_button}
                    >
                      <Button>
                        <BsArrowLeft className="me-1" />
                        חזרה
                      </Button>
                    </Link>
                  </div>
                  {isOwnerOrAdmin && (
                    <div className="col-md-3 col-6">
                      <Button
                        loading={loading}
                        onClick={() => handleMemoryDelete(memory._id)}
                        type="button"
                      >
                        מחיקה
                      </Button>
                    </div>
                  )}
                  <div className="col-md-3 col-6">
                    <a href={shareURL} target="_blank" rel="noreferrer">
                      <Button
                        dir="rtl"
                        className={`d-flex justify-content-center align-items-center ${classes.share_button}`}
                      >
                        שיתוף ב
                        <img
                          src={whatsAppImg}
                          alt="whatsApp"
                          className="me-2 img-fluid"
                        />
                      </Button>
                    </a>
                  </div>
                  <div className="col-md-3 col-6">
                    <Button onClick={handleShowComment}>...הגב</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </CenterContentLayout>
    </React.Fragment>
  );
};

export default MemoryDetails;

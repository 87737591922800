import React, { useState } from 'react';
import classes from './HeroSearch.module.css';
import search from '../../assets/hero-img/search.png';
import { useNavigate } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch } from 'react-redux';
import { searchProfiles } from '../../features/profile/profileSlice';
import SearchShow from '../SearchShow/SearchShow';

const HeroSearch = () => {
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSearch = (event) => {
    event.preventDefault();
    navigate(`/search/${searchValue}`);
  };
  const handleSearchInput = (event) => {
    setSearchValue(event.target.value);
    dispatch(searchProfiles(event.target.value));
  };
  return (
    <section className={classes.hero_search}>
      <form
        role="search"
        onSubmit={(e) => handleSearch(e)}
        className={classes.hero_search_body}
      >
        <div className={classes.wrapper}>
          <DebounceInput
            value={searchValue}
            type="search"
            aria-label="Search"
            onChange={(e) => handleSearchInput(e)}
            placeholder="...חיפוש מנוח/עמותה"
            className={`form-control ${classes.input}`}
            minLength={2}
            debounceTimeout={500}
          />
          <img src={search} alt="search" className={classes.search_icon} />
        </div>
        <SearchShow
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          className={classes.search_results}
        />
      </form>
    </section>
  );
};

export default HeroSearch;

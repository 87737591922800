import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import TagsInput from 'react-tagsinput';
import 'react-quill/dist/quill.snow.css';
import 'react-tagsinput/react-tagsinput.css';
import classes from './EditBlog.module.css';
import MainLayout from '../../components/layout/MainLayout';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllBlogs,
  getBlog,
  updateBlog,
} from '../../features/blog/blogSlice';
import Button from '../../components/UI/Button/Button';
import alertCreator from '../../utils/alertCreator';
import { useParams } from 'react-router-dom';
function EditBlog() {
  const { blogId } = useParams();
  const dispatch = useDispatch();
  const { blogs, loading } = useSelector((state) => state.blog);
  const [tags, setTags] = useState([]);
  const [blogDetails, setBlogDetails] = useState({});
  const [content, setContent] = useState([
    {
      btnText: '',
      btnLink: '',
      paragraph: '',
    },
  ]);
  const { register, handleSubmit, setValue, reset } = useForm();

  useEffect(() => {
    (async () => {
      const response = await dispatch(getBlog(blogId));
      setBlogDetails(response.payload);
      reset({
        ...response.payload,
        relatedPosts: response.payload.relatedPosts.map((val) => val._id),
      });
      setContent(response.payload.content);
      setTags(response.payload.tags);
    })();
  }, [blogId, dispatch, reset]);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  const onSubmit = async ({
    title,
    description,
    relatedPosts,
    content,
    blogImg,
  }) => {
    const formData = new FormData();
    if (typeof blogImg !== 'string') {
      formData.append('blogImg', blogImg[0]);
    }
    formData.append('title', title);
    formData.append('description', description);
    tags.forEach((tag) => formData.append('tags[]', tag));
    formData.append('content', JSON.stringify(content));
    relatedPosts.forEach((relatedPost) =>
      formData.append('relatedPosts[]', relatedPost)
    );
    const response = await dispatch(updateBlog({ formData, blogId }));
    if (response.meta.requestStatus === 'fulfilled') {
      alertCreator({
        icon: 'success',
        title: 'Blog Updated Successfully',
      });
    }
  };
  const addItem = () => {
    setContent([
      ...content,
      {
        btnText: '',
        btnLink: '',
        paragraph: '',
      },
    ]);
  };
  console.log(
    blogDetails.relatedPosts,
    blogDetails.relatedPosts?.includes('63e4d9012bcab08ef7b76d98')
  );

  return (
    <MainLayout>
      <div className={`container ${classes.blogContainer}`}>
        <div className="row">
          <div className="col-md-8 col-12 mx-auto">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={`p-5 bg-light shadow m-5`}
              style={{ borderRadius: '10px' }}
            >
              <div className="mb-5">
                <input
                  className="form-control"
                  type="file"
                  {...register('blogImg')}
                />
              </div>
              <div className="mb-5">
                <input
                  className="form-control"
                  type="text"
                  {...register('title')}
                  placeholder="Title"
                />
              </div>
              <div className="mb-5">
                <textarea
                  {...register('description')}
                  className="form-control"
                  placeholder="Short paragraph for description"
                ></textarea>
              </div>

              {content.map((item, index) => (
                <React.Fragment key={index}>
                  <div className="mb-5">
                    <ReactQuill
                      theme="snow"
                      placeholder={`Paragraph ${index + 1}`}
                      onChange={(value) => {
                        setValue(`content[${index}].paragraph`, value);
                      }}
                      value={content[index].paragraph}
                    />
                    <textarea
                      className="d-none"
                      {...register(`content[${index}].paragraph`)}
                    ></textarea>
                  </div>

                  <div className="mb-5 d-flex gap-5">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={`Button Text ${index + 1} `}
                      {...register(`content[${index}].btnText`)}
                    />
                    <input
                      className="form-control"
                      type="text"
                      placeholder={`Button Link ${index + 1}`}
                      {...register(`content[${index}].btnLink`)}
                    />
                  </div>
                </React.Fragment>
              ))}
              <div className="text-center mb-5">
                <button
                  className="btn btn-light"
                  onClick={addItem}
                  type="button"
                >
                  <img
                    src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1675945312/plus_jusz7l.png"
                    alt="plus"
                  />
                </button>
              </div>

              <div className="mb-5">
                <select
                  className="form-control"
                  multiple
                  {...register('relatedPosts')}
                >
                  {blogs.map((blog) => (
                    <option
                      key={blog._id}
                      value={blog._id}
                      selected={blogDetails.relatedPosts?.includes(blog._id)}
                    >
                      {blog.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-5">
                <TagsInput value={tags} onChange={(tag) => setTags(tag)} />
              </div>
              <Button
                className="px-4 py-2"
                style={{ fontSize: '16px' }}
                loading={loading}
              >
                Submit
              </Button>
            </form>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
export default EditBlog;

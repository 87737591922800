import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import lifeAxios from '../../utils/lifeAxios';

/* Initial State for memory */
const initialState = {
  memories: [],
  memory: null,
  loading: false,
  error: '',
  memoryLoading: true,
};
/* For Creating Memory */
export const createMemory = createAsyncThunk(
  'memory/createMemory',
  async ({ formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/memories`,
        formData
      );
      showNotification('success', response.data.data.data);
      return response.data.data.data;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* Get a Single Memory Details */
export const getMemory = createAsyncThunk(
  'memory/getMemory',
  async (memoryId, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/memories/${memoryId}`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* Update Memory Details */
export const editMemory = createAsyncThunk(
  'memory/editMemory',
  async ({ memoryId, formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/memories/${memoryId}`,
        formData
      );
      showNotification('success', memoryId);
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* Getting Memory for Specific Profile */
export const getProfileMemories = createAsyncThunk(
  'memory/profile',
  async (profileId, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/memories/profile-memories/${profileId}`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* Delete Memory */
export const deleteMemory = createAsyncThunk(
  'memory/deleteMemory',
  async ({ memoryId, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).delete(
        `/memories/${memoryId}`
      );
      showNotification('success');
      return response;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Add Memory Like */
export const memoryLike = createAsyncThunk(
  'memory/like',
  async ({ memoryId, userId }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/memories/memory-like/${memoryId}`,
        {
          userId,
        }
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Add Memory Comment */
export const memoryComment = createAsyncThunk(
  'memory/memoryComment',
  async ({ memoryId, formData }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/memories/comment/${memoryId}`,
        formData
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* Delete Memory Comment */
export const deleteMemoryComment = createAsyncThunk(
  'memory/deleteComment',
  async ({ memoryId, commentId }, { dispatch, rejectWithValue }) => {
    try {
      await lifeAxios(localStorage.getItem('jwt')).delete(
        `/memories/${memoryId}/comment/${commentId}`
      );
      dispatch(getMemory(memoryId));
    } catch (error) {
      rejectWithValue(error.response.data.message);
    }
  }
);
const memorySlice = createSlice({
  name: 'memory',
  initialState,
  extraReducers: (builder) => {
    /* All Memories */
    builder.addCase(getProfileMemories.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getProfileMemories.fulfilled, (state, action) => {
      state.memories = action.payload;
      state.loading = false;
    });
    builder.addCase(getProfileMemories.rejected, (state, action) => {
      state.memories = [];
      state.error = action.payload;
      state.loading = false;
    });
    /* Single Memory */
    builder.addCase(getMemory.pending, (state, action) => {
      state.memoryLoading = true;
    });
    builder.addCase(getMemory.fulfilled, (state, action) => {
      state.memory = action.payload;
      state.memoryLoading = false;
    });
    builder.addCase(getMemory.rejected, (state, action) => {
      state.memory = null;
      state.error = action.payload;
      state.memoryLoading = false;
    });
    /* Memory Like */
    builder.addCase(memoryLike.fulfilled, (state, action) => {
      state.memory.likes = action.payload.likes;
    });
    /* Memory Comment */
    builder.addCase(memoryComment.fulfilled, (state, action) => {
      state.memory.comments = action.payload.comments;
    });

    /* Rest Builder */

    builder.addMatcher(
      isAnyOf(createMemory.pending, memoryComment.pending, editMemory.pending),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        createMemory.fulfilled,
        memoryComment.fulfilled,
        editMemory.fulfilled
      ),
      (state) => {
        state.loading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        createMemory.rejected,
        deleteMemory.rejected,
        memoryLike.rejected,
        memoryComment.rejected,
        deleteMemoryComment.rejected,
        editMemory.rejected
      ),
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default memorySlice.reducer;

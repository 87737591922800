import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import arrow from '../../assets/wall-friend-img/left-arrow.png';
import classes from './SearchResult.module.css';
import { useEffect } from 'react';
import { searchProfiles } from '../../features/profile/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

const SearchResult = () => {
  const { profiles } = useSelector((state) => state.profile);
  const { searchValue } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(searchProfiles(searchValue));
  }, [dispatch, searchValue]);
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | תוצאות החיפוש</title>
        <meta
          property="og:url"
          content={`https://lifecloud-qr.com/search/${searchValue}`}
        />
      </Helmet>
      <CenterContentLayout
        className={classes.search_success}
        bg="#DCECF4"
        cloudPos="right-left"
      >
        <div className="container">
          <h2 className={`${classes.search_title} text-center`}>
            תוצאות החיפוש
          </h2>
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              {profiles.length > 0 ? (
                <React.Fragment>
                  {profiles.map((profile) => (
                    <Link
                      to={
                        profile.isOrganization
                          ? `/organization-profile/${profile._id}`
                          : `/profile/${profile._id}`
                      }
                      className={`${classes.pill_box} d-flex justify-content-between align-items-center`}
                    >
                      <img src={profile.profileImg} alt="profile" />
                      <p>
                        {profile.organizationName} {profile.firstName}{' '}
                        {profile.lastName}
                      </p>
                    </Link>
                  ))}
                  <h3 className={classes.search_subtitle}>
                    ...אין תוצאות נוספות
                  </h3>
                </React.Fragment>
              ) : (
                <p className={classes.no_result}>): אין תוצאות</p>
              )}

              <div
                className={`d-flex ${classes.go_back_container}`}
                onClick={() => navigate('/')}
              >
                <img src={arrow} alt="arrow" />
                <p>חזרה</p>
              </div>
            </div>
          </div>
        </div>
      </CenterContentLayout>
    </React.Fragment>
  );
};

export default SearchResult;

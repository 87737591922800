import React, { useEffect, useState } from 'react';
import { useForm, Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import Button from '../../components/UI/Button/Button';
import classes from './Profile.module.css';
import formClasses from '../../pages/Form.module.css';
import lifeAxios from '../../utils/lifeAxios';
import ProfileAccordion from '../../components/ProfileAccordion/ProfileAccordion';
import location_icon from '../../assets/profile-create-img/location.png';
import useGeoLocation from '../../utils/useGeoLocation';
import { BsArrowLeft } from 'react-icons/bs';
import toastCreator from '../../utils/toastifyCreator';
import axios from 'axios';
import qs from 'qs';
import
{
  gregorianToHebDay,
  gregorianToHebMonth,
  gregorianToHebYear,
} from '../../utils/gregorianDate';
import uploadImg from '../../assets/profile-create-img/upload.svg';
import { editProfile } from '../../features/profile/profileSlice';
import getFileType from '../../utils/getFileType';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { showModal } from '../../features/modal/modalSlice';
import QrModalTest from '../../modals/QrModalTest';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'moment/locale/he';


const ProfileEdit = () =>
{
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, getValues, setValue, reset } = useForm();
  const [privacy, setPrivacy] = useState('public');
  const { loading } = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const [profileImg, setProfileImg] = useState('');
  const [wallImg, setWallImg] = useState('');
  const [gallery, setGallery] = useState([]);
  const [cemeteryLocation, setCemeteryLocation] = useState('');

  const [lifeAxis, setLifeAxis] = useState([
    {
      axisImage: '',
      axisTitle: '',
      axisDate: '',
      axisDescription: '',
      imgUpload: false,
    },
    {
      axisImage: '',
      axisTitle: '',
      axisDate: '',
      axisDescription: '',
      imgUpload: false,
    },
    {
      axisImage: '',
      axisTitle: '',
      axisDate: '',
      axisDescription: '',
      imgUpload: false,
    },
    {
      axisImage: '',
      axisTitle: '',
      axisDate: '',
      axisDescription: '',
      imgUpload: false,
    },
  ]);
  /* Toggle Accordion Functionality */
  const [accordion, setAccordion] = useState({
    profileAccordion: false,
    wallAccordion: false,
    dateAccordion: false,
    galleryAccordion: false,
    descriptionAccordion: false,
    axisAccordion: false,
    mapAccordion: false,
    zoomAccordion: false,
    privacyAccordion: false,
  });
  const toggleAccordion = (accordionName) =>
  {
    setAccordion((prev) => ({
      ...prev,
      [accordionName]: true,
    }));
  };

  useEffect(() =>
  {
    (async () =>
    {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/profiles/${profileId}`
      );
      const profile = response.data.data.data;
      setMemDay(profile.memorialDate)
      setAccordion({
        profileAccordion: profile.profileImg,
        wallAccordion: profile.wallImg,
        dateAccordion: profile.deathDate,
        galleryAccordion: profile.gallery.length > 0,
        descriptionAccordion: profile.description,
        axisAccordion: profile.lifeAxis.length > 0,
        mapAccordion: profile.cemeteryLocation || profile.cemetery,
        zoomAccordion: profile.zoom,
        privacyAccordion: profile.privacy,
      });
      setCemeteryLocation(profile.cemeteryLocation);
      setLifeAxis(profile.lifeAxis);
      setGallery(profile.gallery);
      setProfileImg(profile.profileImg);
      setWallImg(profile.wallImg);
      setPrivacy(profile.privacy);
      reset(profile);
    })();
  }, [dispatch, profileId, reset, setValue]);
  /* Genders */
  const radioOptions = [
    {
      value: 'other',
      name: 'other',
      label: 'אחר',
    },
    {
      value: 'female',
      name: 'female',
      label: 'נ',
    },
    {
      value: 'male',
      name: 'male',
      label: 'ז',
    },
  ];
  /* Form Submit Handler */
  const onSubmit = async (data) =>
  {
    try {
      const formData = new FormData();
      if (typeof data.profileImg !== 'string') {
        formData.append('profileImg', data.profileImg[0]);
      }
      if (typeof data.wallImg !== 'string') {
        formData.append('wallImg', data.wallImg[0]);
      }

      formData.append('firstName', data.firstName);
      formData.append('lastName', data.lastName);
      formData.append('gender', data.gender);
      formData.append('birthDate', data.birthDate);
      formData.append('degree', data.degree);
      formData.append('city', data.city);

      formData.append('deathDate', data.deathDate || '');
      formData.append('memorialDate', data.memorialDate || '');
      formData.append('hebDeathDate', data.hebDeathDate || '');

      formData.append('description', data.description);

      formData.append('lifeAxis', JSON.stringify(lifeAxis));

      for (let i = 0; i < lifeAxis.length; i++) {
        formData.append('axisImage', lifeAxis[i].axisImage);
      }

      for (let i = 0; i < gallery.length; i++) {
        formData.append('gallery', gallery[i]);
      }
      formData.append('cemetery', data.cemetery);
      formData.append('cemeteryLocation', cemeteryLocation);
      /* formData.append('zoom', data.zoom); */
      formData.append('privacy', privacy);

      await dispatch(editProfile({ profileId, formData }));
      navigate(`/profile/${profileId}`);
    } catch (error) {
      toastCreator('error', error.response.data.message);
    }
  };
  /* Geo Location */
  const getLatLng = (lat, lng) =>
  {
    setCemeteryLocation(`${lat},${lng}`);
  };
  const { location, getGeoLocation, removeGeoLocation } = useGeoLocation({
    getLatLng,
  });
  const getCurrentGeoLocation = () =>
  {
    getGeoLocation();
  };

  /* Hebrew and Memorial Date */
  const [autoDate, setAutoDate] = useState(true);
  const generateDate = async (event) =>
  {
    const [year, month, day] = event.target?.value ? event.target.value.split('-') : event.split('-');
    toggleAccordion('galleryAccordion');
    const url = `https://www.hebcal.com/yahrzeit/?n1=Person1&t1=Memorial&d1=${day}&m1=${month}&y1=${year}&sX=off`;
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify({
        cfg: 'json',
        v: 'yahzeit',
      }),
      url,
    };
    const res = await axios(options);
    const generatedDate = res.data.items.find((mem) =>
      moment(mem.date).isAfter(moment(new Date()))
    );
    const [genDay, genMonth, genYear] = generatedDate.hdate.split(' ');

    const hebDay = gregorianToHebDay(genDay);
    const hebMonth = gregorianToHebMonth(genMonth);
    const hebYear = gregorianToHebYear(genYear);

    setValue('memorialDate', generatedDate.date);
    setMemDay(generatedDate.date);
    setValue('hebDeathDate', `${hebDay} ${hebMonth} ${hebYear}`);
  };

  /* Profile Image */
  const onChangeProfilePicture = (event, setImg) =>
  {
    if (event.target.files[0]) {
      const src = URL.createObjectURL(event.target.files[0]);
      setProfileImg(src);
      toggleAccordion('wallAccordion');
    }
  };
  /* Wall Image */
  const onChangeWallPicture = (event, setImg) =>
  {
    if (event.target.files[0]) {
      const src = URL.createObjectURL(event.target.files[0]);
      setWallImg(src);
    }
  };
  /* Gallery */
  const onChangeGalleryPicture = (event, setImg) =>
  {
    if (event.target.files.length > 0) {
      setGallery((prev) => [...prev, ...event.target.files]);
      toggleAccordion('descriptionAccordion');
    }
  };
  const onRemoveGallery = (index) =>
  {
    setGallery((prev) => prev.filter((img, i) => i !== index));
  };
  /* Life Axis Functionality */
  const addAxisItem = () =>
  {
    setLifeAxis([
      ...lifeAxis,
      { axisImage: '', axisTitle: '', axisDate: '', axisDescription: '' },
    ]);
  };
  const handleInputChange = (e, index) =>
  {
    const { name, value } = e.target;
    const list = [...lifeAxis];
    list[index][name] = value;
    setLifeAxis(list);
    toggleAccordion('mapAccordion');
  };
  const onChangeAxisImg = (event, index) =>
  {
    if (event.target.files[0]) {
      const list = [...lifeAxis];
      list[index].axisImage = event.target.files[0];
      list[index].imgUpload = true;
      setLifeAxis(list);
    }
  };
  const removeAxisItem = (index) =>
  {
    setLifeAxis((prev) => prev.filter((item, i) => i !== index));
  };

  // e.s. kicdev , change order
  /////////////////////////////
  const moveAxis = (i,dir) => 
  {
    let len = lifeAxis.length;
    if( dir==1 && (i+1)==len) {
      console.log("out of boundry ",i,len)
      return;
    }
    if( dir==-1 && i==0) {
      console.log("out of boundry ",i,len)
      return;
    }
    let save=lifeAxis[i];
    lifeAxis[i]=lifeAxis[i+dir];
    lifeAxis[i+dir]=save;
    setLifeAxis(lifeAxis);
    toggleAccordion('mapAccordion');    
  };

  const getGalleryImg = (img) =>
  {
    const videoPlaceHolder =
      'https://res.cloudinary.com/lifecloud-qr/image/upload/v1672574174/photo-1611162616475-46b635cb6868_l2gzrj.avif';
    if (typeof img === 'string') {
      return getFileType(img) === 'image' ? img : videoPlaceHolder;
    }
    return img.type.startsWith('video')
      ? videoPlaceHolder
      : URL.createObjectURL(img);
  };
  const removeCurrentGeoLocation = () =>
  {
    removeGeoLocation();
    setCemeteryLocation('');
  };


  const handleBirthDateChange = (event) =>
  {
    const bday = moment(event?._d).format('YYYY-MM-DD');
    setValue("birthDate", bday);
  };

  const [memDay, setMemDay] = useState(undefined)
  const handleDeathDateChange = (event) =>
  {
    const dday = moment(event?._d).format('YYYY-MM-DD');
    generateDate(dday);
    setValue("deathDate", dday);
  };

  const handleMemorialDateChange = (event) =>
  {
    const dday = moment(event?._d).format('YYYY-MM-DD');
    setMemDay(dday);
    setValue("memorialDate", dday);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | עריכת פרופיל</title>
        <meta
          property="og:url"
          content={`https://lifecloud-qr.com/edit-profile/${profileId}`}
        />
      </Helmet>
      <CenterContentLayout cloudPos="left-right">
        <div className={`container ${classes.profile_create} py-5`}>
          <div className={`${classes.profile_header} text-center`}>
            <h2 className="page_title">עריכת פרופיל</h2>
            <Link to="/profile/62930e650fc791cf90ac210c">
              <Button className={classes.create_button_top}>
                !לחץ לפרופיל לדוגמא
              </Button>
            </Link>
          </div>
          <form className="row" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-lg-8 col-md-10 mx-auto">
              <ProfileAccordion
                isFilled={getValues('profileImg')?.length}
                show={accordion.profileAccordion}
                target="profileImg"
                title="תמונת פרופיל"
                modalText="יש להעלות קובץ תמונה שיופיע כתמונה ראשית בעמוד הפרופיל"
              >
                <label htmlFor="profileImg">
                  <div className={classes.upload}>
                    <img
                      src={profileImg || uploadImg}
                      alt="upload"
                      className={`${profileImg && classes.img_active}`}
                    />
                  </div>
                  <h3 className={classes.upload_button}>בחרו תמונה להעלאה</h3>
                  <input
                    type="file"
                    id="profileImg"
                    className="d-none"
                    accept="image/*"
                    {...register('profileImg', {
                      onChange: onChangeProfilePicture,
                    })}
                  />
                </label>
              </ProfileAccordion>
              <ProfileAccordion
                isFilled={getValues('wallImg')?.length}
                target="wallImg"
                title="תמונת נושא"
                modalText="יש להעלות קובץ תמונה שיופיע כתמונה משנית בעמוד הפרופיל"
                show={accordion.wallAccordion}
              >
                <label htmlFor="wallImg">
                  <div className={classes.upload}>
                    <img
                      src={wallImg || uploadImg}
                      alt="upload"
                      className={`${wallImg && classes.img_active}`}
                    />
                  </div>
                  <h3 className={`${classes.upload_button} d-inline-block`}>
                    בחרו תמונה להעלאה
                  </h3>
                  <input
                    type="file"
                    id="wallImg"
                    className="d-none"
                    accept="image/*"
                    {...register('wallImg', {
                      onChange: onChangeWallPicture,
                    })}
                  />
                </label>
              </ProfileAccordion>

              <div className={`row ${classes.row_gap}`}>

                <div className="col-md-6 order-sm-1 order-0">
                  <input
                    type="text"
                    placeholder="*שם פרטי..."
                    className={`form-control ${formClasses.input} ${classes.input} ${classes.name_input}`}
                    {...register('firstName', {
                      required: true,
                    })}
                  />
                </div>
                <div className="col-md-6 order-sm-0 order-1">
                  <input
                    type="text"
                    placeholder="*שם משפחה..."
                    className={`form-control ${formClasses.input} ${classes.input}`}
                    {...register('lastName', {
                      required: true,
                    })}
                  />
                </div>
              </div>
              <div className={`row ${classes.row_gap}`}>
                <div className="col-md-6">
                  <div className={formClasses.radio_container}>
                    {radioOptions.map((option, index) => (
                      <div className={formClasses.radio_input} key={index}>
                        <input
                          type="radio"
                          id={option.value}
                          className={formClasses.radio}
                          value={option.value}
                          {...register('gender')}
                        />
                        <label htmlFor={option.value}>{option.label}</label>
                      </div>
                    ))}
                    <h3 className={formClasses.radio_label}>מגדר*</h3>
                  </div>
                </div>
                <div className="col-md-6" style={{ marginTop: '10px' }}>

                  <LocalizationProvider dateAdapter={AdapterMoment} locale="he">
                    <DatePicker
                      slotProps={{
                        textField: {
                          placeholder: "ת. לידה לועזי: DD/MM/YYYY",
                          variant: 'standard',
                          InputProps: { disableUnderline: true, style: { fontSize: '16px' } }
                        }
                      }}
                      className={`form-control ${formClasses.input} ${classes.input} ${classes.date_input}`}
                      onChange={handleBirthDateChange}
                      value={getValues("birthDate") ? moment(getValues("birthDate")) : null}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className={`row ${classes.row_gap}`}>
                <div className="col-6">
                  <input
                    type="text"
                    placeholder="תואר..."
                    className={`form-control ${formClasses.input} ${classes.input}`}
                    {...register('degree')}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    placeholder="*עיר..."
                    className={`form-control ${formClasses.input} ${classes.input}`}
                    {...register('city', {
                      required: true,
                      onChange: () => toggleAccordion('dateAccordion'),
                    })}
                  />
                </div>
              </div>
              <ProfileAccordion
                isFilled={getValues('deathDate')}
                target="date"
                title="תאריך פטירה / אזכרה"
                modalText="יש למלא את תאריך הפטירה הלועזי בעמודה הימנית ומיד לאחר מכן, תאריך האזכרה יתמלא אוטומטית על ידי מערכתנו.

              במידה וישנם אילוצים לשינוי תאריך האזכרה, ניתן לסמן את תיבת הסימון מתחת לשדות המילוי ולערוך בעצמכם את התאריך המתקבל.
              "
                show={accordion.dateAccordion}
              >
                <div className="row gx-5">
                  <div className="col-6">
                    <h3
                      className={`form-control ${classes.date_title} bg-transparent mb-4`}
                    >
                      תאריך אזכרה{' '}
                    </h3>
                  </div>
                  <div className="col-6">
                    <h3
                      className={`form-control ${classes.date_title} bg-transparent mb-4`}
                    >
                      תאריך פטירה
                    </h3>
                  </div>

                  <div className="col-6">
                    <LocalizationProvider dateAdapter={AdapterMoment} locale="he">
                      <DatePicker
                        slotProps={{
                          textField: {
                            //placeholder: "ת. אזכרה לועזי: DD/MM/YYYY",
                            placeholder: "ת. אזכרה לועזי",
                            variant: 'standard',
                            InputProps: { disableUnderline: true, style: { fontSize: '16px' } }
                          }
                        }}
                        className={`form-control ${formClasses.input} ${classes.input} ${classes.date_input}`}
                        onChange={handleMemorialDateChange}
                        value={memDay ? moment(memDay) : null}
                        disabled={autoDate}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className="col-6">
                    <LocalizationProvider dateAdapter={AdapterMoment} locale="he">
                      <DatePicker
                        slotProps={{
                          textField: {
                            placeholder: "ת. פטירה לועזי",
                            variant: 'standard',
                            InputProps: { disableUnderline: true, style: { fontSize: '16px' } }
                          }
                        }}
                        className={`form-control ${formClasses.input} ${classes.input} ${classes.date_input}`}
                        onChange={handleDeathDateChange}
                        value={getValues("deathDate") ? moment(getValues("deathDate")) : null}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="col-6" style={{ paddingTop: "20px" }}>
                    <input
                      type="text"

                      placeholder="ת. פטירה עברי"
                      // placeholder="ת. אזכרה עברי"
                      className={`form-control ${formClasses.input} `}
                      {...register('hebDeathDate')}
                      disabled={autoDate}
                      style={{ backgroundColor: 'white', fontSize: '16px', color: 'rgba(0, 0, 0, 0.38)', padding: '1.2rem 3rem' }}
                    />
                  </div>
                  <div className="col-md-12 my-5 d-flex justify-content-center align-items-center">
                    <h3
                      className={`text-white my-0 fw-light fs-sm-6 ${classes.date_bottom_text}`}
                    >
                      לעריכת התאריך האוטומטי שהתקבל סמנו תיבה זו
                    </h3>
                    <input
                      type="checkbox"
                      className={`ms-3 form-check-input text-center ${formClasses.check_box} ${classes.check_box}`}
                      onChange={() => setAutoDate((prev) => !prev)}
                    />
                  </div>
                  <h4 dir="rtl" className={classes.teaser_text}>
                    *לסירוגין יש סטייה בתאריך המתקבל אוטומטית מאחר ותאריך הפטירה
                    הלועזי מחושב כלפני שקיעת אותו היום
                  </h4>
                </div>
              </ProfileAccordion>
              <ProfileAccordion
                isFilled={getValues('gallery')?.length}
                target="gallery"
                title="גלריה"
                modalText="הגלריה מציגה אלבום מדיה של יקירכם, רצוי להעלות עד 8 קבצים בכל העלאה."
                show={accordion.galleryAccordion}
              >
                <label htmlFor="profile">
                  <div
                    className={`${classes.upload} d-flex gap-4 justify-content-center flex-wrap`}
                  >
                    {gallery?.length > 0 ? (
                      gallery.map((img, i) => (
                        <div key={i}>
                          <img
                            src={getGalleryImg(img)}
                            alt="upload"
                            className={classes.img_active}
                          />
                          <p
                            className="text-white my-2"
                            onClick={() => onRemoveGallery(i)}
                            role="button"
                          >
                            מחיקה
                          </p>
                        </div>
                      ))
                    ) : (
                      <img src={uploadImg} alt="upload" />
                    )}
                  </div>

                  <h3 className={classes.upload_button}>בחרו קובץ להעלאה</h3>
                  <h5 className="text-white fw-lighter mt-3">
                    יש לבחור עד 8 קבצים בכל העלאה*
                  </h5>
                  <input
                    type="file"
                    id="profile"
                    multiple
                    className="d-none"
                    accept="image/*, video/*"
                    {...register('gallery', {
                      onChange: onChangeGalleryPicture,
                    })}
                  />
                </label>
              </ProfileAccordion>
              <ProfileAccordion
                isFilled={getValues('description')}
                target="description"
                title="סיפור חיים"
                modalText="ספרו קצת על יקיריכם שתרצו להנציח, שגם החברים יוכלו להכיר יותר, גם המשפחה ואפילו אלה שלא זכו להכיר."
                show={accordion.descriptionAccordion}
              >
                <textarea
                  {...register('description', {
                    onChange: () => toggleAccordion('axisAccordion'),
                  })}
                  placeholder="...טקסט חופשי"
                  className={`form-control ${formClasses.input} ${formClasses.textarea} ${classes.input_focus} bg-transparent mb-4`}
                ></textarea>
              </ProfileAccordion>
              <ProfileAccordion
                target="lifeAxis"
                title="נקודות ציון"
                modalText="הוסיפו נקודות ציון חשובות בדרכם של יקיריכם אותם אתם מנציחים. תוכלו לספר על תקופות בחיים, אירועים חשובים ורגעים משמעותיים."
                show={accordion.axisAccordion}
                isFilled={lifeAxis.length}
              >
                {lifeAxis?.map((axis, i) => (
                  <div
                    className={`row justify-content-center align-items-center ${classes.life_axis}`}
                    key={i}
                  >
                    <div className="col-md-2 order-md-1 order-5 row">
                      <div title="move 1 up" className="col-lg-4 text-white" role="button"
                           onClick={() => moveAxis(i,-1)} 
                           ><h2>⇡</h2></div>
                      <div title="delete" onClick={() => removeAxisItem(i)} role="button" className="col-lg-4 text-white">
                        <h2>🗑</h2></div>
                      <div title="move 1 down" className="col-lg-4 text-white" role="button"
                           onClick={() => moveAxis(i,1)} 
                           ><h2>⇣</h2></div>
                    </div>
                    <div className="col-md-4 order-md-2 order-4">
                      <div>
                        <input
                          type="text"
                          placeholder="ספרו על נקודת הציון..."
                          name="axisDescription"
                          value={axis.axisDescription}
                          onChange={(e) => handleInputChange(e, i)}
                          className={`form-control ${formClasses.input} bg-white mb-4`}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-6 order-md-3 order-2">
                      <div>
                        <input
                          type="text"
                          placeholder="תאריך..."
                          onChange={(e) => handleInputChange(e, i)}
                          name="axisDate"
                          value={axis.axisDate}
                          className={`form-control ${formClasses.input} bg-white mb-4`}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-6 order-md-4 order-3">
                      <div>
                        <input
                          type="text"
                          placeholder="כותרת..."
                          onChange={(e) => handleInputChange(e, i)}
                          name="axisTitle"
                          value={axis.axisTitle}
                          className={`form-control ${formClasses.input} bg-white mb-4`}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 order-md-5 order-1">
                      <div className="my-3">
                        <label
                          htmlFor={`axisImage${i}`}
                          className={classes.upload_text}
                        >
                          <img
                            className={`${axis.axisImage && classes.img_active_lifeAxis
                              }`}
                            src={
                              axis.axisImage
                                ? typeof axis.axisImage === 'string'
                                  ? axis.axisImage
                                  : URL.createObjectURL(axis.axisImage)
                                : uploadImg
                            }
                            alt="upload"
                          />
                          <h5>העלאת מדיה</h5>
                          <input
                            type="file"
                            id={`axisImage${i}`}
                            className="d-none"
                            onChange={(e) => onChangeAxisImg(e, i)}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
                <div className={classes.button_container}>
                  <Button
                    type="button"
                    onClick={addAxisItem}
                    className={classes.add_axis}
                  >
                    +
                  </Button>
                  <p className="d-md-none d-block">
                    הוסף עוד נקודות בסיפור חיים
                  </p>
                </div>
              </ProfileAccordion>

              <ProfileAccordion
                target="map"
                title="מיקום קבר"
                modalText="בסעיף זה, ניתן להוסיף את מיקום הקבר המדוייק על ידי נעיצת סיכה בגוגל מפות ובנוסף גם להוסיף הכוונות הגעה לבית הקברות עצמו על ידי הוספת מיקום בוויז."
                show={accordion.mapAccordion}
                isFilled={getValues('cemetery')}
              >
                <div className={`row gx-5 ${classes.map}`}>
                  <div className="col-md-6 order-md-1 order-2">
                    {!location.loaded && !cemeteryLocation && (
                      <Button
                        className={`${classes.upload_button} w-100 mt-0 py-2 mb-3 d-flex align-items-center justify-content-center`}
                        type="button"
                        onClick={getCurrentGeoLocation}
                        disabled={!getValues('cemetery')}
                      >
                        <img
                          src={location_icon}
                          alt="location"
                          height="25px"
                          className="me-2"
                        />
                        נעיצת מיקומכם הנוכחי כמיקום הקבר
                      </Button>
                    )}
                    {(cemeteryLocation ||
                      (location.loaded && location.coordinates)) && (
                        <Button
                          className={`${classes.upload_button} w-100 mt-0 py-2 mb-3 d-flex align-items-center justify-content-center`}
                          type="button"
                          onClick={removeCurrentGeoLocation}
                          disabled={!getValues('cemetery')}
                        >
                          <img
                            src={location_icon}
                            alt="location"
                            height="25px"
                            className="me-2"
                          />
                          מיקום נוכחי נוסף
                        </Button>
                      )}
                    {location.loaded && location.error && (
                      <Button
                        className={`${classes.upload_button} w-100 mt-0 py-2 mb-3 d-flex align-items-center justify-content-center`}
                        type="button"
                        onClick={getCurrentGeoLocation}
                        disabled={!getValues('cemetery')}
                      >
                        <img
                          src={location_icon}
                          alt="location"
                          height="25px"
                          className="me-2"
                        />
                        יש לאשר שימוש בשירותי מיקום בהגדרות המכשיר
                      </Button>
                    )}
                  </div>
                  <div className="col-md-6 order-md-2 order-1">
                    <input
                      type="text"
                      placeholder="שם בית הקברות"
                      className={`form-control ${formClasses.input} ${classes.input_focus}  bg-transparent mb-4`}
                      {...register('cemetery', {
                        onChange: () => toggleAccordion('privacyAccordion'),
                      })}
                    />
                  </div>
                </div>
              </ProfileAccordion>
              {/* <ProfileAccordion
              target="zoom"
              title="לינק לזום"
              modalImg=""
              show={accordion.zoomAccordion}
              isFilled={getValues('zoom')}
            >
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <input
                    type="text"
                    placeholder="...הוספת לינק לזום"
                    className={`form-control ${formClasses.input} ${classes.input_focus} bg-transparent mb-4`}
                    {...register('zoom', {
                      onChange: () => toggleAccordion('privacyAccordion'),
                    })}
                  />
                </div>
              </div>
            </ProfileAccordion> */}
              <ProfileAccordion
                target="privacy"
                title="פרטיות"
                modalText="יש לבחור את פרטיות הפרופיל החדש:
              ציבורי - פתוח לקהל הרחב ללא הגלבת תכנים
              פרטי - ניתן לראות תכנים רק על ידי אישור חברות
              "
                show={accordion.privacyAccordion}
                isFilled={privacy}
              >
                <div className="d-flex justify-content-center gap-4">
                  <Button
                    className={`${privacy === 'public' && classes.active_privacy
                      } ${classes.privacy_button}`}
                    onClick={() => setPrivacy('public')}
                    type="button"
                  >
                    פרופיל ציבורי
                  </Button>
                  <Button
                    className={`${privacy === 'private' && classes.active_privacy
                      } ${classes.privacy_button}`}
                    onClick={() => setPrivacy('private')}
                    type="button"
                  >
                    פרופיל פרטי
                  </Button>
                </div>
              </ProfileAccordion>
              <div
                className={`${classes.profile_create_button} d-flex justify-content-center`}
              >
                <Button
                  type="button"
                  onClick={() => navigate(-1)}
                  className={classes.submit}
                >
                  <BsArrowLeft className="me-2" />
                  חזרה
                </Button>
                <Button loading={loading} className={classes.submit}>
                  שמירה
                </Button>
                <Button
                  loading={loading}
                  className={classes.submit}
                  type="button"
                  onClick={() => dispatch(showModal('qrFirstModal'))}
                  dir="rtl"
                >
                  רכישת QR
                </Button>
              </div>
            </div>
          </form>
        </div>
      </CenterContentLayout>
      <QrModalTest profileId={profileId} />
    </React.Fragment>
  );
};



export default ProfileEdit;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { showModal } from '../../features/modal/modalSlice';
import Button from '../UI/Button/Button';
import classes from './Hero.module.css';
const Hero = ({ className }) => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  return (
    <section className={`${classes.hero} ${className}`}>
      <div className={classes.hero_content}>
        <div className="container">
          <div className="row">
            <div className="col-xl-9 mx-auto">
              <div className="row align-items-center gy-4 gy-md-0 mx-auto">
                <div className="col-md-6 text-center ">
                  <img
                    src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1676730440/iPhone-X-mockup-hover-seperated_2_1_1_jm0vd6.png"
                    alt="banner"
                    className={`d-md-block d-none mx-auto ${classes.banner_img}`}
                  />
                  <img
                    src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1677071957/iPhone-X-mockup-hover-seperated_2_1_2_kv3tgu.png"
                    alt="banner"
                    className={`d-md-none d-block mx-auto ${classes.banner_img}`}
                  />
                </div>
                <div className={`col-md-6 ${classes.banner_content}`}>
                  <div className={classes.content}>
                    <img
                      src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1676729296/logo-blue_1_x7po3k.png"
                      alt="logo"
                      className={classes.logo}
                    />
                    <h2>יוצרים בית חדש לחיים שאחרי</h2>
                    <h3>
                      אדם הוא עולם ומלואו, סיפור חייו ראוי שיוזכר ויונצח לעד
                    </h3>

                    <div className="d-flex gap-3 justify-content-center align-items-center flex-column flex-md-row">
                      <Button to="profile/62930e650fc791cf90ac210c">
                        לצפייה בעמוד לדוגמא
                      </Button>
                      {user._id ? (
                        <Button to="create-profile">
                          הקמת עמוד ליקירכם - <span>ללא עלות</span>
                        </Button>
                      ) : (
                        <Button
                          onClick={() =>
                            dispatch(showModal('registrationModal'))
                          }
                        >
                          הקמת עמוד ליקירכם - <span>ללא עלות</span>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

import React from 'react';

const Loader = ({ section }) => {
  const loaderStyle = section
    ? { marginTop: '5rem', marginBottom: '5rem' }
    : { height: '100vh' };
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={loaderStyle}
    >
      <div
        className="spinner-border"
        style={{ width: '8rem', height: '8rem' }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;

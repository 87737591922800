import React, { useEffect, useState } from 'react';
import classes from './CFNotificationModal.module.css';
import cross from '../assets/candleflower-modal-img/x.png';
import Button from '../components/UI/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCfComment,
  getCandlesFlowers,
} from '../features/candleFlower/candleFlowerSlice';
import moment from 'moment';
import CfCommentForm from '../components/CfCommentForm/CfCommentForm';

const CFNotificationModal = ({ profileId, userId }) => {
  const { candlesFlowers } = useSelector((state) => state.candleFlower);
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [cfComment, setCfComment] = useState(null);
  const dispatch = useDispatch();
  /*   useEffect(() => {
    dispatch(getCandlesFlowers(profileId));
  }, [dispatch, profileId]);
 */
  const handleComment = (cf) => {
    setCfComment({ cfId: cf, profileId, user: userId });
    setShowCommentForm(true);
  };

  return (
    <React.Fragment>
      <div
        className="modal fade qr-modal"
        id="cfNotification"
        tabIndex="-1"
        aria-labelledby="cfNotificationLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className={`modal-content text-center ${classes.modal_content}`}>
            <div
              className={`modal-header justify-content-between ${classes.modal_header}`}
            >
              <h5
                className={`${classes.modal_title} m-auto`}
                id="cfNotificationLabel"
              >
                דף מנציחים
              </h5>
              <button
                type="button"
                className={`${classes.btn_close}  btn align-self-start`}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src={cross} alt="Close" />
              </button>
            </div>

            <div className={`modal-body ${classes.modal_body}`}>
              <CfCommentForm
                showCommentForm={showCommentForm}
                setShowCommentForm={setShowCommentForm}
                cfComment={cfComment}
              />
              <div className={classes.wrapper}>
                {candlesFlowers.map((cf) => (
                  <div className={classes.notification} key={cf._id}>
                    <div className={`${classes.notification_container}`}>
                      <button
                        onClick={() => handleComment(cf._id)}
                        className={`d-md-block d-none ${classes.add_tribute}`}
                      >
                        <span>+</span>
                        <p>השאר תגובה</p>
                      </button>
                      <div className={classes.notification_contents}>
                        <div className={classes.notification_creator}>
                          <div className={classes.creator_info}>
                            <h6>
                              {cf.user.firstName} {cf.user.lastName}
                            </h6>
                            <p>{cf.dedication}</p>
                            <span>
                              {moment(cf.createdAt).format('DD.MM.YYYY')}
                            </span>
                          </div>
                          <div className={classes.creator_img_container}>
                            <img
                              src={cf.user.profilePicture}
                              alt={cf.user.firstName}
                              className={classes.creator_img}
                            />
                            <div
                              className={`${classes.cf_count} ${classes.cf_count_flower}`}
                            >
                              <p
                                className={`${classes.flower_count} ${classes.count}`}
                              >
                                +{cf.flowers}
                              </p>
                            </div>
                            <div
                              className={`${classes.cf_count} ${classes.cf_count_candle}`}
                            >
                              <p
                                className={`${classes.candle_count} ${classes.count}`}
                              >
                                +{cf.candles}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {cf.comments.map((comment) => (
                      <div
                        className={classes.comment_container}
                        key={comment._id}
                      >
                        <hr className={classes.hr} />
                        <div className={classes.comment_content}>
                          <h5>תגובות:</h5>
                          <div className="d-flex justify-content-end align-items-center gap-2">
                            <p className={classes.comment_text}>
                              {comment.text}
                            </p>
                            <img
                              src={comment.user.profilePicture}
                              alt={comment.user.firstName}
                              className={classes.commenter}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      onClick={() => handleComment(cf._id)}
                      className={`d-md-none d-flex ${classes.add_tribute_2}`}
                    >
                      <span>+</span>
                      <p>השאר תגובה</p>
                    </button>
                  </div>
                ))}
              </div>
              {/*  <div className={classes.buttons}>
                <div className={classes.button_container}>
                  <button>הדלק נר +</button>
                  <img
                    src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1673871864/roundCandle_lgthl1.png"
                    alt="candle"
                  />
                </div>
                <div className={classes.button_container}>
                  <button>הנח זר +</button>
                  <img
                    src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1672831132/Rectangle_60_segjmf.png"
                    alt="flower"
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CFNotificationModal;

import React from 'react';
import { useState } from 'react';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import classes from './AccordionPage.module.css';
import Linkify from 'react-linkify';

function AccordionPage({ content, title }) {
  const [selected, setSelected] = useState(0);
  const info = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <CenterContentLayout bg="#f5fcff" cloudPos="right-left">
      <div className={`container ${classes.accordion_page_container}`}>
        <div className={classes.accordion}>
          <h1 className="page_title">{title}</h1>

          {content.map((c, i) => (
            <div key={i}>
              <div className={classes.title} onClick={() => info(i)}>
                <h3>{c.title}</h3>
                <span className={classes.plus_minus}>
                  {selected === i ? '-' : '+'}
                </span>
              </div>
              <div
                className={
                  selected === i
                    ? `${classes.content} ${classes.show}`
                    : classes.content
                }
              >
                {c.sections &&
                  c.sections.map((section, i) => (
                    <div
                      className={classes.answer_section}
                      key={'section-' + i}
                    >
                      <div className={classes.section_title}>
                        {section.subTitle && section.subTitle}
                      </div>
                      <div>
                        {section.paragraphs &&
                          section.paragraphs.map((para, i) => (
                            <div
                              className={`${classes.policies_content}`}
                              key={`paragraph-${i}`}
                            >
                              <Linkify>{para.p}</Linkify>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </CenterContentLayout>
  );
}

export default AccordionPage;

import React from 'react';
import ProfileModal from '../../modals/ProfileModal';
import Button from '../UI/Button/Button';
import classes from './ProfileAccordion.module.css';
import blankTic from '../../assets/profile-create-img/blank_tic.png';
import filledTic from '../../assets/profile-create-img/filled_tic.png';
const ProfileAccordion = ({
  children,
  target,
  title,
  modalImg,
  modalText,
  show,
  isFilled,
}) => {
  return (
    <React.Fragment>
      <ProfileModal
        src={modalImg}
        bsTarget={`${target}Modal`}
        modalText={modalText}
      />
      <div className="accordion my-5">
        <div className={`accordion-item ${classes.accordion_item}`}>
          <h2 className={`accordion-header ${classes.accordion_header}`}>
            <Button
              type="button"
              className={classes.button}
              outline
              data-bs-toggle="modal"
              data-bs-target={`#${target}Modal`}
            >
              !לחץ להסבר
            </Button>
            <div
              className={`accordion-button  ${
                !show && 'collapsed'
              }  d-flex justify-content-end align-items-center  ${
                classes.accordion_button
              }`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#${target}Accordion`}
            >
              <span className={classes.accordion_title}>{title}</span>
              {isFilled ? (
                <img
                  src={filledTic}
                  alt="check"
                  height="25px"
                  className="ms-2"
                />
              ) : (
                <img
                  src={blankTic}
                  alt="check"
                  height="25px"
                  className="ms-2"
                />
              )}
            </div>
          </h2>
          <div
            id={`${target}Accordion`}
            className={`accordion-collapse ${show ? 'show' : 'collapse'}`}
          >
            <div className={`accordion-body ${classes.accordion_body}`}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileAccordion;

import React, { useState } from 'react';
import Button from '../UI/Button/Button';
import classes from './ProfileCover.module.css';
import { HiOutlineMinus, HiOutlinePlus } from 'react-icons/hi';
import { editProfile } from '../../features/profile/profileSlice';
import { useDispatch } from 'react-redux';
import { TbEdit } from 'react-icons/tb';

const ProfileCover = ({ isOwnerOrAdmin, profileId, wallImg, objectYPos }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [yPos, setYPos] = useState(objectYPos);
  const dispatch = useDispatch();

  const handleObjectPos = async (what) => {
    if (yPos <= 90 && what === 'up') {
      setYPos(yPos + 10);
    } else if (yPos >= 10 && what === 'down') {
      setYPos(yPos - 10);
    }
  };
  const handleSubmit = () => {
    setShowEdit(false);
    const formData = { objectYPos: yPos };
    dispatch(editProfile({ profileId, formData }));
  };
  return (
    <section className={`${classes.profileCover} position-relative`}>
      <img
        src={wallImg}
        alt="profileCover"
        className={classes.wallImg}
        style={{ objectPosition: `0 ${yPos}%` }}
      />

      {isOwnerOrAdmin && (
        <div className={classes.relocate_cover}>
          {!showEdit ? (
            <button onClick={() => setShowEdit(true)}>
              <TbEdit />
            </button>
          ) : (
            <React.Fragment>
              <button onClick={() => handleObjectPos('up')}>
                {/* <HiOutlinePlus /> */}
                <i class="fa-sharp fa-solid fa-angle-up"></i>
              </button>
              <button onClick={() => handleObjectPos('down')}>
                {/* <HiOutlineMinus /> */}
                <i class="fa-solid fa-angle-down"></i>
              </button>
              <button
                onClick={handleSubmit}
                className={classes.reLocator_submit}
              >
                שמירה
              </button>
            </React.Fragment>
          )}
        </div>
      )}
    </section>
  );
};

export default ProfileCover;

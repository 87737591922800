import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import lifeAxios from '../../utils/lifeAxios';
import { add_analytics } from "../gtagFunction.js";
// import { updateReceiveEmails } from '../path/to/userController';

/* Initial State */
const initialState = {
  loading: true,
  user: {},
  error: null,
  tokenError: null,
};
/* It will store jwt token in the local storage */
const storeJwt = (jwt) => {
  localStorage.setItem('jwt', jwt);
};
/* It will remove jwt token from the local storage */
const removeJwt = () => {
  localStorage.removeItem('jwt');
};


/* Signup User */
export const userSignup = createAsyncThunk(
  'user/signup',
  async ({ formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/users/signup`,
        formData
      );
      if (response.data.status === 'success') storeJwt(response.data.token);
      showNotification('success');
      //
      // kicdev # task1
      // add signals
      // e.s. 21.02.2023
      //////////////////
      add_analytics("registration", response, "email");

        /* Document response.data.data.user :
        __v: 0
        _id: "63f4d5a66e964935931205ef"
        active: true
        createdAt: "2023-02-21T14:31:02.906Z"
        email: "yaweli+1@gmail.com"
        firstName: "eli1"
        gender: "male"
        lastName: "smadar1"
        phone: "0523255581"
        profilePicture: "https://res.cloudinary.com/lifecloud-qr/image/upload/v1664810314/userIcon_tiznk1.png"
        receiveEmails: true
        role: "user"
        updatedAt: "2023-02-21T14:31:02.906Z"
        verified: false
        */ 
      // console.log(response.data.data)
      return response.data.data.user;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Login User */
export const userLogin = createAsyncThunk(
  'user/login',
  async ({ formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/users/login`,
        formData
      );
      if (response.data.status === 'success') storeJwt(response.data.token);
      showNotification('success');
      //
      // kicdev # task1
      // add signals
      // e.s. 21.02.2023
      //////////////////
      add_analytics("login", response, "email");
      return response.data.data.user;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Login User */
export const userLoginWithSocialMedia = createAsyncThunk(
  'user/loginWithSocialMedia',
  async ({ formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/users/loginWithSocialMedia`,
        formData
      );
      if (response.data.status === 'success') storeJwt(response.data.token);
      showNotification('success');
      //
      // kicdev # task14
      // add signals
      // e.s. 21.02.2023
      //////////////////
      add_analytics("login", response, "SocialMedia");
      return response.data.data.user;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Logout User */
export const userLogout = createAsyncThunk(
  'user/logout',
  async (_, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/users/logout`
      );
      if (response.data.status === 'success') removeJwt();
      //
      // kicdev # task14
      // add signals
      // e.s. 21.02.2023
      //////////////////
      add_analytics("logout", response, "none");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Get the current login user */
export const getMe = createAsyncThunk('user/me', async () => {
  try {
    const response = await lifeAxios(localStorage.getItem('jwt')).get(
      `/users/me`
    );
    return response.data.data.data;
  } catch (error) {
    return error.response.data;
  }
});
export const updateMe = createAsyncThunk('user/updateMe', async (formData) => {
  try {
    const response = await lifeAxios(localStorage.getItem('jwt')).patch(
      `/users/updateMe`,
      formData
    );
      //
      // kicdev # task14
      // add signals
      // e.s. 21.02.2023
      //////////////////
      add_analytics("updateMe", response, "none");
    return response.data.data.user;
  } catch (error) {
    return error.response.data;
  }
});
/* Verify Email */
export const verifyEmail = createAsyncThunk(
  'user/verifyEmail',
  async (token, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/users/verifyEmail/${token}`
      );
      return response.data.data.user;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Forgot Password */
export const forgotPassword = createAsyncThunk(
  'user/forgotPassword',
  async ({ formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/users/forgotPassword`,
        formData
      );
      showNotification('success');
            //
      // kicdev # task14
      // add signals
      // e.s. 21.02.2023
      //////////////////
      add_analytics("forgotPassword", response, "none");

      return response.data.data.user;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Reset Password */
export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async ({ token, formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/users/resetPassword/${token}`,
        formData
      );
      if (response.data.status === 'success') storeJwt(response.data.token);
      showNotification('success');
      return response.data.data.user;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const userUpdateReceiveEmails = createAsyncThunk(
  'user/updateReceiveEmails',
  async ({ userId, receiveEmails }, { rejectWithValue }) => {
    console.log("hi");
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/users/${userId}/receiveEmails`,
        { receiveEmails }
      );
      return response.data.data.user;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);


/* User Reducer */
const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(verifyEmail.rejected, (state, action) => {
        state.tokenError = action.payload;
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.user = {};
        state.loading = false;
        state.error = null;
        state.tokenError = null;
      })
      .addCase(userUpdateReceiveEmails.fulfilled, (state, action) => {
        state.loading = false;
        state.user.receiveEmails = action.payload.receiveEmails;
        state.error = null;
      })
      .addCase(userUpdateReceiveEmails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addMatcher(
        isAnyOf(
          userSignup.pending,
          userLogin.pending,
          userLoginWithSocialMedia.pending,
          verifyEmail.pending,
          forgotPassword.pending,
          resetPassword.pending,
          getMe.pending,
          updateMe.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          userSignup.fulfilled,
          userLogin.fulfilled,
          userLoginWithSocialMedia.fulfilled,
          verifyEmail.fulfilled,
          forgotPassword.fulfilled,
          resetPassword.fulfilled,
          getMe.fulfilled,
          updateMe.fulfilled
        ),
        (state, action) => {
          state.loading = false;
          state.user = action.payload;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          userSignup.rejected,
          userLogin.rejected,
          userLoginWithSocialMedia.rejected,
          verifyEmail.rejected,
          forgotPassword.rejected,
          resetPassword.rejected,
          getMe.rejected,
          updateMe.rejected
        ),
        (state, action) => {
          state.loading = false;
          state.user = {};
          state.error = action.payload;
        }
      );
  },
});

export default userSlice.reducer;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import AccordionPage from './AccordionPage';
import Linkify from 'react-linkify';

const Regulations = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | תקנון שימוש</title>
        <meta property="og:title" content="LifeCloud | תקנון שימוש" />
        <meta
          property="og:url"
          content="https://lifecloud-qr.com/regulations"
        />
        <meta
          name="description"
          content="לעיונכם תקנון השימוש או תנאי השימוש באתר ובפלטפורמה. השימוש בפלטפורמה מהווה אישור לתנאי השימוש והתקנון. ניתן ליצור קשר להברה או שאלה."
        />

        <meta
          property="og:description"
          content="לעיונכם תקנון השימוש או תנאי השימוש באתר ובפלטפורמה. השימוש בפלטפורמה מהווה אישור לתנאי השימוש והתקנון. ניתן ליצור קשר להברה או שאלה."
        />
      </Helmet>
      <AccordionPage content={regulations} title="תקנון / תנאי שימוש" />
    </React.Fragment>
  );
};
const regulations = [
  {
    title: '1. כללי ',
    sections: [
      {
        paragraphs: [
          {
            p: 'ברוכים הבאים לאתר LifeCloud (להלן: "האתר"), המופעל על ידי חברת חיים בענן בע"מ, מספר חברה 516510047, מרחוב השדה 101, כפר חיטים 1528000 (להלן: "החברה").',
          },
          {
            p: 'האתר משמש פלטפורמה קהילתית מקוונת להנצחה ולזיכרון, באמצעותה ניתן, בין היתר, להנציח אדם שנפטר ולנהל קבוצה הפועלת לפרסם ולנהל דפי זיכרון, לשתף בחוויות, סיפורים וזיכרונות אודות אדם שנפטר, לפרסם הספדים אודותיו, תמונות מתחנות חייו, לתזכר בימי אזכרה והנצחות וכן לקבל וליהנות מתכנים ושירותים נלווים נוספים.',
          },
          {
            p: 'תנאי השימוש להלן (להלן: "תנאי השימוש") מסדירים את היחסים בין החברה לבין כל אדם הגולש או העושה שימוש באתר ובמידע ובתכנים הכלולים בו (להלן: "המשתמש"). הגלישה והשימוש באתר ייחשבו להסכמה מצד המשתמש לכל התנאים וההתניות הכלולים בתנאי השימוש.',
          },
          {
            p: 'אין בתנאי השימוש כדי לגרוע או לשנות מכל הסכם אחר בין המשתמש ו/או גוף כלשהו לבין החברה, ובכלל זה הסכמי מסגרת ארגוניים או קבוצתיים להנצחה. יש לראות בתנאי שימוש אלה ובמסמכים ובהסכמים האחרים של המשתמש או כל גורם אחר מול החברה כמשלימים אחד את השני. למעט אם נקבע אחרת, בכל מקרה של סתירה בין תנאי השימוש לבין הסכמים ומסמכים אלו, יגבר האמור בתנאי שימוש אלה. ',
          },
          {
            p: '**https://www.lifecloud-qr.com/policy - השימוש באתר כפוף לתנאי השימוש המפורטים להלן. יש לקרוא בעיון את תנאי השימוש בטרם השימוש באתר, שכן השימוש בו ייחשב להסכמה מצד המשתמש לתנאי השימוש וכן להוראות מדיניות הפרטיות אליהן הוא מפנה (בהן ניתן לעיין כאן**). יובהר, כי המשתמש אינו חייב להסכים לאמור בתנאי השימוש, אולם אם אינו מסכים לאמור בהם, עליו לצאת מהאתר ולא לעשות בו כל שימוש.',
          },
          {
            p: 'זמינות המידע והתכנים במסגרת האתר אינה תמידית ולעיתים אף כפופה להוראות הדין או להסכמים של החברה ו/או מי מטעמה עם צדדים שלישיים, לפיכך החברה תהיה רשאית, על פי שיקול דעתה הבלעדי, לעדכן ו/או לבטל ו/או להסיר יישום, שירות, מוצר או תוכן ספציפי מהאתר, מעת לעת, באופן מידי וללא התראה מראש ובכפוף לדין החל, ולמשתמש לא תהא כל טענה ו/או דרישה בקשר לכך.',
          },
          {
            p: 'החלוקה בתנאי השימוש לסעיפים וכותרות הסעיפים הינם לשם נוחות בלבד ולא תשמש לצרכי פרשנות. כל האמור במסמך זה בלשון זכר - אף נקבה במשמע. כל האמור במסמך זה בלשון יחיד - אף רבים במשמע.',
          },
        ],
      },
    ],
  },
  {
    title: '2. השימוש באתר ',
    sections: [
      {
        paragraphs: [
          {
            p: 'השימוש באתר ובתכנים המוצעים בו ניתן כמות שהוא (as-is). כל המידע והנתונים הכלולים באתר, לרבות מידע אודות השירותים המוצעים דרך האתר, מעודכנים ונכונים אך ורק למועד הרלוונטי בו נעשה בהם שימוש.',
          },
          {
            p: 'משתמשים מתחת לגיל 18 או משתמשים שאינם כשירים לבצע פעולות משפטיות לפי דיני מדינת ישראל, רשאים לעשות שימוש בשירותי האתר בכפוף ליידוע וקבלת ההסכמה של ההורים או האפוטרופוסים החוקיים שלהם לשימוש בשירותי האתר, לביצוע פעולות במסגרתו, לקבלת תנאי שימוש אלה, לרבות תנאי הגבלת האחריות כאמור להלן. החברה תהיה רשאית לדרוש כי הסכמה כאמור תיעשה באמצעות שיחת טלפון, אישור בכתב או באמצעי אחר, לפי שיקול דעתה של החברה והנחיותיה. על ההורים או האפוטרופוסים החוקיים חלה אחריות לפקח על פעילות המשתמשים כאמור על מנת שאלו יפעלו בהתאם להוראות תנאי שימוש אלה. כל פעילות אשר תבוצע על ידי משתמשים כאמור באתר מהווה הסכמה של המשתמשים ו/או ההורים או האפוטרופוסים החוקיים שלהם לאמור בתנאי שימוש אלה.',
          },
          {
            p: 'החברה עושה ככל יכולתה לוודא שהמידע המוצג באתר יהיה המידע השלם והמדויק ביותר אך יובהר, כי עלולים להופיע בו, בתום לב, אי דיוקים או שגיאות ובכפוף לאמור בדין החל, החברה לא תישא באחריות כל שהיא הנובעת מהן או קשורה אליהן.',
          },
          {
            p: 'רישומי המחשב של החברה בלבד, בדבר הפעולות המתבצעות דרך האתר יהוו ראיה לכאורה לנכונות הפעולות.',
          },
          {
            p: 'המשתמש מתחייב לעשות שימוש באתר, במידע, בתכנים, במוצרים ובשירותים המופצים או הכלולים בו אך ורק על פי החוק ובהתאם להוראות תנאי השימוש.',
          },
          {
            p: 'המשתמש מתחייב שלא לקשר (link) לאתר ו/או להשתמש בו ו/או במידע, בתכנים, במוצרים ובשירותים הכלולים בו, לרבות על ידי יצירת סביבת דפדפן, תיחום (frame) או יצירת העתק (mirror) לצורך העלאה, הורדה, הפצה, פרסום או שידור של: (א) מידע או חומר אחר באופן המפר זכויות כלשהן, לרבות, זכויות קניין רוחני, זכויות להגנת הפרטיות ו/או כל זכות קניינית אחרת; (ב) מידע או חומר אחר האסור לפרסום או לשימוש, בשל היותו בגדר איום, פגיעה, העלבה, הוצאת דיבה, השמצה, גזענות או תוכן בלתי ראוי; (ג) מידע או חומר אחר הכולל וירוס, רוגלה, נוזקה, סוס טרויאני או כל קוד מחשב, קובץ, יישום או תוכנה אחרים אשר עשויים להזיק או לחבל במערכות מחשב של החברה ו/או של צדדים שלישיים כלשהם או באופן העלול להגביל או למנוע מאחרים את השימוש באתר; או (ד) מידע או חומר אחר הכולל פרסומת מכל סוג שהוא בלי הרשאה מראש ובכתב של החברה.',
          },
          {
            p: 'המשתמש מתחייב כי בעת השימוש שלו באתר ובשירותים או בתכנים המוצעים בו הוא לא יפר ו/או יפגע בזכויות המשתמשים לפרטיות וזכויות אחרות, או יאסוף מידע אישי מזהה אודות משתמשים אחרים ללא הסכמתם המפורשת, בין אם באופן ידני או באמצעות שימוש בכל רובוט, עכביש, כל יישום חיפוש או אחזור, או שימוש באמצעי, בתהליך או בשיטה ידניים או אוטומטיים אחרים על מנת להיכנס לאתר ולאחזר, לאסוף ו/או לשאוב מידע.',
          },
          {
            p: 'המשתמש מתחייב שלא לפגוע, להכביד או לשבש את פעולת האתר או השרתים המאחסנים את האתר, או להפר כל חוק, תקנה, דרישה, נוהל או מדיניות של שרתים כאמור, לבצע כל פעולה היוצרת או העלולה ליצור עומס גדול ובלתי סביר על תשתית האתר או לעקוף את האמצעים בהם החברה משתמשת על מנת למנוע או להגביל את הגישה לאתר, לרבות אמצעים המיועדים למנוע שימוש בלתי מורשה באתר.',
          },
          {
            p: 'המשתמש מסכים כי, מבלי לפגוע בכל זכות אחרת של החברה, במקרים בהם החברה תחשוש כי השימוש של המשתמש באתר אינו מתיישב עם תנאי השימוש ו/או כל דין, היא תהיה רשאית להפסיק פעילותו של המשתמש באתר, לרבות על ידי חסימת מספר IP או כל פעולה אחרת, להתחקות אחר השימוש של המשתמש באתר, להעביר את דפוסי התנהגות המשתמש לרשויות הרלוונטיות ו/או לצדדים שלישיים אשר יוכיחו, להנחת דעתה של החברה, כי הינם נפגעים מפעילות המפרה של המשתמש וכן כל פעולה אחרת אשר החברה תמצא לנכון לנקוט כדי להגן על קניינה ו/או על זכויותיה ו/או זכויות צדדים שלישיים. ',
          },
        ],
      },
    ],
  },

  {
    title: '3. הגבלת השימוש באתר ',
    sections: [
      {
        subTitle:
          'מבלי לגרוע מהאמור לעיל, תהא החברה רשאית למנוע ממשתמש מלעשות כל שימוש באתר ו/או לחסום או למנוע גישה או אף להוריד מהאתר עמוד הנצחה בכל אחד מהמקרים הבאים:',
        paragraphs: [
          {
            p: '(1) המשתמש ביצע מעשה בלתי חוקי ו/או עבר על הוראות הדין;',
          },
          {
            p: '(2) המשתמש הפר תנאי מתנאי השימוש;',
          },
          {
            p: '(3) המשתמש מסר לחברה פרטים שגויים בעת ההצטרפות לשירותי החברה;  ',
          },
          {
            p: '(4) לפי שיקול דעתה הסביר של החברה בנסיבות עניין ספציפי;',
          },
          {
            p: `(5) המשתמש ביצע מעשה או מחדל שיש בו כדי לפגוע בחברה, במי מטעמה ו/או בצד ג' כלשהו, לרבות משתמשים אחרים באתר או בפעילות התקינה של האתר;`,
          },
          {
            p: '(6) קיים סיכון לביטחון או פרטיות חשבון המשתמש ו/או עמוד הנצחה; ',
          },
          {
            p: '(7) קיים איום על הביטחון או השלמות של נכסי החברה ו/או תשתיות מערכות המידע שלה; ',
          },
          {
            p: '(8) חסימה, הפסקה או הגבלת השימוש על ידי החברה נדרשת כדי להגן על זכויות, רכוש או בטיחות של החברה, המשתמשים באתר או הציבור.',
          },
          {
            p: '(9) למשתמש לא תהיה כל טענה ו/או דרישה ו/או תביעה כנגד החברה ו/או מי מטעמה בגין האמור לעיל ואין בחסימה, הפסקה או הגבלת השימוש על ידי החברה כדי לגרוע מחובתו של המשתמש לנהוג בכל עת בהתאם לתנאי שימוש אלו.',
          },
        ],
      },
    ],
  },
  {
    title: '4. שירותי האתר',
    sections: [
      {
        subTitle: 'ניהול עמוד הנצחה',
        paragraphs: [
          {
            p: `(1)משתמש יהיה רשאי להקים ולנהל, ללא תשלום (מלבד שירותים או מוצרים ספציפיים בתשלום) עמוד הנצחה פרטי עבור נפטר או קבוצת נפטרים בלבד (להלן: "עמוד הנצחה"). החברה שומרת לעצמה את הזכות, לגבות תשלום בגין שירות עמוד ההנצחה, בכל עת, על ידי מסירת הודעה למשתמשים, בכפוף להוראות הדין.`,
          },
          {
            p: `(2) על מנת להקים ולנהל עמוד הנצחה, על המשתמש להירשם תחילה לאתר, ולספק לחברה פרטי רישום וזיהוי אודותיו (כגון שם, תאריך לידה, דרכי התקשרות, סיסמת התחברות וכו').`,
          },
          {
            p: `(3) משתמש שהקים עמוד הנצחה ייחשב למנהל העמוד (להלן: "מנהל העמוד" או "לקוח") ויוקנו לו הרשאות גישה ושימוש של מנהל (Admin) ביחס לעמוד הנצחה זה (להלן: "זכויות ניהול"), הכוללות, בין היתר, זכות לעצב ולנהל את עמוד ההנצחה בהתאם לשדות המידע המוצעים, אישור וצירוף חברים לקבוצה, שליחת הזמנות לקבוצה, שליחת תזכורות לקבוצה, ניהול קיר (wall) המאפשר למנהל העמוד ולחברי הקבוצה הסגורה לשתף ולעלות תכנים נוספים אודות הנפטר (לרבות תמונות וסרטונים), שיתוף מיקום מדויק של הקבר, ניהול לוח שנה, ניהול יומן אירועים ושליחת תזכורות, הסרה של תכנים, הסרת חברים מהקבוצה, סגירת עמוד ההנצחה, הענקת זכויות ניהול לחברים נוספים וכו'. `,
          },
          {
            p: `(4) במסגרת ההרשמה לאתר והקמת עמוד ההנצחה, מנהל העמוד יתבקש לבחור סיסמא באמצעותה הוא יוכל להתחבר בעתיד לחשבון המשתמש שלו. השימוש בחשבון האישי באתר הוא של המשתמש בלבד, אישי, בלתי עביר ועל המשתמש לשמור על פרטי חשבונו, לרבות סיסמתו האישית, ולא לגלותם לאף אדם. על הסיסמא לכלול, לכל הפחות, 8 תווים (או לעמוד בכל דרישה אחרת שתקבע החברה לעניין זה).`,
          },
          {
            p: `(5) עם השלמת הרישום והקמת עמוד ההנצחה, מנהל העמוד יקבל מהחברה באמצעות הדוא"ל קוד QR , הניתן לסריקה באמצעות מכשיר קצה סלולרי ולשיתוף עם צדדים שלישיים שיוכלו, באמצעות קוד זה, לגשת לעמוד ההנצחה ולתכנים הכלולים בו. מובהר כי מנהל העמוד יהא אחראי באופן בלעדי על ניהול הקוד והפצתו לצדדים שלישיים וכי החברה לא תהיה אחראית לכל נזק או הפסד שיגרמו למנהל העמוד ו/או מי מטעמו ו/או לכל צד שלישי אחר בקשר עם השימוש בקוד על ידי מנהל העמוד או משתמשים אחרים. יובהר כי הקוד שיישלח על ידי החברה לפי סעיף 4.5 זה אינו תבליט QR מעוצב אותו ניתן לרכוש מהחברה בנפרד ובתשלום.`,
          },
          {
            p: `(6) במסגרת ההרשמה, על המשתמש לספק לחברה מידע מלא, מדויק ונכון, והנך מסכים בזאת כי לא תציג שלא כהלכה את זהותך או כל פרט אחר בחשבונך. החשבון מוגן בסיסמא והנך אחראי באופן בלעדי ומלא, לקבוע ולעדכן את סיסמת הכניסה, לשמור על סודיות הסיסמה ועל כל פעילות אשר תתבצע תחת החשבון שלך. היה החברה תסבור כי המשתמש נרשם לשירותי האתר באמצעות פרטי זיהוי של אדם אחר, או שמסר פרטים שגויים או מזויפים בכוונה להונות, הרי שבכך המשתמש חושף את עצמו לאחריות פלילית ו/או אזרחית. `,
          },
          {
            p: `(7) מנהל העמוד רשאי לקבוע את סוג פרופיל העמוד - פרטי (קרי, פתוח אך ורק למשתמשים שאושרו על ידי מנהל העמוד) או ציבורי (פתוח לכלל הציבור). קביעת סוג הפרופיל היא באחריותו הבלעדית של מנהל העמוד והחברה לא תישא בכל אחריות בעניין זה.`,
          },
          {
            p: `(8) הקמת עמוד הנצחה כפופה לאישור החברה. החברה שומרת לעצמה את הזכות לאשר, לדחות או להגביל בקשת הקמה כלשהי, על פי שיקול דעתה הסביר בנסיבות העניין (למשל, מסירת פרטים שגויים וכו') ולא תתקבל כל טענה ו/או דרישה כלפי החברה בקשר לכך. `,
          },
          {
            p: `(9) אדם (שאינו מנהל עמוד) אשר מעוניין לקבל גישה לתכני עמוד הנצחה שאינם פתוחים בפניו, יתבקש לשלוח בקשת הצטרפות למנהל העמוד ולהמתין לאישורה או לדחייתה. יובהר כי אישור הצטרפותו של אדם כלשהו לעמוד הנצחה אינו נתון לפיקוח או בקרה מצד החברה אלא לשיקול דעתו הבלעדי של מנהל העמוד אליו נשלחה בקשת ההצטרפות.`,
          },
          {
            p: `(10) בקשת הצטרפות לעמוד הנצחה יכולה להתבצע על ידי פנייה למנהל העמוד באחת הדרכים הבאות: סריקת ברקוד (אשר ישותף על ידי מנהל העמוד או יופיע בפומבי; לחיצה על לינק ייעודי (שיישלח על ידי מנהל עמוד); ביצוע חיפוש פרטני של עמוד ההנצחה במנגנון החיפוש של האתר ושליחת בקשת הצטרפות באמצעות האתר; ועוד. 
              `,
          },
          {
            p: `(11) שטח האחסון של עמוד ההנצחה מוגבל עד לנפח של 1 GB.`,
          },
          {
            p: `(12) מעת לעת, החברה עשויה להציע תכנים ושירותים נוספים בתשלום במסגרת האתר (למשל, הדלקת נר וירטואלי, הנחת פרחים וירטואליים, שירותי הטמעת חומרים בעמוד ההנצחה, לוחית QR קשיחה ומעוצבת וכו'). החברה אינה מתחייבת להציע מוצרים או שירותים כלשהו או לתקופה מסוימת והצעה כאמור תיעשה לפי שיקול דעתה הבלעדי של החברה. יודגש כי הזכות לקבלת התשלומים בגין התכנים והשירותים הנוספים בתשלום במסגרת האתר הינה של החברה בלבד. 
              `,
          },
          {
            p: `(13) כל הסכומים והמחירים המוצגים באתר כוללים מע"מ (במידה וחל תשלום מע"מ על הרכישה לפי כל דין), אלא אם צוין מפורשות אחרת. החברה רשאית לעדכן את מחירי השירותים באתר מעת לעת ובלא צורך בהודעה מוקדמת. המחיר התקף ביחס להזמנה שבוצעה הוא המחיר שהתפרסם באתר בעת השלמת תהליך הרכישה. אם עודכנו המחירים לפני שהושלם תהליך הרכישה על ידי המשתמש, יחויב המשתמש לפי המחירים המעודכנים. `,
          },
          {
            p: `(14) ניתן לשלם עבור מוצרים ושירותים באתר באמצעות כרטיס אשראי ישראלי או בינלאומי תקף. היה ונעשה שימוש בכרטיס אשראי של צד שלישי כלשהו, השימוש בכרטיס האשראי הנ"ל הנו על דעתו ובהסכמתו המלאה של הבעלים הרשום של כרטיס האשראי, והמשתמש יישא בלעדית באחריות המלאה וישפה את החברה באופן מידי בגין כל תביעה ו/או דרישה של הבעלים של כרטיסי האשראי או צד שלישי כלשהו בקשר עם האמור, וזאת מבלי לגרוע מזכויותיה של החברה על פי תנאי השימוש ו/או הוראות כל דין.`,
          },
        ],
      },
    ],
  },
  {
    title: `5. ביטול עסקה`,
    sections: [
      {
        paragraphs: [
          {
            p: `5.1 לקוח אשר רכש מוצר או שירות עמוד באתר יהיה רשאי לבטל את העסקה, בכפוף להוראות חוק הגנת הצרכן, התשמ"א – 1981 (להלן: "חוק הגנת הצרכן"). ביטול כאמור, ככל והדין מאפשר זאת, ייעשה באמצעות פנייה לחברה, באחת מהדרכים האמורות בסעיף 4.14(ה) להלן. `,
          },
          {
            p: `5.2 לקוח אשר רכש מוצר באתר, יהיה רשאי לבטל את העסקה, מיום עשיית העסקה ועד ארבעה עשר ימים מיום קבלת המוצר או מיום קבלת מסמך גילוי לפי חוק הגנת הצרכן, לפי המאוחר מביניהם.`,
          },
          {
            p: `5.3 לקוח אשר רכש שירות באתר, יהיה רשאי לבטל את העסקה, בתוך ארבעה עשר ימים מיום עשיית העסקה או מיום קבלת מסמך גילוי לפי חוק הגנת הצרכן, לפי המאוחר מביניהם ובעסקה שאינה עסקה מתמשכת – בתנאי שביטול כאמור ייעשה לפחות שני ימים, שאינם ימי מנוחה, קודם למועד שבו אמור השירות להינתן.
              `,
          },
          {
            p: `5.4 החברה תהיה רשאית לגבות דמי ביטול בסך של 5% מערך העסקה או 100 ₪, הנמוך מביניהם, בכל מקרה של ביטול עסקה לרכישת מוצר או שירות באתר, שלא כתוצאה מהפרת התחייבויותיה של החברה. `,
          },
          {
            p: `5.5 במקרה של ביטול עסקה, תחזיר החברה ללקוח, בתוך 14 ימים מיום קבלת ההודעה על הביטול, את אותו חלק ממחיר העסקה ששולם על ידי הלקוח (בניכוי דמי ביטול, ככל והחברה תבחר לגבותם), תבטל את חיובו של הלקוח בשל העסקה (ככל וטרם חויב) ותמסור לו עותק מהודעת ביטול החיוב כאמור. `,
          },
          {
            p: `5.6 לקוח שהינו אדם עם מוגבלות, אזרח ותיק או עולה חדש, כהגדרתם בחוק הגנת הצרכן, יהיה רשאי לבטל מוצר או שירות שהוזמן בתוך 4 חודשים מיום השלמת העסקה או מיום קבלת מסמך גילוי לפי חוק הגנת הצרכן, לפי המאוחר מביניהם, ובלבד שההתקשרות בין החברה לבין הלקוח כללה שיחה, ובכלל זה שיחה באמצעות תקשורת אלקטרונית. במקרה כזה, החברה תהיה רשאית לדרוש מהלקוח להציג בפניו תעודה המוכיחה שהוא אדם עם מוגבלות, אזרח ותיק או עולה חדש, לפי העניין, בהתאם להוראות חוק הגנת הצרכן.`,
          },
          {
            p: `5.7 זכות הביטול לא תחול על עסקה לרכישת:`,
          },
          {
            p: `טובין פסידים;`,
          },
          {
            p: `מידע כהגדרתו בחוק המחשבים, התשנ"ה-1995;
              `,
          },
          {
            p: `טובין שיוצרו במיוחד בעבור הלקוח בעקבות העסקה;
              `,
          },
          {
            p: `טובין הניתנים להקלטה, לשעתוק או לשכפול, שהלקוח פתח את אריזתם המקורית.
              `,
          },
          {
            p: `5.8 הלקוח יהיה רשאי להודיע לחברה על ביטול עסקה בכל אחת מהדרכים המפורטות להלן:
  
              בטלפון: 052-3753750
              בפנייה בדואר רשום בכתובת: חיים בענן בע"מ רחוב השדה 101 כפר חיטים
              בדואר אלקטרוני: office@lifecloud-qr.com
              באמצעות קישור ייעודי לביטול עסקה שיופיע באתר החברה.
              `,
          },
          {
            p: `בהודעת הביטול יפרט הלקוח את שמו. לצורך זירוז הטיפול בפנייה, מומלץ לציין במסגרת ההודעה גם את הסיבה לביטול, לרבות, אי ההתאמה של השירות – ככל שרלוונטי, מספר הזמנה וכל פרט אחר בקשר עם ההזמנה הרלוונטית.`,
          },
          {
            p: `5.9 כל זיכוי כספי יועבר לכרטיס האשראי או לאמצעי התשלום בו בוצעה ההזמנה בלבד, ובהתאם ללוחות הזמנים של חברת האשראי.
              `,
          },
          {
            p: `5.10 החברה תאפשר למשתמש להוריד את התכנים הכלולים בעמוד ההנצחה בתוך 30 ימים ממועד סיום השירות, מכל סיבה שהיא. בתום תקופה זו, תהיה החברה רשאית למחוק את התכנים לצמיתות ולמשתמש לא תהיה כל טענה ו/או תביעה ו/או דרישה כלפי החברה בעניין זה.
              `,
          },
        ],
      },
    ],
  },
  {
    title: `6. ביטול השירות על ידי החברה
      `,
    sections: [
      {
        paragraphs: [
          {
            p: `6.1 החברה תהיה רשאית לבטל את השירות עבור המשתמשים ולהסיר את עמוד ההנצחה והתכנים הכלולים בו, בכל עת, על ידי מתן הודעה למשתמשים, בכל אחד מהמקרים הבאים:`,
          },
          {
            p: `הפרה יסודית של תנאי השימוש מצד המשתמש, שלא תוקנה בתוך 5 ימים ממועד ההודעה למשתמש בדבר ההפרה;`,
          },
          {
            p: `מניעה חוקית או רגולטורית להמשך מתן השירות, העולה על 30 ימים;
              `,
          },
          {
            p: `כניסה של החברה להליכי פירוק או כינוס או חדלות פירעון, שלא הסתיימו בתוך 30 ימים;
              `,
          },
          {
            p: `הפסקת מתן השירות על ידי החברה ו/או חיסול עסקיה.
              `,
          },
          {
            p: `6.2 החברה תאפשר ללקוח להוריד את התכנים הכלולים בעמוד ההנצחה בתוך 30 ימים ממועד סיום השירות. בתום תקופה זו, תהיה החברה רשאית למחוק את התכנים לצמיתות וללקוח לא תהיה כל טענה ו/או תביעה ו/או דרישה כלפי החברה בעניין זה.
  
              `,
          },
        ],
      },
    ],
  },
  {
    title: `7. מדיניות פרטיות`,
    sections: [
      {
        paragraphs: [
          {
            p: `למדיניות הפרטיות של האתר ניתן לגשת ל- https://www.lifecloud-qr.com/policy.`,
          },
        ],
      },
    ],
  },
  {
    title: `8. קניין רוחני של החברה
      `,
    sections: [
      {
        paragraphs: [
          {
            p: `8.1 מלוא זכויות הקניין הרוחני (מכל סוג שהן), לרבות סימני המסחר, הסודות המסחריים וזכויות היוצרים, בין אם רשומים ובין אם לאו, באתר ובכל חלק בו, לרבות (ומבלי לגרוע): תכנים, עיצוב, ובכל תוכנה, יישום, קוד מחשב, קובץ גרפי, טקסט וכל חומר אחר הכלול באתר, בין בממשק החיצוני, בין בקוד מקור ובין בקוד יעד - הוא של החברה בלבד ו/או של צדדים שלישיים, לפי העניין והם מוגנים על ידי חוקי זכויות היוצרים של מדינת ישראל, אמנות בינ"ל וחוקי זכויות יוצרים של מדינות אחרות, לפי העניין. `,
          },
          {
            p: `8.2
              החברה מעניקה למשתמש זכות שימוש מוגבלת ומותנית לעשות שימוש באתר, בתכנים וביישומים הכלולים בו בכפוף לתנאים הבאים: (א) זכות השימוש אינה בלעדית; (ב) זכות השימוש כפופה לקיום הוראות תנאי שימוש אלה וכן להוראות כל דין; (ג) זכות השימוש הנה אישית והיא אינה ניתנת להעברה, להמחאה או להסבה ולא ניתן לתת בה רישיונות משנה; (ד) זכות השימוש הדירה ומוגבלת בזמן והיא עשויה להיות מבוטלת על ידי החברה בכל עת ומכל סיבה שהיא ללא נימוק, על פי שיקול דעתה הסביר של החברה בנסיבות העניין. אין באמור כדי להוות ויתור מצד החברה על זכויות הקניין הרוחני שלה באתר ובנכסיה האחרים.
              `,
          },
          {
            p: `8.3
              נאסר על המשתמשים להכניס שינויים, להעתיק, לפרסם, להפיץ, לשדר, להציג, לבצע, לשכפל, להנפיק רישיון, ליצור עבודות נגזרות, לבצע הנדסת-היפוך (reverse engineering), או למכור באופן חלקי או מלא, זמני או קבוע, בכל צורה שהיא, כל חלק מן המידע והתכנים הכלולים באתר ללא הסכמה מראש ובכתב של החברה ו/או של אותם צדדים שלישיים (לפי העניין). כמו כן, אין לעשות שימוש באתר באופן המהווה או שעשוי להוות הפרה או פגיעה בקניין הרוחני של החברה או (לפי העניין) של אותם צדדים שלישיים, ללא הסכמתה המפורשת של החברה לכך בכתב ומראש.            `,
          },
          {
            p: `8.4
              סימני המסחר באתר הם קניינה של החברה או, במידה שפורסמו מטעמם של מפרסמים או שותפים עסקיים - קניינם של אלו בלבד. אין לעשות בהם שימוש בלא הסכמתם של החברה או (לפי העניין) אותם צדדים שלישיים כאמור לעיל, בכתב ומראש. המשתמש מתחייב שלא להסיר או להפריד מתכני האתר כל הגבלות וסימנים המציינים זכויות קנייניות של החברה או צדדים שלישיים, לרבות כל ההודעות הקנייניות המופיעות בהם (כגון ©, TM או ®).
              `,
          },
          {
            p: `8.5
              המשתמש מתחייב שלא לעשות שינויים ו/או להתערב בצורה כלשהי בקוד המקור של האתר ו/או המידע ו/או התכנים הכלולים בו ולא להעלות תוכנות ו/או יישומים כלשהם שעלולים לפגוע או לגרום נזק לאתר ו/או לחברה ו/או לצדדים שלישיים אחרים כלשהם.             `,
          },
          {
            p: `8.6
              האתר עשוי להשתמש ברכיבי צד שלישי הכפופים לרישיונות של צדדים שלישיים (להלן: "רכיבי צד שלישי"), בהם המשתמש רשאי להשתמש אך ורק בכפוף לעמידתו בתנאי הרישיון החלים על רכיבים אלו. במקרה של סתירה בין תנאי הרישיון של רכיב צד שלישי ותנאי שימוש אלה, תנאי הרישיון של רכיב הצד השלישי הרלוונטי יגברו, וזאת אך ורק בקשר עם רכיבי הצד השלישי. תנאי שימוש אלה אינם חלים על כל רכיבי הצד השלישי הנלווים ו/או הכלולים באתר, והחברה מסירה כל אחריות הקשורה בכך. החברה אינה היוצר, הבעלים או נותן הרישיון של רכיבי צד שלישי, והיא אינה מעניקה כל מצג או התחייבות מכל סוג שהוא, באופן מפורש או מכללא, בדבר איכות, יכולות, תפעול, ביצוע או התאמתו של רכיב צד שלישי זה או אחר. אין לראות באתר או בכל חלק ממנו כתוכנת "קוד פתוח".
            `,
          },
        ],
      },
    ],
  },
  {
    title: `9. העלאת תכנים על ידי משתמשים וכללי התנהגות בקבוצה
      `,
    sections: [
      {
        paragraphs: [
          {
            p: `9.1 
              המשתמש נותן את הסכמתו לכך כי במסירת פרטים ו/או העלאת תכנים לאתר, הוא מקנה לחברה רישיון חינם שאינו מוגבל בזמן עם הזכות ליתן רישיונות משנה, להציג, לשכפל, להעתיק, להפיץ, לשווק, לשדר, לערוך, לתרגם, לעצב מחדש ולעשות בתכנים כל שימוש אחר לפי שיקול דעתה.
              `,
          },
          {
            p: `9.2
              אין להעלות או לפרסם באתר כל מידע, קובץ, תמונה, תצלום, פרטים או כל תוכן אחר, לרבות תגובות לתכנים של אחרים (להלן בסעיף זה: "תכנים") שהינם בלתי חוקיים, בעלי אופי מיני בוטה, טורדניים, מעליבים, עוינים, מזיקים, מאיימים, גסים, גזעניים, פוגעים ברגשות הציבור, מעודדים ביצוע עבירה פלילית, עלולים להוות בסיס לתביעה או אחריות אזרחית, מוציאים לשון הרע, פוגעים בפרטיות, תכנים הכוללים יישומים מזיקים או תוכנות עוינות, או תכנים המהווים הפרה אחרת של הדין.            `,
          },
          {
            p: `9.3 
              במסירת תוכן כלשהו לפרסום באתר, המשתמש מאשר כי הנו בעל הזכויות בתוכן כאמור, ו/או כי הנו בר-רשות להתרת פרסומו באתר. העלאת תכנים אשר אינם פרי יצירתו המקורית של המשתמש (או העלאתם ללא קבלת אישור מבעל היצירה) מהווה הפרת זכויות יוצרים, דבר המהווה עוולה אזרחית ועבירה פלילית.
              `,
          },
          {
            p: `9.4 
              אין בפרסום או אישור לפרסום תכנים של משתמש באתר כדי להעיד על אישורה או הסכמתה של החברה לתכנים אלה והחברה אינה אחראית לנזקים שייגרמו לכל אדם בגין פרסום התכנים כאמור. תכנים המועלים על ידי המשתמש אינם מבטאים את דעת החברה או עמדתה ואין בפרסומם כל ערובה מצד החברה לתקפותם, אמינותם, דיוקם או חוקיותם.            `,
          },
          {
            p: `9.5 
              המשתמש הינו האחראי הבלעדי לטיבו ולדיוקו של התוכן המועלה על-ידו לאתר. מובהר כי התכנים המועלים לאתר אינם דורשים הסכמה מראש של החברה והחברה אף אינה מנטרת או עוקבת אחר פרסום תכנים כאמור ולמשתמש או לכל אדם לא תהיה כל טענה ו/או תביעה ו/או דרישה כלפי החברה בקשר עם כך. 
              `,
          },
          {
            p: `9.6 
              החברה רשאית לסרב לפרסם, או למחוק לאלתר בכל עת כל תוכן שמסר משתמש לפרסום, אם הפרה המשתמש את תנאי השימוש או שעשה מעשה או מחדל הפוגע או עלול לפגוע באתר, במשתמשים אחרים, בחברה או במי מטעמה או בכל גורם אחר. במקרה זה, החברה תהיה רשאית גם למנוע מהמשתמש לפרסם תכנים נוספים באתר. הוראות סעיף זה מוסיפות על זכויות החברה לפי כל דין.            `,
          },
          {
            p: `9.7 
              התכנים המועלים על ידי המשתמש לפרסום באתר יהיו חשופים לציבור. החברה אינה מתחייבת, והיא אינה יכולה לדעת אילו תגובות (אם בכלל) יקבל הפרסום של התכנים, ומי יגיב לתכנים שיפורסמו וכיצד. החברה לא תישא כלפי המשתמש או כלפי כל אדם באחריות לשימוש שייעשה בתכנים שהמשתמש העלה לפרסום או לתגובות שיתפרסמו או שיישלחו למשתמש, או לכל תוצאה שתנבע מפרסום התכנים באתר.            `,
          },
          {
            p: `9.8 
              ידוע למשתמש כי למנהל העמוד סמכות להסרת תכנים לפי שיקול דעתו הבלעדי וכל תלונה ו/או תביעה ו/או דרישה בקשר עם הסרת תוכן כלשהו על ידי מנהל העמוד, יש להפנות אל מנהל עמוד ולחברה לא תהיה כל אחריות בעניין זה.            `,
          },
          {
            p: `9.9 
              היה ואדם כלשהו סבור שתוכן מסוים המופיע באתר ו/או בעמוד הנצחה מפר את תנאי השימוש ו/או הוראות הדין, הוא רשאי ליצור קשר עם החברה בכתובת: office@lifecloud-qr.com, עם הפנייה לתוכן המפר (לרבות צילום מסך), פירוט לגבי ההפרה, שמו ופרטי התקשרות. היה והחברה תמצא כי התוכן אכן מפר, הוא יוסר בהקדם האפשרי. החברה אינה אחראית לתכנים מפרים או הסרתם מהאתר.
            `,
          },
          {
            p: `9.10 
              כחלק מקהילה סגורה, על כל משתמש להקפיד על כללי התנהגות בקרב חברי הקבוצה בעמוד ההנצחה אליה הוא משתייך. על המשתמש להקפיד, בין היתר, להתבטא בצורה ראויה, לשמור על כבוד ופרטיות הנפטר ועל כבוד קרוביו וחברי הקבוצה, לשמור על רוח טובה ויחסי קהילה נאותים, להקפיד על יושר והגינות ולדווח על תוכן מפר עם היוודע לו על כך. 
            `,
          },
        ],
      },
    ],
  },
  {
    title: `10. הגבלת אחריות 
      `,
    sections: [
      {
        paragraphs: [
          {
            p: `10.1
              מבלי לגרוע מהאמור בתנאי שימוש אלו, בכל מקרה ובשום מצב החברה ו/או מי מטעמה לא יישאו בחבות או באחריות כלשהי לכל אחד מאלה: 
              `,
          },
          {
            p: `10.2
              לכל הוצאה, אובדן או נזק עקיפים, תוצאתיים או נסיבתיים (לרבות הפסד כלכלי, אבדן רווחים, אבדן הזדמנות עסקית, אבדן מוניטין, ירידת ערך וכיו"ב), אשר יגרמו למשתמש או לצד שלישי כלשהו בקשר עם השימוש באתר ו/או מעשה או מחדל של החברה ו/או מי מטעמה;
            `,
          },
          {
            p: `10.3
              לכל תקלה ו/או איחור ו/או שיבוש בשימוש באתר (לרבות ומבלי לפגוע בכלליות האמור לעיל, בקווי בזק ו/או במערכות האינטרנט ו/או ברשתות הסלולר) וכן לכל הוצאה, אובדן או נזק אשר יגרמו מכל סיבה שהיא, שאינה תלויה בהחברה, לרבות ומבלי לגרוע, כתוצאה מצו ו/או תקנה ו/או הוראה של רשות ממשלתית, שיתוק מהותי של מערכות התקשורת, רעידת אדמה, סופה, מחסור בחומרים ו/או בשירותים ציבוריים ו/או בשירותי הובלה, אש, שיטפון, פיצוץ, התפוצצות, תאונה, מגיפה, שביתה, השבתה, מהומות, הפרת הסדר הציבורי, מלחמה, פעולת טרור ו/או איבה וסגר ואלו לא יחשבו כהפרה של תנאי השימוש ולא יזכו את המשתמש בכל סעד ו/או זכות. למען הסר ספק ומבלי לגרוע מכלליות האמור לעיל, יובהר כי לא תהא למשתמש כל טענה או זכות כלשהי בקשר לפעולות בהן תנקוט החברה עקב תקלה ו/או שיבוש כאמור;             `,
          },
          {
            p: `10.4
              לכל הוצאה, אובדן או נזק שיגרמו בקשר עם מעשים או מחדלים של המשתמש ו/או צדדים שלישיים, לרבות, ומבלי לגרוע, ספקי תקשורת (לרבות בקשר לתקלה ו/או לשיבוש, זמני או קבוע, בקווי התקשורת). 
            `,
          },
          {
            p: `10.5
              לכל הוצאה, אובדן או נזק שיגרמו בקשר עם גישה בלתי מורשית, התקפות, פריצה וחדירה למידע (או ניסיון לבצע כל אחד מאלה) באמצעות קווי תקשורת או רשת תקשורת אחרת.             `,
          },
          {
            p: `10.6
              לכל הוצאה, אובדן או נזק שייגרמו למשתמש כתוצאה מעדכון ו/או ביטול ו/או הסרה של תכנים מהאתר ו/או הורדת האתר (או חסימת הגישה אליו) לצמיתות או לפרקי זמן סבירים ומקובלים לצרכי תחזוקה ו/או שדרוג.
            `,
          },
          {
            p: `10.7
              השימוש הרציף באתר תלוי, בין השאר, בזמינותן, תקינותן ושרידותן של רשת האינטרנט ו/או (לפי העניין) רשת התקשורת הסלולארית, אשר כידוע, בחלק מן הזמנים ו/או האזורים במדינת ישראל אינה מלאה ורציפה. החברה ו/או מי מטעמה, לא יישאו באחריות לכל הפסד, הוצאה, אובדן או נזק, לרבות נזק מיוחד, תוצאתי או עקיף, שנגרם בקשר לפעילות או אי פעילות של האתר, ובכלל זה (ומבלי לגרוע) בקשר לטעות, תקלה או כשל טכני או אחר בצפייה במידע. 
            `,
          },
        ],
      },
    ],
  },
  {
    title: `11. שונות
      `,
    sections: [
      {
        paragraphs: [
          {
            p: `11.1
              החברה שומרת לעצמה את הזכות להוסיף, לשנות, לגרוע ולהחליף בכל עת את הוראות תנאי שימוש אלה, כולן או חלקן, לפי שיקול דעתה, וללא קבלת הסכמת המשתמשים או יידועם מראש, למעט במקרה של יידוע המשתמשים בעת עריכת שינויים מהותיים. שינויים מהותיים ייכנסו לתוקפם בתום שלושים (30) ימים ממתן ההודעה עליהם, למעט אם השינוי נובע מדרישה חוקית או רגולטורית או אז ייכנס השינוי לתוקפו על פי אותה דרישה. נוסחם המחייב של תנאי השימוש הוא זו שיופיע מעת לעת באתר. באחריות המשתמש להתעדכן בשינויים שחלו, אם חלו, בתנאי השימוש. המשך השימוש באתר ובשירותים המוצעים בו לאחר ביצוע שינוי כאמור יהווה ראיה לאישור המשתמש בדבר קבלת השינויים. מומלץ לעיין בהוראות תנאי השימוש בכל כניסה מחודשת לאתר על מנת לעיין בשינויים שחלו (אם חלו) בתנאי השימוש.            `,
          },
          {
            p: `11.2
              כל מחלוקות, טענות ותביעות בקשר לאתר לרבות, אך לא רק, בקשר עם המידע והתכנים המצויים בו, ידונו על פי דיני מדינת ישראל. מקום השיפוט הבלעדי לכל עניין הנוגע לתנאי השימוש ולשימוש באתר הינו בבתי המשפט המוסמכים בעיר תל-אביב יפו והצדדים שוללים במפורש סמכות של כל בית משפט אחר לעניין זה.           `,
          },
          {
            p: `11.3
              במידה והוראה מהוראות תנאי השימוש, תקבע על ידי בית המשפט כבלתי חוקית ו/או בלתי תקפה, אזי לא יבטל הדבר את יתר הוראות תנאי השימוש ו/או את יתר חלקי אותה הוראה שבוטלה ו/או צומצמה על ידי בית המשפט, אשר לא בוטלו או צומצמו.
          `,
          },
          {
            p: `11.4
              כל שיהוי של החברה בקיום זכות המגיעה לה או אי עמידה שלה על זכות המגיעה לה לפי תנאי השימוש או על פי דין, הינה לפנים משורת הדין בלבד ולא תשמש או תיחשב כוויתור של החברה על זכות כאמור.
              `,
          },
          {
            p: `11.5
              תנאי שימוש אלה אינם יוצרים ולא יתפרשו כאילו הם יוצרים יחסי שותפות, מיזם משותף, יחסי עובד-מעסיק, יחסי שליחות או יחסי נותן-מקבל זיכיון בין הצדדים.
            `,
          },
          {
            p: `11.6
              כל תיקון לתנאי שימוש אלה לא יהיה בעל תוקף משפטי מחייב אלא אם כן נעשה בכתב על ידי החברה.          `,
          },
          {
            p: `תאריך עדכון אחרון: [5/7/22]
              `,
          },
        ],
      },
    ],
  },
];
export default Regulations;

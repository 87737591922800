import React, { useEffect } from 'react';
import MainLayout from '../../components/layout/MainLayout';
/* import ProfileSettings from '../../components/ProfileSettings/ProfileSettings'; */
import ProfileUpgrade from '../../components/ProfileUpgrade/ProfileUpgrade';
import SocialFooter from '../../components/SocialFooter/SocialFooter';
import AllProfiles from '../../components/AllProfiles/AllProfiles';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { getUserProfiles } from '../../features/profile/profileSlice';
import { Helmet } from 'react-helmet-async';

const UserProfile = () => {
  const { profileLoading, profiles } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserProfiles());
  }, [dispatch]);
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | בית חדש לחיים שאחרי</title>
        <meta
          property="og:url"
          content="https://lifecloud-qr.com/user-profile"
        />
      </Helmet>
      <MainLayout bg="#fff">
        <ProfileUpgrade />
        {profileLoading ? (
          <Loader section={true} />
        ) : (
          <AllProfiles profiles={profiles} />
        )}
        <SocialFooter cloudPos="down-left" />
      </MainLayout>
    </React.Fragment>
  );
};

export default UserProfile;

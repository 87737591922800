import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Button.module.css';

const Button = React.forwardRef(
  ({ children, className, outline, loading, disabled, to, ...rest }, ref) => {
    return (
      <>
        {to ? (
          <Link
            to={to}
            className={`${className} btn ${classes.button} ${
              outline ? classes.button_outline : classes.button_contained
            }`}
            {...rest}
            disabled={disabled || loading}
            ref={ref}
          >
            {loading && (
              <React.Fragment>
                <span
                  className={`spinner-border spinner-border-lg me-3 ${classes.button_spinner}`}
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Loading...</span>
              </React.Fragment>
            )}
            {children}
          </Link>
        ) : (
          <button
            className={`${className} btn ${classes.button} ${
              outline ? classes.button_outline : classes.button_contained
            }`}
            {...rest}
            disabled={disabled || loading}
            ref={ref}
          >
            {loading && (
              <React.Fragment>
                <span
                  className={`spinner-border spinner-border-lg me-3 ${classes.button_spinner}`}
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Loading...</span>
              </React.Fragment>
            )}
            {children}
          </button>
        )}
      </>
    );
  }
);

export default Button;

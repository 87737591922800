import React from 'react';
import classes from './Section.module.css';

import upCloudLeft from '../../assets/error-page-img/upcloud-left.png';
import upCloudRight from '../../assets/error-page-img/upcloud-right.png';
import downCloudLeft from '../../assets/error-page-img/downcloud-left.png';
import downCloudRight from '../../assets/error-page-img/downcloud-right.png';

import upColorCloudLeft from '../../assets/error-page-img/blue-upcloud-left.png';
import upColorCloudRight from '../../assets/error-page-img/blue-upcloud-right.png';
import downColorCloudLeft from '../../assets/error-page-img/blue-downcloud-left.png';
import downColorCloudRight from '../../assets/error-page-img/blue-downcloud-right.png';

const Section = ({
  children,
  className,
  gap = true,
  padding = false,
  bg = '#fff',
  showCloud = true,
  cloudPos = 'left-right',
  colorful,
  cloudZIndex = 0,
  ...rest
}) => {
  const [firstPos, secondPos] = cloudPos.split('-');
  let firstCloud;
  let secondCloud;

  if (cloudPos === 'up-left') {
    firstCloud = colorful ? upColorCloudLeft : upCloudLeft;
  } else if (cloudPos === 'up-right') {
    firstCloud = colorful ? upColorCloudRight : upCloudRight;
  } else if (cloudPos === 'down-left') {
    secondCloud = colorful ? downColorCloudLeft : downCloudLeft;
  } else if (cloudPos === 'down-right') {
    secondCloud = colorful ? downColorCloudRight : downCloudRight;
  } else if (cloudPos === 'left-right') {
    firstCloud = colorful ? upColorCloudLeft : upCloudLeft;
    secondCloud = colorful ? downColorCloudRight : downCloudRight;
  } else if (cloudPos === 'right-left') {
    firstCloud = colorful ? upColorCloudRight : upCloudRight;
    secondCloud = colorful ? downColorCloudLeft : downCloudLeft;
  }
  return (
    <section
      className={`${classes.section} ${className} 
      ${gap && classes.section_gap}
      ${padding && classes.section_padding}
      `}
      style={{ backgroundColor: bg }}
      {...rest}
    >
      {showCloud && firstCloud && (
        <img
          src={firstCloud}
          alt="cloud"
          className={`${classes.cloudImg} ${classes.cloudImgUp} ${
            firstPos === 'up' ? classes[secondPos] : classes[firstPos]
          }`}
          style={{ zIndex: cloudZIndex }}
        />
      )}
      {children}
      {showCloud && secondCloud && (
        <img
          src={secondCloud}
          alt="cloud"
          className={`${classes.cloudImg} ${classes.cloudImgDown} ${classes[secondPos]}`}
          style={{ zIndex: cloudZIndex }}
        />
      )}
    </section>
  );
};

export default Section;

import React, { useEffect } from 'react';
import MemoryListDesktop from './MemoryListDesktop/MemoryListDesktop';
import MemoryListMobile from './MemoryListMobile/MemoryListMobile';

const MemoryList = ({ memories, profileId, isOwnerOrAdmin, isFriend }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakPoint = 768;

  // useEffect(() => {
  //   const handleWindowResize = () => setWidth(window.innerWidth);
  //   window.addEventListener('resize', handleWindowResize);

  //   return () => window.removeEventListener('resize', handleWindowResize);
  // }, []);
  return (
    <React.Fragment>
      {/* {width > breakPoint ? (
        <MemoryListDesktop
          memories={memories}
          profileId={profileId}
          isOwnerOrAdmin={isOwnerOrAdmin}
          isFriend={isFriend}
        />
      ) : (
        <MemoryListMobile
          memories={memories}
          profileId={profileId}
          isOwnerOrAdmin={isOwnerOrAdmin}
        />
      )} */}
      <MemoryListDesktop
        memories={memories}
        profileId={profileId}
        isOwnerOrAdmin={isOwnerOrAdmin}
        isFriend={isFriend}
      />
    </React.Fragment>
  );
};

export default MemoryList;

import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '../features/modal/modalSlice';
import { ForgotPasswordBody } from '../pages/ForgotPassword/ForgotPassword';
import classes from './ForgotPasswordModal.module.css';
import cross from '../assets/candleflower-modal-img/x.png';
const ForgotPasswordModal = () => {
  const { forgotPasswordModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  return (
    <Modal
      show={forgotPasswordModal}
      onHide={() => dispatch(hideModal('forgotPasswordModal'))}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="auth-modal"
    >
      <Modal.Header style={{ border: 0, background: '#1A4E74' }}>
        <h5 className={`${classes.modal_title} m-auto`} dir="rtl">
          שכחת את הסיסמא?
        </h5>
        <button
          type="button"
          className={`${classes.btn_close}  btn align-self-start`}
          onClick={() => dispatch(hideModal('forgotPasswordModal'))}
        >
          <img src={cross} alt="Close" />
        </button>
      </Modal.Header>
      <Modal.Body className={classes.modal_body}>
        <ForgotPasswordBody modal />
      </Modal.Body>
    </Modal>
  );
};
export default ForgotPasswordModal;

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '../features/modal/modalSlice';
import { showModal } from '../features/modal/modalSlice';
import classes from './GuestCFPurchaseModal.module.css';
import Button from '../components/UI/Button/Button';
import SocialLogin from '../components/SocialLogin/SocialLogin';
import lifeAxios from '../utils/lifeAxios';

import cross from '../assets/candleflower-modal-img/x.png';
import profileImageDemo from '../assets/candleflower-modal-img/candle.png';
import google from '../assets/candleflower-modal-img/googleLogo.png';
import facebook from '../assets/candleflower-modal-img/facebookLogo.png';
import gmail from '../assets/candleflower-modal-img/gmailLogo.png';
import prfilepic_a from '../assets/candleflower-modal-img/profilesamps/prfilepic_a.jpeg';
import prfilepic_b from '../assets/candleflower-modal-img/profilesamps/prfilepic_b.jpeg';
import prfilepic_c from '../assets/candleflower-modal-img/profilesamps/prfilepic_c.jpeg';
import prfilepic_z from '../assets/candleflower-modal-img/profilesamps/prfilepic_z.jpeg';
import { createFundedCandleFlower, getCandlesFlowers } from '../features/candleFlower/candleFlowerSlice';


const GuestCFPurchaseModal = ({ candleFlowerState, dedication, hideCFModal }) =>
{
  const { guestCFPurchaseModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleNameChange = (event) =>
  {
    setName(event.target.value);
  };

  const handleEmailChange = (event) =>
  {
    setEmail(event.target.value);
  };
  const handleFormSubmit = async (event) =>
  {
    event.preventDefault();

    if (profile.funded) {
      // if (!user._id) return dispatch(showModal('loginModal'));
      const funder = profile.funder;
      // console.log(funder, candleFlowerState.filter((cf) => cf.count > 0));
      const formData = {
        user: "guest",
        profile: profile._id,
        candlesFlowers: candleFlowerState,
        dedication: dedication,
        guestName: name,
        guestEmail: email,
      };
      const creationResponse = await dispatch(createFundedCandleFlower(formData));

      if (creationResponse.meta.requestStatus === "fulfilled");
      {
        dispatch(getCandlesFlowers(profile._id));
        dispatch(hideModal('guestCFPurchaseModal'));
        hideCFModal();
        document.getElementById('virtual-memory').scrollIntoView();
      }
      return;
    }

    const totalPrice = candleFlowerState.reduce(
      (acc, item) => acc + item.count * 5,
      0
    );
    localStorage.setItem('profileId', profile._id);

    const selectedCFs = candleFlowerState.filter((cf) => cf.count > 0);
    const queryString = encodeURIComponent(
      JSON.stringify(selectedCFs)
    );

    const objectId = (await lifeAxios(localStorage.getItem('jwt')).post(
      `/purchases/create`,
      {
        candlesFlowers: selectedCFs,
        profile: profile._id,
        user: "guest",
        contact: name,
        email: email,
        sum: totalPrice,
        dedication: dedication
      }
    )).data.data.data;

    window.location.assign(
      `https://direct.tranzila.com/icloud/iframenew.php?supplier=icloud&template=custom&u71=1&candlesFlowers=${queryString}&sum=${totalPrice}&currency=1&contact=${name
      }&email=${email}&user=${"guest"
      }&tranmode=A&pdesc=CandleOrFlower&type_code=320&tamal_lang=il&profile=${profile._id
      }&doc_remark=docremark&dedication=${dedication}&purchase=${objectId}&ppnewwin=0`
    );



  };

  return (
    <React.Fragment>
      <Modal
        show={guestCFPurchaseModal}
        onHide={() => dispatch(hideModal('guestCFPurchaseModal'))}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        contentClassName={classes.modal_content}
        className={` ${classes.guestCFModal}`}
        centered
      >

        <div className={`modal-dialog modal-dialog-centered modal-md  ${classes.modal_dialog}`} style={{ margin: 0 }}>
          <div className={`auth-modal modal-content text-center ${classes.modal_content}`}>

            <div className={`modal-header justify-content-between ${classes.modal_header}`}   >

              <button
                type="button"
                className={`${classes.btn_close}  btn align-self-start`}
                // data-bs-dismiss="modal"
                // onClick={()=>setShowCFOptionsModal(false)}
                aria-label="Close"
              >
                <img src={cross} alt="Close" />
              </button>
              <div className="m-auto">
                <h5
                  className={`${classes.header_title} m-auto`} >
                  תודה שבחרתם לחלוק כבוד ל
                  <b>{profile.firstName}</b>
                </h5>
              </div>


            </div>


            {/*  <Modal.Header closeButton  className={` ${classes.modal_header}` } >
           תודה שבחרתם לחלוק כבוד לשם פרטי 
          </Modal.Header>   */ }

            <div className={`modal-body ${classes.modal_body}`}>
              <div className="container">
                <div className={`row ${classes.rowGuest} `}>
                  <span>
                    בתור אורח/ת, יש למלא את שמך וכתובת מייל
                  </span>

                  <form className={`row g-4 ${classes.guestForm} `} onSubmit={(e) =>
                  {
                    dispatch({ type: 'RESET' });
                    handleFormSubmit(e);
                  }}
                  >
                    <div className={classes.dedicated_texts}>
                      <input
                        type="text"
                        placeholder="שם מלא*"
                        className="form-control"
                        // onChange={(e) => setName(e.target.value)}
                        onChange={handleNameChange}
                        value={name}
                        required
                      />
                      <br />
                      <input
                        type="email"
                        placeholder="כתובת מייל*"
                        className="form-control"
                        // onChange={(e) => setEmail(e.target.value)}
                        onChange={handleEmailChange}
                        value={email}
                        required
                      // disabled="disabled" 

                      />
                    </div>
                    <div className="col-md-12">
                      <Button
                        className={`w-100 ${classes.submit_btn}`}
                      >
                      {profile.funded?"להמשך הנחת פרח/נר":"המשך לתשלום"}
                      </Button>
                    </div>
                  </form>


                </div>
                <div className={`row ${classes.rowGuest} `}>
                  <span className={` ${classes.txt_title}`}   >
                    הצטרפו ל{profile.profileFriends.length == 0 ? "" : "-" + profile.profileFriends.length + " "}חברים שכבר בקהילה לזכר
                    <span className={` ${classes.txt_title}`}   > {profile.firstName} </span>
                  </span>
                  <div className={` ${classes.profilesContain} `} >
                    <div className={` ${classes.profileImage} `} >
                      <img src={prfilepic_a} />
                    </div>
                    <div className={` ${classes.profileImage} `} >
                      <img src={prfilepic_b} />
                    </div>
                    <div className={` ${classes.profileImage} `} >
                      <img src={prfilepic_c} />
                    </div>
                    <div className={` ${classes.profileImage} `} >
                      <img src={prfilepic_z} />
                    </div>
                    <div className={` ${classes.profileImageEmpty}  ${classes.profileImage} `} >
                      <span>
                        ?אתם
                      </span>
                    </div>
                  </div>
                  <div className={`row ${classes.likeBtn} `}>
                    <span>
                      התחברות עם חשבון:
                    </span>
                    <SocialLogin bigSizedDesign={false} />
                    |
                    <div className={` ${classes.socialIcons} `} onClick={() => dispatch(showModal('registrationModal'))}>
                      <img src={gmail} />
                    </div>
                  </div>

                  {/*
             <div className={`row ${classes.likeBtn} `}>
               <span>
               התחברות עם חשבון:
               </span>
                 <div className={` ${classes.socialIcons} `} onClick={()=>console.log("Google button pressed")}>
                   <img src={google} />
                 </div>
                 |
                  <div className={` ${classes.socialIcons} `} onClick={()=>console.log("Facebook button pressed")}>
                   <img src={facebook} />
                 </div>
                 |
                  <div className={` ${classes.socialIcons} `} onClick={()=>console.log("Gmain button pressed")}>
                   <img src={gmail} />
                 </div>
              </div>
              */}
                </div>
                <div className={` ${classes.linkLogin} `} >
                  <span onClick={() => dispatch(showModal('loginModal'))} >
                    !יש לכם כבר משתמש? לחצו כאן
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>



  );
};

export default GuestCFPurchaseModal;

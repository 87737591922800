
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import cross from '../assets/candleflower-modal-img/x.png';
import closeModal from '../assets/qr-remake/modal-close.svg';
import qrImage from '../assets/qr-popup/qrImage.png';
import qrMonument from '../assets/qr-popup/qrMonument.png';
import classes from './QrModal.module.css';
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal, showModal } from '../features/modal/modalSlice';
import toastCreator from '../utils/toastifyCreator';
import FsLightbox from 'fslightbox-react-premium';
import { qrPopup } from '../features/profile/profileSlice';
import Button from '../components/UI/Button/Button';
import { useForm } from 'react-hook-form';
import AddressFormModal from './AddressFormModal';
import lifeAxios from '../utils/lifeAxios';
import Image119Big from '../assets/qr-remake/modal-3-qr-code-119-lg.png';
import Image189Big from '../assets/qr-remake/modal-3-qr-image-189-lg.png';
import Image119Small from '../assets/qr-remake/mobile-qr-2-119.png';
import Image189Small from '../assets/qr-remake/mobile-qr-1.png';


const QrModalTest = ({ profileId }) => {
  const { register, handleSubmit } = useForm();
  const [type, setType] = useState('');
  const [qrText, setQrText] = useState('');
  const [qrImg, setQrImg] = useState('');
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.profile);
  const { qrFirstModal, qrSecondModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [width, setWidth] = React.useState(window.innerWidth);
  const normalQR=140;
  const picQR=220;
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  
  const qrStandImg =
    width > 768
      ? [
          'https://res.cloudinary.com/lifecloud-qr/image/upload/v1673358141/Frame_9_bhlhz3.png',
          'https://res.cloudinary.com/lifecloud-qr/image/upload/v1673358150/Frame_9_1_kwnmn6.png',
        ]
      : [
          'https://res.cloudinary.com/lifecloud-qr/image/upload/v1673358145/Frame_9_8_xn7vyf.png',
          'https://res.cloudinary.com/lifecloud-qr/image/upload/v1673358145/Frame_9_7_k0iq58.png',
        ];
  const qrStatusImg =
    width > 768
      ? [
        // 119
          Image119Big,
          // 'https://res.cloudinary.com/lifecloud-qr/image/upload/v1673358141/Frame_9_4_f1ztou.png'
          // 'https://res.cloudinary.com/lifecloud-qr/image/upload/v1673358149/Frame_9_3_ieif7g.png',
        ]
      : [
          Image119Small,
        //   'https://res.cloudinary.com/lifecloud-qr/image/upload/v1673358145/Frame_9_6_v4oume.png',
          // 'https://res.cloudinary.com/lifecloud-qr/image/upload/v1673358149/Frame_9_9_oslw3b.png',
        ];

  const freeQrImg =
    width > 768
      ? [
          'https://res.cloudinary.com/lifecloud-qr/image/upload/v1673358149/Frame_9_2_hf0xc5.png',
        ]
      : [
          'https://res.cloudinary.com/lifecloud-qr/image/upload/v1673358148/Frame_9_5_gn2knj.png',
        ];
  const [index, setIndex] = useState(0);
  const [toggler, setToggler] = useState({
    qrStand: false,
    qrStatus: false,
    freeQr: false,
  });

  const handleQrImgClick = (type, index) => {
    setIndex(index);
    setToggler({ ...toggler, [type]: !toggler[type] });
  };

  const onChangeQrImg = (event) => {
    if (event.target.files[0]) {
      setQrImg(event.target.files[0]);
    }
  };

  const onSubmit = async (data, type) => {
    dispatch(hideModal('addressModal'));
    dispatch(hideModal('qrFirstModal'));
    dispatch(hideModal('qrSecondModal'));

    const formData = new FormData();
    if (type === 'sendToEden') {
      if (!qrImg) return toastCreator('error', 'יש להעלות תמונה');
      formData.append('sendToEden', true);
      formData.append('qrText', qrText);
      formData.append('qrImg', qrImg);
      formData.append('fullName', data.fullName);
      formData.append('address', data.address);
      formData.append('phone2', data.phone2);
    }
    if (type === 'sendProfileToEmail') {
      formData.append('sendProfileToEmail', true);
      formData.append('fullName', data.fullName);
      formData.append('address', data.address);
      formData.append('phone2', data.phone2);
    }
    if (type === 'sendQrToEmail') {
      formData.append('sendQrToEmail', true);
    }

    formData.append('profileId', profileId);
    formData.append('originalUser', user._id);

    await dispatch(qrPopup(formData));
    localStorage.setItem('profileId', profileId);
    if (type === 'sendProfileToEmail') {
      const qrMode = "qrSend";
      const objectId = (await lifeAxios(localStorage.getItem('jwt')).post(
        `/purchases/create`,
        {
          profile: profileId,
          user: user._id,
          contact: user.organizationName || user.firstName,
          email: user.email,
          sum: normalQR,
          qrType: qrMode
        }
      )).data.data.data;
      
      window.location.assign(
        `https://direct.tranzila.com/icloud/iframenew.php?supplier=icloud&template=custom&u71=1&qrType=${qrMode}&sum=${normalQR}&currency=1&contact=${
          user.organizationName || user.firstName
        }&email=${user.email}&user=${
          user._id
        }&tranmode=A&pdesc=CandleOrFlower&type_code=320&tamal_lang=il&profile=${profileId}&doc_remark=docremark&purchase=${objectId}&ppnewwin=0`

      );
    }

    if (type === 'sendToEden') {
      const qrMode = "qrStatus";
      const objectId = (await lifeAxios(localStorage.getItem('jwt')).post(
        `/purchases/create`,
        {
          profile: profileId,
          user: user._id,
          contact: user.organizationName || user.firstName,
          email: user.email,
          sum: picQR,
          qrType: qrMode
        }
      )).data.data.data;
      
      window.location.assign(
        `https://direct.tranzila.com/icloud/iframenew.php?supplier=icloud&template=custom&u71=1&qrType=${qrMode}&sum=${picQR}&currency=1&contact=${
          user.organizationName || user.firstName
        }&email=${user.email}&user=${
          user._id
        }&tranmode=A&pdesc=CandleOrFlower&type_code=320&tamal_lang=il&profile=${profileId}&doc_remark=docremark&purchase=${objectId}&ppnewwin=0`

      );
    }
  };
  const handleSendToEden = () => {
    if (!qrImg) return toastCreator('error', 'יש להעלות תמונה');
    setType('sendToEden');
    dispatch(showModal('addressModal'));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal
        show={qrFirstModal}
        onHide={() => dispatch(hideModal('qrFirstModal'))}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`qr_modal`}
      >
        <Modal.Header className={classes.modal_header}>
          <h5
            className={`${classes.modal_title} m-auto`}
            id="candleFlowerLabel"
          >
            בחרו את קוד ה-QR שתרצו לקבל עם הפרופיל החדש!
          </h5>
          <button
            type="button"
            className={`${classes.btn_close} btn align-self-start`}
            onClick={() => dispatch(hideModal('qrFirstModal'))}
          >
            <img src={cross} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className="px-4 pt-5 pb-4">
          <div className="row gy-lg-0 gy-4">
            <div className="col-lg-4 order-lg-1 order-3">
              <div className={classes.profile_qr}>
                <Swiper
                  modules={[Pagination]}
                  className="qrSwiper"
                  pagination={{
                    clickable: true,
                  }}
                >
                  {qrStandImg.map((img, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={img}
                        alt="QR Stand"
                        onClick={() => handleQrImgClick('qrStand', index)}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div className="">
                  <h3 className="text-center">
                      לוחיות פרימיום |  <span>{picQR}₪</span>
                  </h3>
                  
                  <hr className="d-lg-none d-block" />
                   <ul className={`${classes.bulletins} ${classes.modal_card_text}`}>
                    <li>
                      <p>לוחית עם ברקוד בלבד ולוחית מעוצבת</p>
                    </li>
                    <li>
                      <p>עשוי מחומר עמיד לתנאי חוץ</p>
                    </li>
                    <li>
                      <p>לוחיות בגודל 14X9 ס”מ</p>
                    </li>
                  </ul>

                  <Button
                    className={classes.qr_button_sm}
                    onClick={() => dispatch(showModal('qrSecondModal'))}
                    loading={loading}
                  >
                    להמשך ורכישה
                  </Button>
                </div>
              </div>
            </div>
            
            
            
            <div className="col-lg-4 order-lg-2">
              <div className={`${classes.profile_qr}`}>
                <Swiper
                  modules={[Pagination]}
                  className="qrSwiper"
                  pagination={{
                    clickable: true,
                  }}
                >
                
                  {qrStatusImg.map((img, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={img}
                        alt="QR Status"
                        onClick={() => handleQrImgClick('qrStatus', index)}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div className="">
                  <h3 className="text-center">
                    <span>{normalQR}₪</span> | QR
                  </h3>

                  <ul className={classes.bulletins}>
                    <li>
                      <p>זוג לוחיות עם ברקוד ללא עיצוב</p>
                    </li>
                    <li>
                      <p>עשוי מחומר עמיד לתנאי חוץ</p>
                    </li>
                    <li>
                      <p>לוחית בגודל 8X5 ס”מ</p>
                    </li>
                  </ul>

                  <Button
                    className={classes.qr_button_sm}
                    onClick={() => {
                      setType('sendProfileToEmail');
                      dispatch(showModal('addressModal'));
                    }}
                    loading={loading}
                  >
                    לתשלום
                  </Button>
                </div>
              </div>
            </div>
            
            <div className="col-lg-4 order-lg-3 order-1 ">
              <div className={`${classes.profile_qr} h-100`}>
                <Swiper
                  modules={[Pagination]}
                  className="qrSwiper h-auto"
                  pagination={{
                    clickable: true,
                  }}
                >
                  {freeQrImg.map((img, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={img}
                        alt="free Qr"
                        onClick={() => handleQrImgClick('freeQr', index)}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div className="">
                  <h3 dir="rtl" className="text-center">
                    QR למייל | <span>ללא עלות</span>
                  </h3>
                  <ul className={classes.bulletins}>
                    <li>
                      <p>קבלת הברקוד למייל כקובץ תמונה</p>
                    </li>
                    <li>
                      <p>ניתן לשימוש אישי והדפסה</p>
                    </li>
                    <li>
                      <p>&nbsp;</p>
                    </li>
                  </ul>

                  <Button
                    className={`${classes.qr_button_sm}`}
                    onClick={(e) =>
                    handleSubmit((data) => onSubmit(data, 'sendQrToEmail'))(e)
                    }
                    // onSubmit={(data) =>onSubmit(data, 'sendQrToEmail')}
                    loading={loading}
                  >
                    !שליחה למייל חינם
                  </Button>
                </div>
              </div>
            </div>
          </div>
          
          
          
          
          <div className={classes.modal_footer}>
            <button
              className={classes.qr_button_lg}
              onClick={() => toastCreator('error', 'ש לבחור מוצר')}
            >
              לרכישת QR מעוצב
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={qrSecondModal}
        onHide={() => dispatch(hideModal('qrSecondModal'))}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="qr_modal"
      >
        <Modal.Header className={classes.modal_header}>
          <h5
            className={`${classes.modal_title} m-auto`}
            id="candleFlowerLabel"
          >
            בחירת QR לפרופיל החדש!
          </h5>
          <button
            type="button"
            className={`${classes.btn_close}  btn align-self-start`}
            onClick={() => dispatch(hideModal('qrSecondModal'))}
          >
            <img src={cross} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className="px-4 px-md-5 pt-3 pt-md-5">
          <div className={classes.profile_qr_selected}>
            <h3 dir="rtl">בחרו תמונה של יקריכם ומשפט אותם תרצו להציג על הQR</h3>
            <textarea
              placeholder="כתבו מספר מילים שיופיעו מתחת ל-QR ..."
              maxLength={35}
              dir="rtl"
              {...register('qrText', {
                onChange: (e) => setQrText(e.target.value),
              })}
            ></textarea>
            <span className={classes.reaming_char}>{qrText.length}/35</span>

            <div className="row">
              <div className="col-10 col-lg-6 mx-auto">
                <img
                  src={qrImg ? URL.createObjectURL(qrImg) : qrImage}
                  alt="QR"
                  className="my-5"
                />
                <label htmlFor="qrImg" className={classes.qr_button_sm}>
                  + העלאת תמונה
                  <input
                    type="file"
                    id="qrImg"
                    className="d-none"
                    accept="image/*"
                    {...register('qrImg', {
                      onChange: onChangeQrImg,
                    })}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className={classes.modal_footer}>
            <button
              className={`${classes.qr_button_lg} w-50 mx-auto`}
              onClick={handleSendToEden}
            >
              מעבר לתשלום
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <AddressFormModal
        register={register}
        type={type}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
      />
      <FsLightbox
        toggler={toggler.qrStand}
        sources={qrStandImg}
        sourceIndex={index}
        type="image"
      />
      <FsLightbox
        toggler={toggler.qrStatus}
        sources={qrStatusImg}
        sourceIndex={index}
        type="image"
      />
      <FsLightbox
        toggler={toggler.freeQr}
        sources={freeQrImg}
        sourceIndex={index}
        type="image"
      />
    </form>
  );
};

export default QrModalTest;
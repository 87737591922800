import { toast } from 'react-toastify';

//Toastify generator
const toastCreator = (status, message) => {
  toast[status](message, {
    position: 'bottom-center',
    autoClose: 3000,
    toastId: 'unique',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export default toastCreator;

import React from 'react';
import Section from '../layout/Section';
import Button from '../UI/Button/Button';
import classes from './OrganizationAllProfiles.module.css';
import { Link } from 'react-router-dom';

const OrganizationAllProfiles = ({ organizationProfile }) => {
  return (
    <Section className={classes.users_profile} showCloud={false}>
      <div className="container">
        <h2 className="profile_title">פרופיל עמותה</h2>
        <div className="row gx-sm-5 gy-5 justify-content-center">
          {organizationProfile ? (
            <div className="col-lg-3 col-md-4 col-6">
              <Link
                to={`/organization-profile/${
                  organizationProfile._id
                }/${organizationProfile.organizationName
                  ?.trim()
                  .split(' ')
                  .join('-')}`}
              >
                <div className={`${classes.users_profile_item} text-center`}>
                  <div className="ratio ratio-1x1">
                    <img
                      src={organizationProfile.profileImg}
                      alt="user"
                      className="img-fluid rounded-circle"
                    />
                  </div>
                  <h3>{organizationProfile.organizationName} </h3>
                  <Button>מנהלים -</Button>
                </div>
              </Link>
            </div>
          ) : (
            <div className="col-lg-3 col-md-4 col-6">
              <div
                className={`${classes.icon_container} ratio ratio-1x1 rounded-circle`}
              >
                <Link to="/organization-create-profile">
                  <div className="d-flex justify-content-center align-items-center w-100 h-100">
                    <div className={classes.plus}>
                      <div className={`${classes.line}`}></div>
                      <div className={`${classes.line}`}></div>
                    </div>
                  </div>
                </Link>
              </div>
              <h3>צור פרופיל חדש</h3>
            </div>
          )}
        </div>
        {organizationProfile && (
          <React.Fragment>
            <h2 className="profile_title">הפרופילים שלי</h2>
            <div className="row gx-sm-5 gy-5 justify-content-center">
              {organizationProfile.profiles?.map((profile) => (
                <div className="col-lg-3 col-md-4 col-6" key={profile._id}>
                  <Link to={`/profile/${profile._id}`}>
                    <div
                      className={`${classes.users_profile_item} text-center`}
                    >
                      <div className="ratio ratio-1x1">
                        <img
                          src={profile.profileImg}
                          alt="user"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <h3>
                        {profile.firstName} {profile.lastName}
                      </h3>
                      <Button>מנהלים -</Button>
                    </div>
                  </Link>
                </div>
              ))}
              <div className="col-lg-3 col-md-4 col-6">
                <div
                  className={`${classes.icon_container} ratio ratio-1x1 rounded-circle`}
                >
                  <Link
                    to={
                      organizationProfile
                        ? '/create-profile'
                        : '/organization-create-profile'
                    }
                  >
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                      <div className={classes.plus}>
                        <div className={`${classes.line}`}></div>
                        <div className={`${classes.line}`}></div>
                      </div>
                    </div>
                  </Link>
                </div>
                <h3>צור פרופיל חדש</h3>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </Section>
  );
};

export default OrganizationAllProfiles;

import React, { useEffect, useRef, useState } from 'react';
import classes from './Remember.module.css';
import Section from '../layout/Section';
import cover from '../../assets/home-img/cover.png';
const Remember = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const video = useRef(null);
  const section = useRef(null);
  useEffect(() => {
    const scrollEvent = window.addEventListener('scroll', async () => {
      if (window && window.innerWidth < 768) {
        return;
      }
      if (section && section.current) {
        const distance =
          window.innerHeight -
          section.current.getBoundingClientRect().top.toFixed();
        if (distance > 1100 || distance < 433) {
          video.current.pause();
        } else if (distance > 433) {
          video.current.play();
        }
      }
    });
    return () => window.removeEventListener('scroll', scrollEvent);
  }, []);

  return (
    <Section className={classes.remember} cloudPos="down-right">
      <div className={`text-center ${classes.remember_title}`}>
        {/* <h2>אדם הוא עולם ומלואו, סיפור חייו ראוי שיוזכר ויונצח לעד</h2> */}
        <h3>
          MOMENTS <span>&middot;</span> COMMUNITY <span>&middot;</span> LEGACY
        </h3>
      </div>
      <section className={classes.remember_up} ref={section}>
        <div className="container">
          <div className="row align-items-center gx-5">
            <div className={`col-lg-7 ${classes.video_container}`}>
              <div className={`${classes.video_wrapper}`}>
                <video
                  muted={true}
                  ref={video}
                  onPlay={() => setIsVideoPlaying(true)}
                  onPause={() => setIsVideoPlaying(false)}
                  controls
                  className={classes.video}
                  src="https://res.cloudinary.com/lifecloud-qr/video/upload/v1658269642/LIFECLOUD_FIX_SUBS_1_1_askdfkjasbhdffn_f_glhqbq.mp4"
                  width="100%"
                  height="auto"
                ></video>
                <img
                  className={` ${
                    isVideoPlaying ? classes.hide_cover : classes.show_cover
                  } ${classes.video_cover_img}`}
                  src={cover}
                  onClick={() => video.current.play()}
                  alt="cover"
                />
              </div>
            </div>
            <div className="col-lg-5 mt-md-5">
              <p className={classes.remember_text} dir="rtl">
                סיפור חייהם של יקירנו מורכבים מחלקים השלובים בחייהם של בני
                משפחתם, חברים ומכרים. עם לכתם מן העולם, סיפורם נעלם איתם.
              </p>
              <hr className="d-md-block d-none" />
            </div>
          </div>
        </div>
      </section>
      <section className={classes.remember_down}>
        <div className="container">
          <div className="row align-items-center gx-5">
            <div className="col-lg-9"></div>
            <div className="col-lg-3">
              <p
                className={`${classes.remember_text} position-relative`}
                dir="rtl"
              >
                איך נוכל לחבר את החלקים ולשמר לעד את מי שהם היו?
              </p>
            </div>
          </div>
        </div>
      </section>
    </Section>
  );
};

export default Remember;

import React from 'react';
import Section from '../layout/Section';
import classes from './WallFriend.module.css';
import arrow from '../../assets/wall-friend-img/arrow.png';
import { addUser, removeUser } from '../../features/profile/profileSlice';
import { useDispatch } from 'react-redux';
import toastCreator from '../../utils/toastifyCreator';
import emailTextAndSubject from '../../utils/emailTextAndSubject';
import { createNotification } from '../../features/notification/notificationSlice';
import {
  addOrganizationUser,
  removeOrganizationUser,
} from '../../features/organizationProfile/organizationProfileSlice';
const WallFriend = ({ profile, setShowSection, isOwnerOrAdmin }) => {
  const dispatch = useDispatch();
  const showNotification = (status, payload, user) => {
    if (user) {
      const notification = {
        ...emailTextAndSubject(user.field, {
          organizationName: profile.organizationName,
          firstName: profile.firstName,
          lastName: profile.lastName,
        }),
        creator: profile.originalUser._id,
        receivers: [user._id],
        url: window.location.href,
      };
      // dispatch(createNotification(notification)); //TODO: Server notifications
    }
    toastCreator(status, payload);
  };

  const addUserToProfile = (userId, field) => {
    const formData = { userId, field, profileId: profile._id };
    const formData2 = {
      userId,
      field: 'friendRequests',
      profileId: profile._id,
    };

    if (profile.isOrganization) {
      dispatch(addOrganizationUser({ formData, showNotification }));
      dispatch(
        removeOrganizationUser({ formData: formData2, showNotification })
      );
    } else {
      dispatch(addUser({ formData, showNotification }));
      dispatch(removeUser({ formData: formData2, showNotification }));
    }
  };
  const removeUserToProfile = (userId, field) => {
    const formData = { userId, field, profileId: profile._id };
    if (profile.isOrganization) {
      dispatch(removeOrganizationUser({ formData, showNotification }));
    } else {
      dispatch(removeUser({ formData, showNotification }));
    }
  };
  return (
    <Section
      className={classes.wall_friend}
      colorful
      cloudPos="down-right"
      gap={false}
      padding
    >
      <div className="container">
        <h2>חברים</h2>
        {isOwnerOrAdmin && (
          <div className="row">
            <h3>חברים חדשים</h3>

            <div className="col-lg-8 col-md-10 mx-auto">
              {profile.friendRequests.length > 0 ? (
                profile.friendRequests.map((friendRequest, index) => (
                  <FriendBox
                    removeUserToProfile={removeUserToProfile}
                    addUserToProfile={addUserToProfile}
                    type="friendRequests"
                    user={friendRequest}
                    key={index}
                    isOwnerOrAdmin={isOwnerOrAdmin}
                  />
                ))
              ) : (
                <h3 className="not_found" dir="rtl">
                  אין בקשות חברות חדשות, ניתן לשתף את הפרופיל ולהזמין חברים
                </h3>
              )}
            </div>
          </div>
        )}

        <div className="row  my-5">
          <h3>רשימת מנהלים</h3>
          <div className="col-lg-8 col-md-10 mx-auto">
            <div
              className={`${classes.wall_friend_list} ${classes.profileAdmins}`}
            >
              <div className="d-flex align-items-center gap-5 justify-content-end">
                <h3>
                  {profile.organizationName} {profile.originalUser.firstName}{' '}
                  {profile.originalUser.lastName}
                </h3>
                <img
                  src={profile.originalUser.profilePicture}
                  alt={
                    profile.organizationName || profile.originalUser.firstName
                  }
                />
              </div>
            </div>
            {profile.profileAdmins.map((admin, index) => (
              <FriendBox
                removeUserToProfile={removeUserToProfile}
                addUserToProfile={addUserToProfile}
                type="profileAdmins"
                user={admin}
                key={index}
                isOwnerOrAdmin={isOwnerOrAdmin}
              />
            ))}
          </div>
        </div>
        <div className="row">
          <h3>רשימת חברים</h3>

          <div className="col-lg-8 col-md-10 mx-auto">
            {profile.profileFriends.length > 0 ? (
              profile.profileFriends.map((friend, index) => (
                <FriendBox
                  removeUserToProfile={removeUserToProfile}
                  addUserToProfile={addUserToProfile}
                  type="profileFriends"
                  user={friend}
                  key={index}
                  isOwnerOrAdmin={isOwnerOrAdmin}
                  profileAdmins={profile.profileAdmins}
                />
              ))
            ) : (
              <h3 className="not_found" dir="rtl">
                עדיין אין חברויות בעמוד, ניתן לשתף את הפרופיל ולהזמין חברים
              </h3>
            )}
          </div>
        </div>

        <div
          className={`d-flex align-items-center justify-content-center gap-5 mt-5 ${classes.nav_arrow}`}
          onClick={() => setShowSection('wall')}
        >
          <h5>חזרה</h5>
          <img src={arrow} alt="arrow" />
        </div>
      </div>
    </Section>
  );
};

const FriendBox = ({
  removeUserToProfile,
  addUserToProfile,
  type,
  user,
  isOwnerOrAdmin,
  profileAdmins,
}) => {
  return (
    <div
      className={`d-flex justify-content-between align-items-center ${classes.wall_friend_list} ${classes[type]}`}
    >
      <div className="d-flex align-items-center justify-content-between">
        {isOwnerOrAdmin && (
          <React.Fragment>
            {type === 'profileFriends' &&
              !profileAdmins.find((admin) => admin._id === user._id) && (
                <React.Fragment>
                  <button
                    className={classes.action_button}
                    onClick={() => addUserToProfile(user._id, 'profileAdmins')}
                  >
                    מנהל
                  </button>
                  <span className={classes.fancy_bar}>|</span>
                </React.Fragment>
              )}
            {type === 'profileAdmins' || type === 'profileFriends' ? (
              <button
                className={classes.action_button}
                onClick={() => removeUserToProfile(user._id, type)}
              >
                הסרה
              </button>
            ) : (
              <React.Fragment>
                <button
                  className={classes.action_button}
                  onClick={() => removeUserToProfile(user._id, type)}
                >
                  סירוב
                </button>
                <span className={classes.fancy_bar}>|</span>
                <button
                  className={classes.action_button}
                  onClick={() => addUserToProfile(user._id, 'profileFriends')}
                >
                  אישור
                </button>
                <span className={classes.fancy_bar}>|</span>
                <button
                  className={classes.action_button}
                  onClick={() => addUserToProfile(user._id, 'profileAdmins')}
                >
                  מנהל
                </button>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
      <div className="d-flex align-items-center gap-5">
        <h3>
          {user.organizationName} {user.firstName} {user.lastName}
        </h3>
        <img
          src={user.profilePicture}
          alt={user.organizationName || user.firstName}
        />
      </div>
    </div>
  );
};

export default WallFriend;

import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';
import './VirtualMemory.css';
import spiral from '../../assets/virtual-img/spiral.png';
import Section from '../layout/Section';
import { showModal } from '../../features/modal/modalSlice';
import DedicationModal from '../../modals/DedicationModal';
const cfImages = [
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497661/f4_rfzvva.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497659/f3_peytxr.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497661/f2_cfvspa.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497659/f1_uxle4u.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497662/c4_zytr81.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497657/c3_zdjbnk.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497656/c2_kcjjwc.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497657/c1_bsfofz.png',
];
let index = 0;
let animating = false;
let animationFrame = null;
let speed = 2;

export default function VirtualMemory({ profileId, profileImg }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { dedicationModal } = useSelector((state) => state.modal);
  const { candlesFlowers } = useSelector((state) => state.candleFlower);
  const [cfForVirtual, setCfForVirtual] = useState([]);
  const [groupedCfForVirtual, setGroupedCfForVirtual] = useState([]);
  const [selectedCf, setSelectedCf] = useState({});
  const cfDivs = useRef(null);
  const memorialWrapper = useRef(null);
  const memorial = useRef(null);
  const demo = useRef(null);

  const getAllCandleFlower = useCallback(async (candlesFlowers) => {
    const cf = candlesFlowers
      .flatMap(({ _id, user, profile, candlesFlowers, guestName,guestEmail }) =>
        candlesFlowers.map(({ id, count, type, img }) => ({
          _id,
          cfId: id,
          count,
          type,
          user,
          profile,
          img,
          guestName,
          guestEmail,
        }))
      )
      .flatMap(({ count, ...rest }) => Array(count).fill(rest));

    setCfForVirtual(cf);
  }, []);

  useEffect(() => {
    getAllCandleFlower(candlesFlowers);
  }, [getAllCandleFlower, candlesFlowers]);

  useEffect(() => {
    const groupedItems = getGroups(cfForVirtual);
    setGroupedCfForVirtual(groupedItems);
  }, [cfForVirtual]);

  const getGroups = (itemsNeedsToBeGrouped) => {
    const groups = [];
    let group = [];
    let limit = 28;

    itemsNeedsToBeGrouped.forEach((item, index) => {
      if (limit < index + 1) {
        limit += 29;
        groups.push(group);
        group = [];
      } else {
        group.push(item);
      }
    });

    if (group) groups.push(group);
    return groups;
  };
  const executeScroll = () => {
    if (demo) {
      demo.current?.scroll({
        top: demo.current?.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const scaleItems = useCallback(() => {
    executeScroll();
    cfDivs.current.forEach((item) => {
      const size = (item.getBoundingClientRect().top / 100).toFixed();
      if (size < 2) {
        item.dataset.scale = 'small';
      } else if (size < 4) {
        item.dataset.scale = 'large';
      } else if (size < 5) {
        item.dataset.scale = '';
      } else if (size < 8) {
        item.dataset.scale = 'large';
      } else {
        item.dataset.scale = 'small';
      }
    });
  }, []);

  const setMemorialHeight = () => {
    const groups = document.querySelectorAll('.layers-group');
    if (!groups || !groups.length) return;
    const lastGroup = groups[groups.length - 1];
    const images = lastGroup.querySelectorAll('.temporary figure');
    const lastImage = images[images.length - 1];
    const groupHeight = groups[0].offsetHeight;
    const unnecessaryHeight = groupHeight - lastImage?.offsetTop;
    memorial.current.style.height =
      groups.length * groupHeight - unnecessaryHeight + 200 + 'px';
  };

  useEffect(() => {
    cfDivs.current = document.querySelectorAll('.temporary figure');
    scaleItems();
    setMemorialHeight();
    // memorialWrapper.current.addEventListener("scroll", scaleItems);
  });

  const startAutoScroll = useCallback(() => {
    if (index > memorial.current.offsetHeight + 400) {
      cancelAnimationFrame(animationFrame);
      memorialWrapper.current.style.opacity = 0;
      index = 0;
      memorial.current.ontransitionend = (e) => e.stopPropagation();
      memorialWrapper.current.addEventListener(
        'transitionend',
        () => {
          startAutoScroll();
          memorialWrapper.current.style.opacity = 1;
        },
        { once: true }
      );
      return;
    }
    animationFrame = requestAnimationFrame(startAutoScroll);
    index = index + speed;
    memorial.current.style.transform = `translateY(${-index}px)`;
    scaleItems();
  }, [scaleItems]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (memorialWrapper.current) {
        const distance =
          window.innerHeight -
          memorialWrapper.current.getBoundingClientRect().top.toFixed();
        if (distance > 1500 || distance < 433) {
          animating = false;
          cancelAnimationFrame(animationFrame);
        } else if (distance > 433) {
          if (animating) return;
          animating = true;
          startAutoScroll();
        }
      }
    });
  }, [groupedCfForVirtual, startAutoScroll]);
  const handleMouseEnter = () => {
    cancelAnimationFrame(animationFrame);
    animating = false;
  };
  const onMouseLeave = () => {
    if (animating || dedicationModal) return;
    animating = true;
    startAutoScroll();
  };

  return (
    <Section
      id="virtual-memory"
      className="temporary"
      cloudPos="down-left"
      colorful
      gap={false}
      cloudZIndex={2}
    >
      <div className="background">
        <img src={profileImg} alt="profile" />
      </div>

      <div
        className="wrapper"
        ref={memorialWrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleMouseEnter}
      >
        <div className="memorial" ref={memorial}>
          {groupedCfForVirtual.map((cf, index) => {
            return (
              <div key={index} className="layers-group">
                <img
                  className="spiral spiral-top-left"
                  src={spiral}
                  alt="spiral"
                />
                <img
                  className="spiral spiral-top-right"
                  src={spiral}
                  alt="spiral"
                />
                <img
                  className="spiral spiral-bottom-left"
                  src={spiral}
                  alt="spiral"
                />
                <img
                  className="spiral spiral-bottom-right"
                  src={spiral}
                  alt="spiral"
                />
                {cf.map((figure, index) => {
                  return (
                    <figure
                      ref={demo}
                      key={index}
                      data-scale="small"
                      className={`position-${index + 1}`}
                      onClick={() => {
                        setSelectedCf(figure._id);
                        dispatch(showModal('dedicationModal'));
                      }}
                    >
                      <img
                        className="img-lg"
                        src={cfImages[figure.cfId - 1]}
                        alt={figure.type}
                      />
                      <figcaption>
                        <img
                          src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1674995119/text-message-icon_1_oyrdbh.png"
                          alt="message"
                          className="message_icon"
                        />
                        <span className="comment">
                          {figure.guestName?`${figure.guestName}`:`${figure.user.firstName} ${figure.user.lastName}`} 
                        </span>
                        <img
                          className="img-sm"
                          src={figure.user.profilePicture}
                          alt={figure.guestName||figure.user.lastName}
                        />
                      </figcaption>
                    </figure>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>

      <DedicationModal
        cf={{
          ...candlesFlowers.find((item) => item._id === selectedCf),
          profileId,
          user: user._id,
        }}
      />
    </Section>
  );
}

import React, { useState } from 'react';
import classes from './LifeStory.module.css';
import { Link } from 'react-router-dom';
import candleFlower from '../../assets/story-img/candleFlower.jpg';
import qr from '../../assets/story-img/qr.jpg';
import wall from '../../assets/story-img/wall.jpg';
import social from '../../assets/story-img/social.jpg';
import calender from '../../assets/story-img/calender.jpg';
import googleMap from '../../assets/story-img/googleMap.jpg';
import Section from '../layout/Section';
import ProfileModal from '../../modals/ProfileModal';

const LifeStory = () => {
  const normalQR=140;
  const picQR=220;
  const [modalText, setModalText] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const hideProfileModal = () => {
    var myModalEl = document.getElementById('lifeStoryModal');
    var modal = window.bootstrap.Modal.getInstance(myModalEl);
    modal.hide();
  };
  return (
    <Section
      className={classes.story}
      gap={false}
      colorful
      cloudPos="down-left"
    >
      <div className="container">
        <h2 className={`section_title ${classes.story_title}`}>
          <span dir="rtl">LIFECLOUD מאפשרת לנו להתחבר ולשתף</span>

          <span dir="rtl">את הרגעים, הסיפורים והמורשת של מי שאינו איתנו</span>
        </h2>
        <div className="row gy-5 ">
          <div className="col-lg-4 col-6 order-lg-0  order-2">
            <div className={classes.story_card}>
              <div
                className={`${classes.story_card_img_container} position-relative text-center`}
              >
                <img src={qr} alt="QR" className="img-fluid" />
                <p className={classes.story_card_description} dir="rtl">
                  קוד QR ייחודי ועמיד שניתן להניח בכל מקום שתבחרו ולחבר כל
                  סמארטפון ישירות לפרופיל
                </p>
              </div>

              <h4 className={classes.story_card_title}>ייחודי QR</h4>
              <button
                className={classes.read_more_btn}
                onClick={() => {
                  setModalTitle(` לוחיות QR מעוצבות לסריקה`);
                  setModalText(
                    <div>
                      קוד QR (Quick Response Code) מהווה סוג של ברקוד דו-ממדי
                      הנסרק באמצעות מצלמת מכשיר המובייל שלנו. בכל העולם השימוש
                      בו נפוץ לסריקת מחירים, אתרי אינטרנט, קריאת תפריטים
                      במסעדות, קבלת מידע רלוונטי, כתובת בעיתון, מיקומי אתרים
                      ועוד.
                      <br />
                      <br />
                      מטרת ה-QR מבחינתנו היא להנגיש את עמוד הפרופיל לסביבה
                      הקרובה של משפחת המנוח וע"י כך לייצר קהילה תומכת ומשתפת,
                      זוהי הדרך הטובה ביותר, היצירתית ביותר והעכשווית ביותר
                      להנציח את זכר היקרים לנו במאה ה-21.
                      <br />
                      <br />
                      ה-QR מגיע ב-3 דרכים למשפחת המנוח:
                      <br />
                      <ol type="1">
                        <li>
                          עם הקמת העמוד מקבלים תמונת QR אותה ניתן לשלוח במייל או
                          בווצאפ לכל אחד, דרכה ניתן יהיה להגיע ישירות לדף
                          הפרופיל של המנוח ולהצטרף לקהילה. קבלת הדף הינה
                          אוטומטית ואינה כרוכה כלל בתשלום.
                        </li>
                        <li>
                          הזמנת לוחית מתכת עם הטבעת ה-QR, לוחית 'סטנדרט' לחבר על
                          הקבר. הלוחית עמידה לתנאי מזג אוויר (גשם ולחות) ומגיעה
                          בערכה ידידותית עם דבק איכותי ולוחית נוספת ( סה"כ 2
                          לוחיות). הסיבה שאנו מספקים 2 יחידות היא כי משפחות רבות
                          ביקשו לוחית נוספת לשים בכניסה לביתם. ערכה של שתי
                          לוחיות QR סטנדרטיות (גודל 5*8 ס"מ) בתוספת דבק ייחודי
                          עולה{normalQR}₪ בלבד.
                        </li>
                        <li>
                          הזמנת לוחית QR (גודל 14*9 ס"מ) עם תמונת המנוח העמידה
                          לתנאי מזג אוויר (גשם ולחות) ומגיעה בערכה ידידותית עם
                          דבק איכותי ולוחית סטנדרטית נוספת (סה"כ 2 לוחיות - אחת
                          עם תמונה והקדשה, השנייה לוחית סטנדרטית עם הטבעה). ערכה
                          של שתי לוחיות QR (לוחית תמונה+ סטנדרטית) בתוספת דבק
                          ייחודי עולה {picQR}₪ בלבד.
                        </li>
                      </ol>
                      <br />
                      <br />
                      <div className="text-center">
                        <Link
                          className={classes.lifestory_seemore_btn}
                          to="/create-profile?hpfeature1"
                          onClick={hideProfileModal}
                        >
                          יצירת עמוד ללא עלות ובחירת לוחית
                        </Link>
                      </div>
                    </div>
                  );
                }}
                data-bs-toggle="modal"
                data-bs-target="#lifeStoryModal"
              >
                קרא עוד
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-6 order-lg-0 order-6">
            <div className={classes.story_card}>
              <div
                className={`${classes.story_card_img_container} position-relative text-center`}
              >
                <img src={wall} alt="LifeCloud Wall" className="img-fluid" />
                <p className={classes.story_card_description}>
                  פרופיל ליקר לנו בו ניתן לבנות קהילת חברים, לשתף חוויות, להעלות
                  זכרונות ולחבר את פיסות חייו של יקירינו
                </p>
              </div>
              <h4 className={classes.story_card_title}>פרופיל</h4>
              <button
                className={classes.read_more_btn}
                onClick={() => {
                  setModalTitle(`עמוד פרופיל לזכר מנוח`);
                  setModalText(
                    <div>
                      לכל אחד מאיתנו יש מכר, בן משפחה, קולגה לעבודה או חבר ממעגל
                      חברתי כזה או אחר שנפטר, אם בשיבה טובה ואם בטרם עת. כל אדם
                      שאיבד דמות קרובה אליו משקיע משאבי זמן בשימור הזיכרונות
                      והנצחת המנוח.
                      <br />
                      <br />
                      במשך דורות רבים הנצחנו את קרובינו בצורה מסוימת ואותנטית.
                      לצד ימי הזיכרון ואירועי הזיכרון הרשמיים והפחות רשמיים יש
                      מגמה של התאמת פעולות הזיכרון לפלטפורמות הטכנולוגיות
                      העכשוויות.
                      <br />
                      <br />
                      בניית הפרופיל, על כל המרכיבים והפיצ'רים שהפלטפורמה מאפשרת
                      מייצרת לנו, בני המשפחה והחברים, קרקע ייחודית לשימור
                      הזיכרונות ופיסות הזיכרון מהדמות שאיבדנו.
                      <br />
                      <br />
                      מטרת הפרופיל היא להשאיר את המנוח בתודעה של קרוביו ולבנות
                      סביבו קהילת חברים/משפחה תומכים. הפרופיל הוא פלטפורמה
                      מושלמת לשימור הזיכרונות ופיסות המידע, ויתרה מזאת אפשרות
                      לשמר זיכרונות והיסטוריה שלאו דווקא ידועה למשפחה הקרובה.
                      <br />
                      <br />
                      פתיחת הפרופיל פשוטה לתפעול, ובכל מקרה ניתן לקבל סיוע במקרה
                      של קושי טכנולוגי או רגשי. פתיחת הפרופיל אינה כרוכה בתשלום
                      וניתן מיד לגשת לדף צרו הפרופיל ולהתחיל בבנייתו.
                      <br />
                      <br />
                      הפרופיל האישי של המנוח מציג ומציע מספר אפשרויות, למעשה הוא
                      מייצר עבור הקרובים והסובבים, פאזל של מידעים:
                      <br />
                      <ul style={{ listStyleType: 'disc' }}>
                        <li>פרטים אישיים של המנוח</li>
                        <li>סיפור חיים</li>
                        <li>
                          נקודות ציון (דומה במקצת לקורות חיים רק בסגנון אישי
                          יותר: תנועות נוער, צבא, מגורים, טיולים וכו')
                        </li>
                        <li>גלריית מדיה (תמונות וסרטונים)</li>
                        <li>אזור של זיכרונות ותגובות</li>
                        <li>מיקום בית הקברות ומיקום הקבר</li>
                        <li>
                          תאריכי אזכרות ומועדים חשובים, כולל יכולת לשלוח תזכורות
                          לחברי הקהילה
                        </li>
                        <li>אפשרות להנחת פרח וירטואלי ו/או נר זיכרון</li>
                      </ul>
                      <br />
                      <br />
                      <div className="text-center">
                        <Link
                          className={classes.lifestory_seemore_btn}
                          to="/create-profile?hpfeature2"
                          onClick={hideProfileModal}
                        >
                          יצירת עמוד לזכר יקירכם ללא עלות
                        </Link>
                      </div>
                    </div>
                  );
                }}
                data-bs-toggle="modal"
                data-bs-target="#lifeStoryModal"
              >
                קרא עוד
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-6 order-lg-0 order-3">
            <div className={classes.story_card}>
              <div
                className={`${classes.story_card_img_container} position-relative text-center`}
              >
                <img src={social} alt="story" className="img-fluid" />
                <p className={classes.story_card_description}>
                  צוות מלאכי התוכן שלנו יגיעו לביתכם ויבנו עבורכם את עמוד
                  הפרופיל. *השירות בתשלום
                </p>
              </div>
              <h4 className={classes.story_card_title}>מלאכי תוכן</h4>
              <button
                className={classes.read_more_btn}
                onClick={() => {
                  setModalTitle(` מלאכי תוכן - כאן בשבילכם`);
                  setModalText(
                    <div>
                      ציינו כבר באתר שהקמת פרופיל למנוח הינו תהליך פשוט מבחינה
                      דיגיטלית, אינו לוקח הרבה זמן ולא דורש הבנה עמוקה ברשת
                      האינטרנט. יחד עם זאת ישנן משפחות רבות שמשימת פתיחת הפרופיל
                      אינה נוחה להם:
                      <br />
                      <br />
                      <ul style={{ listStyleType: 'disc' }}>
                        <li>
                          גיל מתקדם של הורים וקושי טכנולוגי לפתוח פרופיל כקב
                          היכרות נמוכה עם עולם האינטרנט.
                        </li>
                        <li>
                          קושי רגשי לעסוק בנושא ולרכז את הנתונים עבור הדף.
                        </li>
                        <li>
                          זקוקים לאיזושהי "דחיפה" ועזרה להעמקת מפעל ההנצחה.
                        </li>
                      </ul>
                      בשיחות שאנו מבצעים עם מאות בני משפחה וקרובים אנו מקבלים את
                      אותו מסר בכל פעם: "אנחנו נשתף פעולה עם כל אפשרות לחזק את
                      המודעות לקרוב שלנו שנפטר או שנפל חלל, העיסוק סביב הפרופיל
                      "מחייה" את הקרבה שלנו למנוח."
                      <br />
                      <br />
                      החלטנו, כחלק מהמפעל החשוב לשימור הזיכרון, לספק אופציה
                      וסיוע למשפחות הזקוקות לסיוע בהקמת הפרופיל. קראנו לפרויקט
                      "מלאכי התוכן, צעירים ומבוגרים שמגיעים לבית המשפחה ומקימים
                      עבורם ויחד איתם את הפרופיל."
                      <br />
                      <br />
                      במהלך השיחה עם מלאך התוכן ייבנה הפרופיל:
                      <br />
                      <ul style={{ listStyleType: 'disc' }}>
                        <li>פרטים אישיים</li>
                        <li>מידע עמוק יותר</li>
                        <li>סיפור חיים</li>
                        <li>
                          נקודות ציון חשובות- לוחות זמנים כרונולוגיים בחייו של
                          המנוח
                        </li>
                        <li>העלאת תמונות וסרטונים לפרופיל</li>
                        <li>ביצוע ראיון ואיסוף של נתונים נוספים</li>
                        <li>
                          הדרכה על תפעול הפרופיל בעתיד, כולל כיצד מעלים את מיקום
                          הקבר בבית הקברות ועוד.
                        </li>
                      </ul>
                      מלאכי התוכן יודעים ומגיעים לכל בית אשר יוזמנו אליו מדן ועד
                      אילת, הפגישה מתקיימת עבור תשלום סמלי וחד פעמי, של 200 ₪
                      שמיועד לכיסוי הוצאות הביקור המרגש.
                      <br />
                      <br />
                      <br />
                      <div className="text-center">
                        <Link
                          className={classes.lifestory_seemore_btn}
                          to="/contact?hpfeature3"
                          onClick={hideProfileModal}
                        >
                          צרו קשר לקבלת פרטים נוספים
                        </Link>
                      </div>
                    </div>
                  );
                }}
                data-bs-toggle="modal"
                data-bs-target="#lifeStoryModal"
              >
                קרא עוד
              </button>
            </div>
          </div>

          <div className="col-lg-4 col-6 order-lg-0 order-5">
            <div className={classes.story_card}>
              <div
                className={`${classes.story_card_img_container} position-relative text-center`}
              >
                <img src={googleMap} alt="story" className="img-fluid" />
                <p className={classes.story_card_description}>
                  בדרך קלה ויעילה נקבל קישור לבית העלמין והמצבה
                </p>
              </div>
              <h4 className={classes.story_card_title}>מיקום הקבר</h4>
              <button
                className={classes.read_more_btn}
                onClick={() => {
                  setModalTitle(`מיקום הקבר - הכוונה לקבר בקלות`);
                  setModalText(
                    <div>
                      בתי הקברות גדלים, חלקם ובעיקר העירוניים הגדולים שביניהם,
                      גדלים בצורה משמעותית ועם השנים יותר קשה למצוא בהם חלקת
                      קבר, במיוחד אם זוהי הפעם הראשונה שאנו באים לבקר ולהתייחד
                      עם קרובינו.
                      <br />
                      <br />
                      הפיצ'ר הנ"ל אמור לסייע למבקרים למצוא את בית הקברות המיועד
                      ואת חלקת הקבר.
                      <br />
                      <br />
                      כאשר נכנסים לאתר ומקלידים את שם הנפטר ומתקבלת תוצאה ובה
                      הכוונה מדויקת אל חלקת הקבר.
                      <br />
                      <br />
                      שלבים להטמעת מיקום הקבר בפרופיל המנוח:
                      <br />
                      <ol type="1">
                        <li>
                          בני המשפחה או נציג מטעמם צריך להגיע לחלקת הקבר
                          המדויקת, לפתוח את פרופיל המנוח וללחוץ על 'עריכה'.
                        </li>
                        <li>
                          בעריכה תוכלו לראות כפתור שנקרא 'מיקום הקבר', לחצו עליו
                          ומצאו את הכפתור שנקרא: 'נעיצת מיקומכם הנוכחי כמיקום
                          הקבר', חלצו עליו ובכך למעשה הוא מתחבר לגוגל מפות.
                        </li>
                        <li>
                          לחצו על שמירה ומרגע זה כל מי שייכנס לפרופיל שפתחתם
                          יוכל למצוא את הקבר בקלות דרך השימוש באפליקציה.
                        </li>
                      </ol>
                      <b>
                        נקודה חשובה: על מנת להוסיף את מיקום הקבר באתר עליכם
                        לוודא שבמכשיר הסלולרי שלכם מופעלים "שירותי מיקום".
                      </b>
                      <br />
                      <br />
                      <br />
                      <div className="text-center">
                        <Link
                          className={classes.lifestory_seemore_btn}
                          to="/create-profile?hpfeature4"
                          onClick={hideProfileModal}
                        >
                          יצירת עמוד והוספת מיקום מצבה
                        </Link>
                      </div>
                    </div>
                  );
                }}
                data-bs-toggle="modal"
                data-bs-target="#lifeStoryModal"
              >
                קרא עוד
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-6 order-lg-0 order-5">
            <div className={classes.story_card}>
              <div
                className={`${classes.story_card_img_container} position-relative text-center`}
              >
                <img src={calender} alt="story" className="img-fluid" />
                <p className={classes.story_card_description}>
                  ציון ימים חשובים, שליחת הזמנות לאירועי אזכרה ומפגשים
                </p>
              </div>
              <h4 className={classes.story_card_title}>ניהול מועדים</h4>
              <button
                className={classes.read_more_btn}
                onClick={() => {
                  setModalTitle(`ניהול מועדים`);
                  setModalText(
                    <div>
                      בתוך עמוד המנוח - פרופיל, ניתן להכניס נתונים רבים. אחת
                      מאותן אפשרויות של הכנסת נתונים היא קטגוריית "ניהול
                      מועדים". המשפחה בוחרת אילו אירועים לציין (למשל ימי זיכרון
                      לאומיים, יום הפטירה/ נפילה, יום הולדת, נישואים וכו'...).
                      <br />
                      <br />
                      האירועים מהווים "נקודות ציון", ניתן לעצב הזמנה מעוצבת עבור
                      קהילת החברים והזמנתם לאירוע, ובנוסף, ניתן לבנות אלבום של
                      אירוע שצוין ובו יהיה תמונות, הספדים, סיפורים ועוד.
                      האירועים הנ"ל מחזקים את הקהילה, מחזקים את הקשר עם המשפחה,
                      מאפשרים לייצר פסיפס של מידע שנותן תחושה שהזיכרון חי
                      "ובועט" והוא ממשי לגמרי. הדבר מהווה חיבוק אמיץ וחזק של
                      הקהילה את המשפחה של המנוח.
                      <br />
                      <br />
                      את המועדים ניתן להטמיע בפרופיל כבר מרגע פתיחת הפרופיל או
                      בהמשך הדרך. ניתן לעשות זאת לבד (קל לתפעול) או באמצעות מלאך
                      תוכן שמגיע למשפחה הביתה ומסייע בבניית הפרופיל.
                    </div>
                  );
                }}
                data-bs-toggle="modal"
                data-bs-target="#lifeStoryModal"
              >
                קרא עוד
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-6 order-lg-0 order-4">
            <div className={classes.story_card}>
              <div
                className={`${classes.story_card_img_container} position-relative text-center`}
              >
                <img src={candleFlower} alt="story" className="img-fluid" />
                <p className={classes.story_card_description}>
                  נחלוק כבוד ליקירינו וקרוביהם על ידי הדלקת נר או הנחת פרח
                  בפרופיל
                </p>
              </div>
              <h4 className={classes.story_card_title}>הדלקת נר והנחת פרח</h4>
              <button
                className={classes.read_more_btn}
                onClick={() => {
                  setModalTitle(`הדלקת נר לזכרם והנחת פרח בשבילם`);
                  setModalText(
                    <div>
                      בדיוק כפי שמניחים פרח יחיד או זר על קבר ובדיוק כפי
                      שמדליקים נר נשמה, האתר שלנו מציע דרך הזדהות עם המשפחה,
                      בימי חול או בימי אזכור ייחודיים.
                      <br />
                      <br />
                      לא תמיד ניתן להגיע לקבר (מרחק, מזג אוויר, אי שהות בארץ
                      ועוד) ובאמצעות הפיצ'ר באתר של הנחת פרח או הדלקת נר כך
                      תוכלו להביע את הזדהותכם עם המשפחה ואת הבעת הצער והכאב עם
                      המשפחה הקרובה והחברים. ברגע שתבצעו הנחה צנועה של נר או
                      פרח המשפחה תקבל אזכור לכך והלב יתחמם.
                      <br />
                      <br />
                      ניתן לציין מתוך דף הפרופיל את ימי הזיכרון, ימי העלייה
                      לקבר, תאריכי ימי הולדת ועוד תאריכים אחרים חשובים. לכל חברי
                      הקהילה יישלח איזכור למועד (אזכרה למשל) ואם מישהו לא יכל
                      להגיע או שנבצר ממנו להצטרף למשפחה הוא יכול להזדהות באמצעות
                      הנחת זר וירטואלי או נר.
                      <br />
                      <br />
                      <b>
                        חשוב לציין, הפעולה כרוכה בתשלום מאוד סמלי של 5 ₪ וזאת
                        לשם כיסוי הוצאות האתר שניתן ללא עלות והמשך הפיתוח שלו
                        לטובת הקהילות.
                      </b>
                      <br />
                      <br />
                      <br />
                      <div className="text-center">
                        <Link
                          className={classes.lifestory_seemore_btn}
                          to="/create-profile?hpfeature6"
                          onClick={hideProfileModal}
                        >
                          יצירת עמוד והנחת נר או פרח
                        </Link>
                      </div>
                    </div>
                  );
                }}
                data-bs-toggle="modal"
                data-bs-target="#lifeStoryModal"
              >
                קרא עוד
              </button>
            </div>
          </div>
        </div>
      </div>
      <ProfileModal
        bsTarget="lifeStoryModal"
        modalTitle={modalTitle}
        modalText={modalText}
      />
    </Section>
  );
};

export default LifeStory;
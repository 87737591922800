import React from 'react';
import MainLayout from '../../components/layout/MainLayout';
import lifecloud from '../../assets/bio-img/lifecloud.png';
import signature from '../../assets/bio-img/signature.png';
import classes from './About.module.css';
import SocialFooter from '../../components/SocialFooter/SocialFooter';
import { Helmet } from 'react-helmet-async';

const About = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | הכירו את חזון החברה והסיפור מאחוריה</title>
        <meta
          property="og:title"
          content="LifeCloud | הכירו את חזון החברה והסיפור מאחוריה"
        />
        <meta property="og:url" content="https://lifecloud-qr.com/about" />
        <meta
          name="description"
          content="המקום לבניית קהילה מנציחה עם חברים, משפחה ומכרים מגיע מצורך שרובנו מרגישים, המשך סיפור חייהם של יקירינו שאינם עוד איתנו. בואו להכיר את החזון והשירות שאנחנו מציעים."
        />

        <meta
          property="og:description"
          content="המקום לבניית קהילה מנציחה עם חברים, משפחה ומכרים מגיע מצורך שרובנו מרגישים, המשך סיפור חייהם של יקירינו שאינם עוד איתנו. בואו להכיר את החזון והשירות שאנחנו מציעים."
        />
      </Helmet>
      <MainLayout gap={false}>
        <section className={classes.bio}>
          <div className={classes.bio_up}>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-10 mx-auto">
                  <div className={classes.img_container}>
                    <img src={lifecloud} alt="logo" />
                  </div>
                  <p dir="rtl">
                    מיזם LifeCloud הוקם על ידי קבוצת חברים שהגיעו כל אחד מתחומו
                    – אמנות, צבא וביטחון, עסקים ותקשורת. עם מטרה אחת:{' '}
                    <b>
                      לאפשר לכולם לחלוק, לשתף ולחגוג את החיים שהיו ליקרים לנו
                      מכל ושאינם.
                    </b>
                  </p>

                  <p className="my-5" dir="rtl">
                    כולנו חווינו אובדן, אם של הורים שנפטרו בשיבה טובה, או חלילה
                    חברים ובני משפחה שנפטרו בנסיבות מצערות. אבל{' '}
                    <b>
                      אחרי הצער והאבל הבנו שיש חיים שלמים של זכרונות, חוויות
                      משותפות ורצון בלתי נגמר לחלוק אותו עם האהובים שלנו.{' '}
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.bio_down}>
            <div className="container">
              <div className="row ">
                <div className="col-lg-8 col-md-6 mx-auto px-0">
                  <p dir="rtl">
                    בעולם המתקדם של רשתות חברתיות, מדיה דיגיטלית ואין סוף
                    אפשרויות, מצאנו שצריך לתת מקום מכבד ונפרד לחברים ומשפחה שכבר
                    לא איתנו. לכן, במהלך תקופה ארוכה ניסינו להבין יותר, לחקור,
                    לשאול ולמצוא את הנוסחה הנכונה – וכך בעצם הקמנו את LifeCloud,
                    על מנת <b> ליצור בית חדש לחיים שאחרי</b> אנחנו מגישים
                    ופותחים בפניכם את המיזם שלנו, שנולד כולו מתוך שליחות אמיתית,
                    לכן טבעי שנרצה להציע אותו לכולם ובגלל זה הוא פתוח בפניכם ללא
                    תשלום. כדי שכל אחד יוכל לשתף ולספר על האמא, האח, החבר מהצבא
                    או כל מי שאנחנו מתגעגעים אליהם כל כך.
                  </p>
                  <br />
                  <p dir="rtl">
                    עוד יהיו שיפורים ושינויים, ואנחנו שמחים לקבל כל פידבק כדי
                    להמשיך ולעצב לכם את המקום הכי נכון עבור היקרים לכם ומקווים
                    שתאהבו את מה שבנינו כאן, עבורכם.
                  </p>
                  <br />
                  <p dir="rtl"> שלכם, צוות LifeCloud</p>
                  <img src={signature} alt="" />
                  <p dir="rtl" className={classes.signature_text}>
                    יזם ומנכ"ל חברת LifeCloud
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SocialFooter bg="#f5fcff" cloudPos="down-left" />
      </MainLayout>
    </React.Fragment>
  );
};

export default About;

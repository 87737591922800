import React, { useEffect, useState } from 'react';
import classes from './DedicationModal.module.css';
import cross from '../assets/candleflower-modal-img/x.png';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../features/modal/modalSlice';
import CfCommentForm from '../components/CfCommentForm/CfCommentForm';
const DedicationModal = ({ cf }) => {
  const { dedicationModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [showCommentForm, setShowCommentForm] = useState(false);
  return (
    <React.Fragment>
      <Modal
        show={dedicationModal}
        onHide={() => dispatch(hideModal('dedicationModal'))}
        size="sm"
        centered
      >
        <Modal.Header className="justify-content-end border-0">
          <button
            type="button"
            className={`${classes.btn_close} btn btn-light`}
            onClick={() => dispatch(hideModal('qrFirstModal'))}
          >
            <img src={cross} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <CfCommentForm
            showCommentForm={showCommentForm}
            setShowCommentForm={setShowCommentForm}
            cfComment={{
              cfId: cf._id,
              user: cf.user,
              profileId: cf.profileId,
            }}
          />
          <div className={classes.dedication_img}>
            <img
              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1674995119/text-message-icon_1_oyrdbh.png"
              alt="message"
            />
          </div>
          <div className={classes.dedication_container}>
            <h3 className={classes.dedication_text}>{cf.dedication}</h3>
            <hr className={classes.hr} />
            <h4 className={classes.dedicator}>{cf.username}</h4>
            <h5 className={classes.comments_number}>
              תגובות ({cf.comments?.length})
            </h5>
            <hr className={classes.hr} />
            {cf.comments?.map((comment, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="d-flex justify-content-center align-items-center mt-4 mb-2 gap-2">
                    <h6 className={classes.commenter_name}>
                      {comment.user.firstName} {comment.user.lastName}{' '}
                    </h6>
                    <img
                      src={comment.user.profilePicture}
                      alt="user"
                      className={classes.commenter_img}
                    />
                  </div>
                  <p className={classes.commenter_content}>{comment.text} </p>
                  <hr className={classes.hr} />
                </React.Fragment>
              );
            })}
          </div>
          <div className="text-center">
            <button
              className={classes.dedication_btn}
              onClick={() => setShowCommentForm(true)}
            >
              השאר תגובה ...
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default DedicationModal;

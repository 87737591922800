import axios from 'axios';

const lifeAxios = (jwt) => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { authorization: `Bearer ${jwt}` },
  });
};

export default lifeAxios;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import MainLayout from '../../components/layout/MainLayout';
import Button from '../../components/UI/Button/Button';
import VirtualMemory from '../../components/VirtualMemory/VirtualMemory';
import { createCandleFlower } from '../../features/candleFlower/candleFlowerSlice';
import { getProfile } from '../../features/profile/profileSlice';
import CandleFlower from '../../modals/CandleFlowerModal';
import classes from './DropsOfMemory.module.css';
import whatsAppImg from '../../assets/wall-friend-img/whatsapp.png';
import CandleFlowerWidget from '../../components/CandleFlowerWidget/CandleFlowerWidget';
import { Helmet } from 'react-helmet-async';
const DropsOfMemory = () => {
  const { profile } = useSelector((state) => state.profile);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { profileId } = useParams();
  useEffect(() => {
    dispatch(getProfile(profileId));
  }, [dispatch, profileId]);
  const handleFormSubmit = (event, candleFlowerState) => {
    event.preventDefault();
    const formData = {
      candles: candleFlowerState.candle,
      flowers: candleFlowerState.flower,
      user: user._id,
      profile: profileId,
    };
    dispatch(createCandleFlower(formData));
  };

  const shareURL = `
  https://api.whatsapp.com/send/?text=זוכרים את ${profile.firstName} ${profile.lastName}? בואו לראות את כל הנרות והפרחים שהחברים שמו להנצחה בLifeCloud, אם הפרופיל מוגדר כפרטי תצטרכו להרשם
  ☁️ %0a %0a https://lifecloud-qr.com${window.location.pathname}`;

  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | טיפות של זכרון</title>
        <meta
          property="og:url"
          content={`https://lifecloud-qr.com/virtual-memory/${profileId}`}
        />
      </Helmet>
      <MainLayout>
        <h2 className="profile_title py-5">טיפות של זכרון</h2>
        <CandleFlowerWidget profileId={profileId} />
        <VirtualMemory profileImg={profile?.profileImg} profileId={profileId} />
        <div className={classes.button_group}>
          <Link to={`/profile/${profileId}`}>
            <Button> ⬅ חזרה לפרופיל</Button>
          </Link>
          <a href={shareURL} target="_blank" rel="noreferrer">
            <Button dir="rtl" className="d-flex align-items-center">
              + שיתוף בWhatsApp
              <img
                src={whatsAppImg}
                alt="whatsApp"
                className="me-2 img-fluid"
              />
            </Button>
          </a>
        </div>
        <CandleFlower handleFormSubmit={handleFormSubmit} />
      </MainLayout>
    </React.Fragment>
  );
};

export default DropsOfMemory;

import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { createNotification } from '../../features/notification/notificationSlice';
import { addUser, removeUser } from '../../features/profile/profileSlice';
import emailTextAndSubject from '../../utils/emailTextAndSubject';
import toastCreator from '../../utils/toastifyCreator';
import Section from '../layout/Section';
import Button from '../UI/Button/Button';
import classes from './ProfileNavigator.module.css';
import { AiFillLock } from 'react-icons/ai';
import { showModal } from '../../features/modal/modalSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ProfileNavigator = ({
  showSection,
  setShowSection,
  isFriend,
  isOwnerOrAdmin,
  user,
  profile,
}) =>
{
  const dispatch = useDispatch();
  const showNotification = (status, payload) => toastCreator(status, payload);

  const addUserToProfile = (event) =>
  {
    event.preventDefault();
    const profileName = `${profile.firstName} ${profile.lastName}`;
    if (!user._id) return dispatch(showModal('loginModal'));

    // ----> Public


    if (profile.privacy === "public") {
      const formData = {
        field: 'profileFriends',
        profileId: profile._id,
        userId: user._id,
      };

      const sendTo = [...new Set([
        profile.originalUser._id,
        ...profile.profileAdmins.map((admin) => admin._id),
        ...profile.profileFriends.map((friends) => friends._id),

      ])];

      const notification = {
        ...emailTextAndSubject(
          'newMemeberJoined',
          {
            organizationName: profile.organizationName,
            firstName: profile.firstName,
            lastName: profile.lastName,
          },
          {
            organizationName: user.organizationName,
            firstName: user.firstName,
            lastName: user.lastName,
          }
        ),
        profile: profile._id,
        creator: user._id,
        receivers: sendTo,
        url: window.location.href,
      };

      const notification2 = {
        ...emailTextAndSubject(
          'welcomeToCommunity',
          {
            organizationName: profile.organizationName,
            firstName: profile.firstName,
            lastName: profile.lastName,
          },
          {
            organizationName: user.organizationName,
            firstName: user.firstName,
            lastName: user.lastName,
          }
        ),
        profile: profile._id,
        creator: user._id,
        receivers: [
          user._id
        ],
        url: window.location.href,
      };
      // dispatch(createNotification(notification)); //TODO: Server notifications
      // dispatch(createNotification(notification2)); //TODO: Server notifications
      dispatch(addUser({ formData, showNotification }));
      toast.success(`אתה עכשיו חבר של  ${profileName}`, {
        autoClose: 5000,
      });
    }

    // -----> PRIVATE
    else {
      const formData = {
        field: 'friendRequests',
        profileId: profile._id,
        userId: user._id,
      };

      const sendTo = [...new Set([
        profile.originalUser._id,
        ...profile.profileAdmins.map((admin) => admin._id),
        ...profile.profileFriends.map((friends) => friends._id),

      ])];

      const notification = {
        ...emailTextAndSubject(
          'friendRequests',
          {
            organizationName: profile.organizationName,
            firstName: profile.firstName,
            lastName: profile.lastName,
          },
          {
            organizationName: user.organizationName,
            firstName: user.firstName,
            lastName: user.lastName,
          }
        ),
        profile: profile._id,
        creator: user._id,
        receivers: sendTo,
        url: window.location.href,
      };

      // dispatch(createNotification(notification));
      dispatch(addUser({ formData, showNotification }));
    }

  };



 const removeUserToProfileRequest = () =>
  {
    
      const formData = {
        userId: user._id,
        field: 'friendRequests',
        profileId: profile._id,
      };
      dispatch(removeUser({ formData, showNotification }));
    
  };


  const removeUserToProfile = () =>
  {

    if (profile.privacy === "public") { // -------> PUBLIC

      const formData = {
        userId: user._id,
        field: 'profileFriends',
        profileId: profile._id,
      };
      dispatch(removeUser({ formData, showNotification }));
    }

    else   // ---------> PRIVATE

    {
      const formData = {
        userId: user._id,
        field: 'profileFriends',
        profileId: profile._id,
      };
      dispatch(removeUser({ formData, showNotification }));
    }
  };

  return (
    <Section className={classes.profile_navigator} showCloud={false}>
      <div className="container">
        <div className="d-flex justify-content-center align-items-center gap-sm-5 gap-2">
          {isOwnerOrAdmin ? (
            <Link to={`/edit-profile/${profile._id}`}>עריכה</Link>
          ) : profile.friendRequests.find((u) => u._id === user._id) ? (
            <Button onClick={removeUserToProfileRequest} className={classes.active}>
              ממתין
            </Button>
          ) : (
            !isFriend && <Button onClick={addUserToProfile}>בקשת חברות</Button>
          )}
          {!isOwnerOrAdmin && isFriend && (
            <Button onClick={removeUserToProfile}
              className={`${classes.active}`}
            >הנך חבר/ה</Button>
          )}
          <Button
            onClick={() => setShowSection('wallFriend')}
            className={`${showSection === 'wallFriend' && classes.active} `}
          >
            חברים ({profile.profileFriends.length})
          </Button>
{/*
           <Button data-bs-toggle="modal" data-bs-target="#facebookShareModal">
            פייסבוק{' '}
          </Button>
*/}
          <Button data-bs-toggle="modal" data-bs-target="#physicalQrModal">
            שיתוף{' '}
          </Button>


          <Button
            onClick={() => setShowSection('wall')}
            className={`${showSection === 'wall' && classes.active
              } d-flex align-items-center`}
          >
            פרופיל
            {profile.privacy === 'private' && <AiFillLock className="ms-2" />}
          </Button>
        </div>
      </div>
    </Section>
  );
};

export default ProfileNavigator;

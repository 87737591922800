import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import classes from './MailUnsubscribe.css';
import { userUpdateReceiveEmails } from '../../features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const MailUnsubscribe = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  console.log(user.receiveEmails);

  console.log(user);

  const handleUnsubscribe = async () => {
    console.log(user._id);
    try {
      const response = await dispatch(
        userUpdateReceiveEmails({ userId: user._id, receiveEmails: false })
      );
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    if (user && user._id) {
      handleUnsubscribe();
    }
  }, [user]);

  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | בית חדש לחיים שאחרי</title>
        <meta property="og:url" content="https://lifecloud-qr.com/*" />
      </Helmet>
      <CenterContentLayout bg="#EDF5F9">
        <div className={`container text-center ${classes.error_container}`} style={{ marginTop: '50px' }}>
          <h1>שלום {user?.organizationName} {user?.firstName} {user?.lastName}</h1>
          {user._id ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <h2 style={{ backgroundColor: '#5BC85B', borderRadius: '10px', width: '50%', margin: '10px 0' }}>
                הוסרת מרשימת המיילים בהצלחה
              </h2>
            </div>
          ) : (
            <h2>לא הצלחנו להסיר אותך מרשימת המיילים, נא להתחבר בבקשה.</h2>
          )}

          <Link to="/" className="return-button">
            חזרה
          </Link>
        </div>
      </CenterContentLayout>
    </React.Fragment>
  );
};

export default MailUnsubscribe;

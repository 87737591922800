import React from 'react';
import Section from '../layout/Section';
import classes from '../AllProfiles/AllProfiles.module.css';
import { Link } from 'react-router-dom';

const SubProfiles = ({ profiles, memoryTitle }) => {
  
 const title= memoryTitle? memoryTitle: "נופלי העמותה";
  const allProfiles = [...profiles]; 
  
  allProfiles.sort((a, b) => a.firstName.trim().localeCompare(b.firstName.trim())); 
  
  return (
    <Section
      className={classes.users_profile}
      showCloud={false}
      padding
      bg="#DCECF4"
      gap={false}
    >
      <div className="container" dir="rtl">
        <h2 className="profile_title">{title}</h2>
        {allProfiles.length > 0 ? (
          <div className="row gx-sm-5 gy-5 row-cols-lg-5 row-cols-lg-3 row-cols-2 justify-content-center">
            {allProfiles.map((profile) => (
              <div className="col" key={profile._id}>
                <Link to={`/profile/${profile._id}`}>
                  <div className={`${classes.users_profile_item} text-center`}>
                    <div className="ratio ratio-1x1">
                      <img
                        src={profile.profileImg}
                        alt="user"
                        className={`img-fluid rounded-circle ${classes.subProfile_image}`}
                      />
                    </div>
                    <h3>
                      {profile.firstName} {profile.lastName}
                    </h3>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <h3 className="not_found" dir="rtl">
            עדיין לא נוספו פרופילים לעומתה זו
          </h3>
        )}
      </div>
    </Section>
  );
};

export default SubProfiles;

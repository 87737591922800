import React, { useEffect } from 'react';
import classes from './HeaderNotification.module.css';
import { getMyNotifications } from '../../features/notification/notificationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../features/modal/modalSlice';

const HeaderNotification = ({ className }) => {
  const { notifications } = useSelector((state) => state.notification);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMyNotifications());
  }, [dispatch]);

  const unViewedNotifications = notifications.filter(
    (notification) => !notification.viewed
  ).length;

  return (
    <div
      className="position-relative"
      onClick={() => {
        dispatch(showModal('notificationModal'));
      }}
    >
      <img
        src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1676459039/bell1_2_1_d0mbtw.png"
        alt="Notification Bell"
        className="cursor-pointer"
      />
      {unViewedNotifications > 0 && (
        <p className={classes.notification_count}>{unViewedNotifications}</p>
      )}
    </div>
  );
};

export default HeaderNotification;

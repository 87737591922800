import React, { useState } from 'react';
import classes from './ProfileUpgrade.module.css';
import Section from '../layout/Section';
import Button from '../UI/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateMe } from '../../features/user/userSlice';

const ProfileUpgrade = () => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.user);
  const [useImg, setUseImg] = useState(null);
  const onChangePicture = (event) => {
    if (event.target.files[0]) {
      const src = URL.createObjectURL(event.target.files[0]);
      setUseImg(src);
      const formData = new FormData();
      formData.append('profilePicture', event.target.files[0]);
      dispatch(updateMe(formData));
    }
  };
  return (
    <Section className={classes.profile_upgrade} gap={false} showCloud={false}>
      <div className={classes.bg}></div>
      <div className={classes.profile_upgrade_img}>
        <div className={`position-relative text-center`}>
          <img
            src={useImg || user.profilePicture}
            alt="user"
            className="bg-white"
          />
          <input
            className="position-absolute top-0 start-0 w-100 h-100 opacity-0"
            type="file"
            name="profileImage"
            accept="image/*"
            onChange={onChangePicture}
          />
          {loading && (
            <div
              className={`spinner-grow position-absolute  ${classes.loader}`}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </div>
      </div>
      <div className={classes.profile_content}>
        <h2 dir="rtl">
          שלום {user.organizationName} {user.firstName} {user.lastName}!
        </h2>
        <Button>
          <Link to="/notifications">התראות</Link>
        </Button>
      </div>
    </Section>
  );
};

export default ProfileUpgrade;

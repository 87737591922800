import React, { useEffect } from 'react';
import MainLayout from '../../components/layout/MainLayout';
import SocialFooter from '../../components/SocialFooter/SocialFooter';
import ProfileUpgrade from '../../components/ProfileUpgrade/ProfileUpgrade';
import OrganizationAllProfiles from '../../components/OrganizationAllProfiles/OrganizationAllProfiles';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from '../../features/organizationProfile/organizationProfileSlice';
import Loader from '../../components/Loader/Loader';
import { Helmet } from 'react-helmet-async';

const OrganizationUserProfile = () => {
  const { organizationProfile, organizationProfileLoading } = useSelector(
    (state) => state.organizationProfile
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | בית חדש לחיים שאחרי</title>
        <meta
          property="og:url"
          content="https://lifecloud-qr.com/organization-user-profile"
        />
      </Helmet>
      <MainLayout bg="#fff">
        <ProfileUpgrade />
        {organizationProfileLoading ? (
          <Loader section={true} />
        ) : (
          <OrganizationAllProfiles organizationProfile={organizationProfile} />
        )}
        <SocialFooter cloudPos="down-left" />
      </MainLayout>
    </React.Fragment>
  );
};

export default OrganizationUserProfile;

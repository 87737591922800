import React, { useEffect, useState } from 'react';
import classes from './Blog.module.css';
import MainLayout from '../../components/layout/MainLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBlogs, sendBlogContact } from '../../features/blog/blogSlice';
import Button from '../../components/UI/Button/Button';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import toastCreator from '../../utils/toastifyCreator';

const Blog = () => {
  const { blogs, loading } = useSelector((state) => state.blog);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [selectedTag, setSelectedTag] = useState('כל');
  const [searchText, setSearchText] = useState('');
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const { register, handleSubmit } = useForm();
  useEffect(() => {
    setFilteredBlogs(blogs);
  }, [blogs]);
  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  const handleTagClick = (tag) => {
    if (tag === 'כל') setSearchText('');
    setSelectedTag(tag);
    setFilteredBlogs(
      tag === 'כל'
        ? blogs
        : blogs.filter(
            (blog) => blog.tags.includes(tag) && blog.title.includes(searchText)
          )
    );
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    setFilteredBlogs(
      selectedTag === 'כל'
        ? blogs.filter((blog) => blog.title.includes(e.target.value))
        : blogs.filter(
            (blog) =>
              blog.tags.includes(selectedTag) &&
              blog.title.includes(e.target.value)
          )
    );
  };

  const onSubmit = async (data) => {
    await dispatch(
      sendBlogContact({
        ...data,
        username: user._id ? `${user.firstName} ${user.lastName}` : 'לא רשום ',
        url: window.location.href,
      })
    );

    toastCreator('success', 'קיבלנו את פנייתך ונחזור אלייך בהקדם');
  };
  return (
    <React.Fragment>
      <MainLayout bg="#F5FCFF">
        <img
          src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1673953900/Rectangle_2_5_bty4jw.png"
          className={classes.banner}
          alt="banner"
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-10 mx-auto">
              <div
                className={classes.search_container}
                style={{ transform: 'translateY(-50%)' }}
              >
                <input
                  type="text"
                  placeholder="חיפוש כתבה:"
                  className="form-control"
                  onChange={handleSearch}
                  value={searchText}
                />
              </div>
            </div>
          </div>

          <div className={classes.menu_container}>
            <h2 className="profile_title">בלוג</h2>
            <div className={classes.menu} dir="rtl">
              <ul className="d-flex justify-content-center align-items-center flex-wrap gap-md-4 gap-2">
                <li>
                  <span>ניווט לפי קטגוריות:</span>
                </li>
                <li>
                  <button
                    className={`btn ${selectedTag === 'כל' && classes.active}`}
                    onClick={() => handleTagClick('כל')}
                  >
                    כל
                  </button>
                </li>
                <li>
                  <button
                    className={`btn ${
                      selectedTag === 'הנצחה' && classes.active
                    }`}
                    onClick={() => handleTagClick('הנצחה')}
                  >
                    הנצחה
                  </button>
                </li>
                <li>
                  <button
                    className={`btn ${
                      selectedTag === 'קבורה' && classes.active
                    }`}
                    onClick={() => handleTagClick('קבורה')}
                  >
                    קבורה
                  </button>
                </li>
                <li>
                  <button
                    className={`btn ${
                      selectedTag === 'אזכרה' && classes.active
                    }`}
                    onClick={() => handleTagClick('אזכרה')}
                  >
                    אזכרה
                  </button>
                </li>
                <li>
                  <button
                    className={`btn ${
                      selectedTag === 'חדשות' && classes.active
                    }`}
                    onClick={() => handleTagClick('חדשות')}
                  >
                    חדשות
                  </button>
                </li>
                <li>
                  <button
                    className={`btn ${
                      selectedTag === 'עיתונות' && classes.active
                    }`}
                    onClick={() => handleTagClick('עיתונות')}
                  >
                    עיתונות
                  </button>
                </li>
                <li>
                  <button
                    className={`btn ${
                      selectedTag === 'פרופיל' && classes.active
                    }`}
                    onClick={() => handleTagClick('פרופיל')}
                  >
                    פרופיל
                  </button>
                </li>
              </ul>
              <hr />
            </div>
          </div>

          <div
            className={`row gx-sm-5 justify-content-center ${
              filteredBlogs.length > 0 && 'gy-5'
            }`}
          >
            {filteredBlogs.map((blog) => (
              <div className="col-lg-4 col-sm-6" key={blog._id}>
                <Link to={`/blog/${blog._id}`}>
                  <div className={`card text-center h-100 ${classes.card}`}>
                    <img
                      src={blog.blogImg}
                      className="card-img-top"
                      alt={blog.title}
                    />
                    <div
                      className={`card-body d-flex flex-column justify-content-between ${classes.card_body}`}
                    >
                      <div>
                        <h5 className="card-title">{blog.title}</h5>
                        <hr />
                        <p className="card-text">{blog.description}</p>
                      </div>

                      <div>
                        <button className="btn">קרא עוד</button>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          <div className="row">
            <div className={`col-lg-8 mx-auto`}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={`d-flex justify-content-center algn-items-center ${classes.contact_container} gap-4`}
              >
                <div className={classes.contact_item}>
                  <Button loading={loading} type="submit">
                    שלח
                  </Button>
                </div>
                <div className={classes.contact_item}>
                  <input
                    type="text"
                    placeholder="טלפון"
                    className="form-control"
                    {...register('phone')}
                  />
                </div>
                <div className={classes.contact_item}>
                  <input
                    type="text"
                    placeholder="מייל"
                    className="form-control"
                    {...register('email')}
                  />
                </div>
                <div className={classes.contact_item}>
                  <input
                    type="text"
                    placeholder="שם מלא"
                    className="form-control"
                    {...register('fullName')}
                  />
                </div>
                <p>צור קשר:</p>
              </form>
            </div>
          </div>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default Blog;

import React from 'react';
import classes from './ProfileInfo.module.css';
import Section from '../layout/Section';
import moment from 'moment';
import { Link } from 'react-router-dom';
const ProfileInfo = ({ profile }) => {
  return (
    <Section
      className={`${classes.profile_info} pb-5`}
      bg="transparent"
      gap={false}
      showCloud={false}
    >
      <div className="container">
        <div className={`text-center ${classes.content}`}>
          <div className={classes.profile_info_image}>
            <img
              src={profile.profileImg}
              alt={`${profile.firstName} ${profile.lastName}`}
            />
          </div>
          {profile.organizationName ? (
            <div className={classes.profile_info_texts}>
              <h2>{profile.organizationName}</h2>
            </div>
          ) : (
            <div className={classes.profile_info_texts}>
              <h2>
                {`${profile.degree} ${profile.firstName}  ${profile.lastName}`}
              </h2>
              <div className="d-flex justify-content-center align-items-center gap-md-3 gap-2">
                <h4>{profile.city}</h4>
                <span className={classes.span}>|</span>
                <h3>
                  {profile.deathDate &&
                    moment(profile.deathDate).format('DD.MM.YYYY')}{' '}
                  -{' '}
                  {profile.birthDate &&
                    moment(profile.birthDate).format('DD.MM.YYYY')}
                </h3>
              </div>
            </div>
          )}
        </div>
        {profile.organization?.length > 0 && (
          <div
            className={`d-flex justify-content-center align-items-center gap-3 mt-md-5 mt-3 ${classes.organization_container} flex-wrap-reverse`}
          >
            {profile.organization.map((org) => (
              <Link
                to={`/organization-profile/${org._id}`}
                className={`d-flex justify-content-center align-items-center gap-3 ${classes.organization_info}`}
                key={org._id}
              >
                <p>{org.organizationName}</p>
                <img src={org.profileImg} alt="association" />
              </Link>
            ))}
          </div>
        )}
      </div>
    </Section>
  );
};
export default ProfileInfo;

const gregorianToHebYear = (hebYear) => {
  switch (hebYear) {
    case `5782`:
      return `תשפ"ב`;
    case `5783`:
      return `תשפ"ג`;
    case `5784`:
      return `תשפ"ד`;
    case `5785`:
      return `תשפ"ה`;
    case `5786`:
      return `תשפ"ו`;
    case `5787`:
      return `תשפ"ז`;
    case `5788`:
      return `תשפ"ח`;
    case `5789`:
      return `תשפ"ט`;
    case `5790`:
      return `תש"צ`;

    default:
      return null;
  }
};

const gregorianToHebMonth = (hebMonth) => {
  switch (hebMonth) {
    case `Tishrei`:
      return 'תשרי';
    case `Cheshvan`:
      return 'חשון';
    case `Kislev`:
      return 'כסלו';
    case `Tevet`:
      return 'טבת';
    case `Sh'vat`:
      return 'שבט';
    case `Adar`:
      return 'אדר';
    case `Adar II`:
      return "אדר ב'";
    case `Nisan`:
      return 'ניסן';
    case `Iyyar`:
      return 'אייר';
    case `Sivan`:
      return 'סיון';
    case `Tamuz`:
      return 'תמוז';
    case `Av`:
      return 'אב';
    case `Elul`:
      return 'אלול';

    default:
      return null;
  }
};
const gregorianToHebDay = (hebDay) => {
  switch (hebDay) {
    case '1':
      return `א'`;
    case '2':
      return `ב'`;
    case '3':
      return `ג'`;
    case '4':
      return `ד'`;
    case '5':
      return `ה'`;
    case '6':
      return `ו'`;
    case '7':
      return `ז'`;
    case '8':
      return `ח'`;
    case '9':
      return `ט'`;
    case '10':
      return `י'`;
    case '11':
      return `יא'`;
    case '12':
      return `יב'`;
    case '13':
      return `י״ג`;
    case '14':
      return `יד'`;
    case '15':
      return `ט"ו`;
    case '16':
      return `ט"ז`;
    case '17':
      return `יז'`;
    case '18':
      return `יח'`;
    case '19':
      return `יט'`;
    case '20':
      return `כ'`;
    case '21':
      return `כ"א`;
    case '22':
      return `כ"ב`;
    case '23':
      return `כ"ג`;
    case '24':
      return `כ"ד`;
    case '25':
      return `כ"ה`;
    case '26':
      return `כ"ו`;
    case '27':
      return `כ"ז`;
    case '28':
      return `כ"ח`;
    case '29':
      return `כ"ט`;
    case '30':
      return `ל'`;
    case '31':
      return `ל"א`;
    case '32':
      return `ל"ב`;
    case '33':
      return `ל"ג`;
    default:
      return null;
  }
};
export { gregorianToHebYear, gregorianToHebMonth, gregorianToHebDay };

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createCfComment } from '../../features/candleFlower/candleFlowerSlice';
import toastCreator from '../../utils/toastifyCreator';
import Button from '../UI/Button/Button';
import classes from './CfCommentForm.module.css';
const CfCommentForm = ({ showCommentForm, setShowCommentForm, cfComment }) => {
  const [text, setText] = useState('');
  const dispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = { text, ...cfComment };
    await dispatch(createCfComment(formData));
    toastCreator('success', 'תודה על התגובה');
    setShowCommentForm(false);
    setText('');
  };
  return (
    <React.Fragment>
      {showCommentForm && (
        <React.Fragment>
          <div
            className={classes.overlay}
            onClick={() => setShowCommentForm(false)}
          ></div>
          <div className={classes.share_container}>
            <h3 className="text-center">השאירו תגובתכם</h3>
            <form onSubmit={onSubmit} className="p-5">
              <input
                type="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="form-control"
              />
              <Button className="mt-3">פרסום</Button>
            </form>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CfCommentForm;

import React from 'react';
import { CreateMemoryBody } from '../pages/CreateMemory/CreateMemory';
import classes from './CreateMemoryModal.module.css';
import cross from '../assets/candleflower-modal-img/x.png';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '../features/modal/modalSlice';
import { Modal } from 'react-bootstrap';

import { showModal } from '../features/modal/modalSlice';

const CreateMemoryModal = ({ profileId, modalAction }) => {
  
  const { createMemoryModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  return (

        <React.Fragment>
      <Modal
        show={modalAction.show}
        onHide={() => modalAction.set(false)}
        size="md"
        // id="createMemoryModal"
        // aria-labelledby="createMemoryModalLabel"
        contentClassName={classes.modal_content}
        className={` ${classes.guestCFModal}`}
        centered
      >
    <div
      // className="modal fade"
      // id="createMemoryModal"
      // tabIndex="-1"
      // aria-labelledby="createMemoryModalLabel"
      // aria-hidden="true"
    >
    
      <div className="modal-dialog modal-dialog-centered">
        <div className={`modal-content ${classes.modal_content}`}>
          <div className="modal-header justify-content-between">
            <h5
              className={`${classes.modal_title} m-auto`}
              id="candleFlowerLabel"
            >
              שתפו חוויות וזכרונות
            </h5>
            <button
              type="button"
              className={`${classes.btn_close}  btn align-self-start`}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => modalAction.set(false)}
            >
              <img src={cross} alt="Close" />
            </button>
          </div>
          <div className={`modal-body ${classes.create_memory_modal_body}`}>
            <CreateMemoryBody profileId={profileId} />
          </div>
        </div>
      </div>
    </div>
    
          </Modal>
    </React.Fragment>
  );
};

export default CreateMemoryModal;

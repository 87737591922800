import React, { useEffect } from 'react';
import MainLayout from '../../components/layout/MainLayout';
import classes from './Blog.module.css';
import cross from '../../assets/candleflower-modal-img/x.png';
import { useDispatch, useSelector } from 'react-redux';
import { getBlog } from '../../features/blog/blogSlice';
import { Link, useParams } from 'react-router-dom';
import parse from 'html-react-parser';

const BlogPost = () => {
  const { blogId } = useParams();
  const { blog } = useSelector((state) => state.blog);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBlog(blogId));
  }, [blogId, dispatch]);

  return (
    <React.Fragment>
      <MainLayout bg="#F5FCFF">
        <div className="container py-md-5 py-3">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <Link className={classes.cross_container} to="/blog">
                <img src={cross} alt="Close" />
              </Link>

              <div
                className={`card text-center ${classes.card} ${classes.blog_post_card}`}
              >
                <img src={blog.blogImg} className="card-img-top" alt="blog" />
                <div className={`card-body ${classes.card_body}`}>
                  <h5 className="card-title">{blog.title}</h5>
                  <hr />
                  {blog.content?.map((item, index) => (
                    <React.Fragment key={index}>
                      <p className="card-text"> {parse(item.paragraph)}</p>
                      {item.btnLink && item.btnText && (
                        <a href={item.btnLink}>
                          <button className="btn">{item.btnText}</button>
                        </a>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`col-xl-8 mx-auto ${classes.similar_blog_post}`}>
              <div>
                <h5 className={classes.similar_title}>צפייה בכתבות דומות...</h5>
                <hr className={classes.hr} />
              </div>

              <div className="row mb-5 gx-sm-5 gy-md-5 gy-4 mt-5">
                {blog.relatedPosts?.map((blog) => (
                  <div className="col-lg-4 col-sm-6" key={blog._id}>
                    <Link to={`/blog/${blog._id}`}>
                      <div className={`card text-center h-100 ${classes.card}`}>
                        <img
                          src={blog.blogImg}
                          className="card-img-top"
                          alt={blog.title}
                        />
                        <div
                          className={`card-body d-flex flex-column justify-content-between ${classes.card_body}`}
                        >
                          <div>
                            <h5 className="card-title">{blog.title}</h5>
                            <hr />
                            <p className="card-text">{blog.description}</p>
                          </div>

                          <div>
                            <button className="btn">קרא עוד</button>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default BlogPost;

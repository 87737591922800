import React from 'react';
import { LoginBody } from '../pages/Auth/Login';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '../features/modal/modalSlice';
const LoginModal = () => {
  const { loginModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  return (
    <Modal
      show={loginModal}
      onHide={() => dispatch(hideModal('loginModal'))}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="auth-modal"
    >
      <Modal.Header closeButton style={{ border: 0 }}>
        
      </Modal.Header>
      <Modal.Body>
        <LoginBody modal />
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import CenterContentLayout from '../../../components/layout/CenterContentLayout';
import classes from '../Error.module.css';
const NotFound = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | בית חדש לחיים שאחרי</title>
        <meta property="og:url" content="https://lifecloud-qr.com/*" />
      </Helmet>
      <CenterContentLayout bg="#EDF5F9">
        <div className={`container text-center ${classes.error_container}`}>
          <h1>404</h1>
          <h3>קרתה תקלה :( עובדים על פיתרון</h3>
          <Link to="/">חזרה</Link>
        </div>
      </CenterContentLayout>
    </React.Fragment>
  );
};

export default NotFound;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { showModal } from '../../features/modal/modalSlice';
import CFOptionModal from '../../modals/CFOptionModal';
import classes from './CFWidget.module.css';
import lifeAxios from '../../utils/lifeAxios';
import { createFundedCandleFlower, getCandlesFlowers } from '../../features/candleFlower/candleFlowerSlice';


const CFWidget = () =>
{
  const { candlesFlowers } = useSelector((state) => state.candleFlower);
  const dispatch = useDispatch();

  const total = candlesFlowers
    .flatMap((obj) => obj.candlesFlowers)
    .reduce((acc, curr) => acc + curr.count, 0);

  const { user } = useSelector((state) => state.user);
  const { profile } = useSelector((state) => state.profile);

  const [showCFOptionsModal, setShowCFOptionsModal] = useState(false);


  const handleFormSubmit = async (event, candleFlowerState, dedication) =>
  {
    event.preventDefault();

    if (!user._id) return dispatch(showModal('guestCFPurchaseModal'));

    if (profile.funded)
    {
      // if (!user._id) return dispatch(showModal('loginModal'));
      const funder = profile.funder;
      // console.log(funder, candleFlowerState.filter((cf) => cf.count > 0));
      const formData = {
        user: user._id,
        profile: profile._id,
        candlesFlowers: candleFlowerState,
        dedication: dedication,
      };
      const creationResponse = await dispatch(createFundedCandleFlower(formData));

      if (creationResponse.meta.requestStatus === "fulfilled");
      {
        dispatch(getCandlesFlowers(profile._id));
        setShowCFOptionsModal(false);
        document.getElementById('virtual-memory').scrollIntoView();
      }
      return;
    }

    // term for checking wether the user is Registered or not

    const totalPrice = candleFlowerState.reduce(
      (acc, item) => acc + item.count * 5,
      0
    );
    localStorage.setItem('profileId', profile._id);

    const selectedCFs = candleFlowerState.filter((cf) => cf.count > 0);
    const queryString = encodeURIComponent(
      JSON.stringify(selectedCFs)
    );

    const objectId = (await lifeAxios(localStorage.getItem('jwt')).post(
      `/purchases/create`,
      {
        candlesFlowers: selectedCFs,
        profile: profile._id,
        user: user._id,
        contact: user.organizationName || user.firstName,
        email: user.email,
        sum: totalPrice,
        dedication: dedication
      }
    )).data.data.data;

    window.location.assign(
      `https://direct.tranzila.com/icloud/iframenew.php?supplier=icloud&template=custom&u71=1&candlesFlowers=${queryString}&sum=${totalPrice}&currency=1&contact=${user.organizationName || user.firstName
      }&email=${user.email}&user=${user._id
      }&tranmode=A&pdesc=CandleOrFlower&type_code=320&tamal_lang=il&profile=${profile._id
      }&doc_remark=docremark&dedication=${dedication}&purchase=${objectId}&ppnewwin=0`
    );
  };
  return (
    <React.Fragment>
      <div
        className={`d-flex align-items-center ${classes.widget_container}`}
        role="button"
      >
        <button
          className={classes.widget_left}
          // data-bs-toggle="modal"
          // data-bs-target="#cfOption"
          onClick={() => { setShowCFOptionsModal(true) }}
        >
          הדלק נר ו/או הנח פרח!
        </button>
        <div className={classes.widget_right}>
          <img
            src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1677295372/candle_02_g22yft.jpg"
            alt="Candle/Flower"
            // data-bs-toggle="modal"
            // data-bs-target="#cfOption"
            onClick={() => { setShowCFOptionsModal(true) }}
          />
          <HashLink to="#virtual-memory">
            <div
              className={`d-flex justify-content-center align-items-center ${classes.cf_count}`}
            >
              <span>+{total}</span>
            </div>
          </HashLink>
        </div>
      </div>{' '}
      <CFOptionModal handleFormSubmit={handleFormSubmit} showCFOptionsModal={showCFOptionsModal} setShowCFOptionsModal={setShowCFOptionsModal} profile={profile} />
    </React.Fragment>
  );
};

export default CFWidget;

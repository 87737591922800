import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '../features/modal/modalSlice';
import { RegistrationBody } from '../pages/Auth/Registration';
const RegistrationModal = () => {
  const { registrationModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  return (
    <Modal
      show={registrationModal}
      onHide={() => dispatch(hideModal('registrationModal'))}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="auth-modal"
    >
      <Modal.Header closeButton style={{ border: 0 }}></Modal.Header>
      <Modal.Body>
        <RegistrationBody modal />
      </Modal.Body>
    </Modal>
  );
};

export default RegistrationModal;

import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import classes from './ProfileDetails.module.css';
import { useParams } from 'react-router-dom';
import Gallery from '../../components/Gallery/Gallery';
import MainLayout from '../../components/layout/MainLayout';
import Memorial from '../../components/Memorial/Memorial';
import MemoryList from '../../components/MemoryList/MemoryList';
import ProfileCover from '../../components/ProfileCover/ProfileCover';
import ProfileDescription from '../../components/ProfileDescription/ProfileDescription';
import ProfileInfo from '../../components/ProfileInfo/ProfileInfo';
import ProfileLifeAxis from '../../components/ProfileLifeAxis/ProfileLifeAxis';
import ProfileNavigator from '../../components/ProfileNavigator/ProfileNavigator';
import WallFriend from '../../components/WallFriend/WallFriend';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from '../../features/profile/profileSlice';
import Loader from '../../components/Loader/Loader';
import { getProfileMemories } from '../../features/memory/memorySlice';
import { useState } from 'react';
import PhysicalQrModal from '../../modals/PhysicalQrModal';
import SocialFooter from '../../components/SocialFooter/SocialFooter';
import VirtualMemory from '../../components/VirtualMemory/VirtualMemory';
import GalleryGrid from '../../components/Gallery/GalleryGrid';
import { Helmet } from 'react-helmet-async';
import emailTextAndSubject from '../../utils/emailTextAndSubject';
import { createNotification } from '../../features/notification/notificationSlice';
import CreateMemoryModal from '../../modals/CreateMemoryModal';
import MemorialDayModal from '../../modals/MemorialDayModal';
import FacebookShareModal from '../../modals/FacebookShareModal';
import CFNotificationModal from '../../modals/CFNotificationModal';
import { getCandlesFlowers } from '../../features/candleFlower/candleFlowerSlice';
import CFWidget from '../../components/CFWidget/CFWidget';
import { createProfileVisit } from '../../features/profile/profileSlice';
import moment from "moment";

const ProfileDetails = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
          if(window.location.hash) {
            // window.history.replaceState("", document.title, window.location.pathname);
            }
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change
  
  
  /* Hide or Show section according to the state value */
  const [showSection, setShowSection] = useState('wall');
  const dispatch = useDispatch();
  /* Profile Id (Getting from URL) */
  const { profileId } = useParams();
  /* Redux State */
  const { profile } = useSelector((state) => state.profile);
  const { user } = useSelector((state) => state.user);
  const { memories } = useSelector((state) => state.memory);
  /* Get the profile details accordion to the profile id */
  useEffect(() => {
    dispatch(getProfile(profileId));
  }, [dispatch, profileId]);
  /* Get all memories of that profile */
  useEffect(() => {
    dispatch(getProfileMemories(profileId));
  }, [dispatch, profileId]);
  /* Get candles and flowers for profile */
  useEffect(() => {
    dispatch(getCandlesFlowers(profileId));
  }, [dispatch, profileId]);
  useEffect(() => {
    if (!profile._id || !user._id) return;
    const receivers = [
      ...profile.profileAdmins
        .filter((admin) => admin._id !== user._id)
        .map((admin) => admin._id),
    ];
    if (profile.originalUser._id !== user._id)
      receivers.push(profile.originalUser._id);

    const notification = {
      ...emailTextAndSubject(
        'profileVisit',
        {
          organizationName: profile.organizationName,
          firstName: profile.firstName,
          lastName: profile.lastName,
        },
        {
          organizationName: user.organizationName,
          firstName: user.firstName,
          lastName: user.lastName,
        }
      ),
      creator: user._id,
      receivers: receivers,
      url: window.location.href,
    };
    // dispatch(createNotification(notification)); //TODO: Server notifications
    
    const visitKey = `lastVisit${profile._id}`;
    
    if(!localStorage.getItem(visitKey))
    {
          dispatch(createProfileVisit(profile._id));
          localStorage.setItem(`lastVisit${profile._id}`,moment().toISOString());
    }
    else
    {
      const now = moment();
      const lastTime=moment(localStorage.getItem(visitKey));
      if((now.diff(lastTime,'hours')>=24))
      {
          dispatch(createProfileVisit(profile._id));
          localStorage.setItem(`lastVisit${profile._id}`,moment().toISOString());
      }
    }
 
     
     
  }, [dispatch, profile, user]);
  /* If profile not loaded then loading spinner will show */
  if (!profile._id) return <Loader />;
  /* Check if the current login user is the owner or admin of visited profile */
  const isOwnerOrAdmin =
    profile.originalUser._id === user._id ||
    profile.profileAdmins.find((admin) => admin._id === user._id);
  const isFriend = profile.profileFriends.find(
    (friend) => friend._id === user._id
  );
  const pUrl = `${window.location.protocol}//${window.location.host}/profile/${profileId}`;
  return (
    <React.Fragment>
      <Helmet>
        <title>
          LifeCloud | {profile.firstName} {profile.lastName} ז"ל
        </title>
        <meta
          name="description"
          content={`זה הוא סיפור החיים של ${profile.firstName}, הכל התחיל בשנת ${profile.birthYear} והתרחש ב${profile.city}. בעמוד תוכלו למצוא תמונות וזכרונות מהחיים ששותפו על ידי המשפחה וחברים. אירועים ואזכרה.`}
        />
        <meta
          property="og:url"
          content={`https://lifecloud-qr.com/profile/${profileId}`}
        />
        <meta
          property="og:title"
          content={`זוכרים את ${profile.firstName} ${profile.lastName} | Lifecloud-qr.com`}
        />
        <meta
          property="og:description"
          content={`הכנסו לחלוק כבוד ל ${profile.firstName}, סיפור החיים של ${profile.firstName} התחיל בשנת ${profile.birthYear} והתרחש ב${profile.city}. בעמוד תוכלו למצוא תמונות וזכרונות ששותפו על ידי המשפחה וחברים. אירועים ואזכרה.`}
        />
        <meta property="og:image" content={profile.wallImg} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <link rel="canonical" href={pUrl} />
      </Helmet>
      <MainLayout bg="#fff" footerClassName={classes.footer}>
        <ProfileCover
        isOwnerOrAdmin={isOwnerOrAdmin}
          profileId={profile._id}
          wallImg={profile.wallImg}
          objectYPos={profile.objectYPos}
        />
        <ProfileInfo profile={profile} />
        <ProfileNavigator
          showSection={showSection}
          setShowSection={setShowSection}
          isFriend={isFriend}
          isOwnerOrAdmin={isOwnerOrAdmin}
          profile={profile}
          user={user}
        />
        {(profile.privacy === 'public' || isOwnerOrAdmin || isFriend) && (
          <React.Fragment>
            {showSection === 'gallery' && (
              <GalleryGrid
                gallery={profile.gallery}
                //galleryProperties={profile.galleryProperties}
                setShowSection={setShowSection}
              />
            )}
            {showSection === 'wall' && (
              <React.Fragment>
                <Memorial
                  profile={profile}
                  isOwnerOrAdmin={isOwnerOrAdmin}
                  isFriend={isFriend}
                />
                <ProfileDescription description={profile.description} />
                <ProfileLifeAxis profile={profile} />
                <Gallery
                  gallery={profile.gallery}
                  //galleryProperties={profile.galleryProperties}
                  setShowSection={setShowSection}
                />

                <MemoryList
                  memories={memories}
                  profileId={profileId}
                  isOwnerOrAdmin={isOwnerOrAdmin}
                  isFriend={isFriend}
                />

                <VirtualMemory
                  profileId={profile._id}
                  profileImg={profile.profileImg}
                />
              </React.Fragment>
         
            )}

            {showSection === 'wallFriend' && (
              <WallFriend
                profile={profile}
                setShowSection={setShowSection}
                user={user}
                isOwnerOrAdmin={isOwnerOrAdmin}
              />
            )}
          </React.Fragment>
        )}
        <SocialFooter cloudPos="down-right" />
        <PhysicalQrModal user={user} profile={profile} />
        {/*
        <CreateMemoryModal profileId={profileId} />
        */}
        <CFWidget />
        <MemorialDayModal profile={profile} isOwnerOrAdmin={isOwnerOrAdmin} />
        <CFNotificationModal profileId={profileId} userId={user._id} />
        <FacebookShareModal />
      </MainLayout>
    </React.Fragment>
  );
};

export default ProfileDetails;

import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import memoryReducer from '../features/memory/memorySlice';
import profileReducer from '../features/profile/profileSlice';
import candleFlowerReducer from '../features/candleFlower/candleFlowerSlice';
import notificationReducer from '../features/notification/notificationSlice';
import contactReducer from '../features/contact/contactSlice';
import organizationProfileReducer from '../features/organizationProfile/organizationProfileSlice';
import modalSlice from '../features/modal/modalSlice';
import blogSlice from '../features/blog/blogSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    memory: memoryReducer,
    profile: profileReducer,
    candleFlower: candleFlowerReducer,
    notification: notificationReducer,
    contact: contactReducer,
    organizationProfile: organizationProfileReducer,
    modal: modalSlice,
    blog: blogSlice,
  },
});

export default store;

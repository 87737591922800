import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
/* Custom Components */
import Button from '../../components/UI/Button/Button';
import SocialLogin from '../../components/SocialLogin/SocialLogin';
import { userSignup } from '../../features/user/userSlice';
import toastCreator from '../../utils/toastifyCreator';
import classes from './Auth.module.css';
/* import alertCreator from '../../utils/alertCreator'; */
import InputError from '../../components/InputError/InputError';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import { hideModal, showModal } from '../../features/modal/modalSlice';
//Form validation schema
const schema = yup.object().shape({
  firstName: yup.string().required('!שם פרטי הינו שדה חובה*'),
  lastName: yup.string().required('!שם משפחה הינו שדה חובה*'),
  phone: yup.string().required('!מספר טלפון הוא שדה חובה*'),
  gender: yup.string().required('!נדרש מגדר*'),
  email: yup
    .string()
    .email('!המייל שהוזן אינו תקין*')
    .required('!מייל הינו שדה חובה*'),
  password: yup
    .string()
    .required('!דרושה סיסמא*')
    .min(6, '!הסיסמא צריכה להכיל לפחות 6 תווים*'),
});

const Registration = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | הרשמה לאתר</title>
        <meta property="og:title" content="LifeCloud | הרשמה לאתר" />
        <meta
          property="og:url"
          content="https://lifecloud-qr.com/registration"
        />
        <meta
          name="description"
          content="על מנת להשתמש באתר ולרגש את המשפחה וחברים, יש למלא את טופס ההרשמה. אנו זמינים לסייע במידה ותהליך ההרשמה לא הלך חלק."
        />
        <meta
          property="og:description"
          content="על מנת להשתמש באתר ולרגש את המשפחה וחברים, יש למלא את טופס ההרשמה. אנו זמינים לסייע במידה ותהליך ההרשמה לא הלך חלק."
        />
      </Helmet>
      <CenterContentLayout cloudPos="right-left">
        <RegistrationBody />
      </CenterContentLayout>
    </React.Fragment>
  );
};
export const RegistrationBody = ({ modal }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const [termsChecked, setTermsChecked] = useState(false);
  const [emailsChecked, setEmailsChecked] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const hideAuthModal = () => {
    dispatch(hideModal('registrationModal'));
  };
  /* Show Notification */
  const showNotification = (status, payload) => {
    if (status === 'error') return toastCreator('error', payload);
    hideAuthModal();
    navigate(location.pathname);
    /*  alertCreator({
      icon: 'success',
      title: 'Registration Successful',
      text: 'Please check your mail to activate account!',
    }).then(() => {
      navigate('/login');
    }); */
  };
  /* Form Submit Handler */
  const onSubmit = async (formData) => {
    formData.passwordConfirm = formData.password;
    dispatch(userSignup({ formData, showNotification }));
  };

  /* Genders */
  const radioOptions = [
    {
      value: 'other',
      name: 'other',
      label: 'אחר',
    },

    {
      value: 'female',
      name: 'female',
      label: 'נ',
    },
    {
      value: 'male',
      name: 'male',
      label: 'ז',
    },
  ];

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className={`${modal ? 'col-12' : 'col-xxl-10 mx-auto'}`}>
            {!modal && (
              <React.Fragment>
                <h2 className="page_title">ברוכים הבאים</h2>
                <h3 className={classes.login_subtitle}>
                  על מנת לבצע פעולות באתר ולרגש אחרים, יש להתחבר או להרשם
                </h3>
              </React.Fragment>
            )}
            <div className={`row ${classes.form}`}>
              <div
                className={`col-xl-3 col-lg-4 order-lg-1 order-2 ${classes.left_panel}`}
              >
                <div className="d-flex flex-column justify-content-between h-100">
                  <div>
                    <h3 dir="rtl">כבר משתמשים רשומים?</h3>
                    {modal ? (
                      <Button
                        className={`w-100 ${classes.form_btn}`}
                        onClick={() => {
                          dispatch(showModal('loginModal'));
                        }}
                      >
                        להתחברות
                      </Button>
                    ) : (
                      <Link to="/login">
                        <Button className={`w-100 ${classes.form_btn}`}>
                          להתחברות
                        </Button>
                      </Link>
                    )}
                  </div>
                  <hr />
                  <div className={classes.need_help}>
                    <h4 dir="rtl">צריכים עזרה?</h4>
                    <h5 dir="rtl">כמה קישורים שיוכלו לעזור!</h5>
                    {modal ? (
                      <span
                        onClick={() => {
                          dispatch(showModal('forgotPasswordModal'));
                        }}
                      >
                        שכחתי את הסיסמא
                      </span>
                    ) : (
                      <Link to="/forgot-password" onClick={hideAuthModal}>
                        שכחתי את הסיסמא
                      </Link>
                    )}
                    <Link to="/contact" onClick={hideAuthModal}>
                      יצירת קשר לקבלת סיוע
                    </Link>
                    <Link to="/faq" onClick={hideAuthModal}>
                      שאלות ותשובות נפוצות
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className={`col-xl-9 col-lg-8 order-lg-2 order-1 ${classes.right_panel}`}
              >
                <h2>הרשמת משתמשים חדשים</h2>
                <SocialLogin />
                <hr />
                <h4>או התחברו באמצעות אימייל וסיסמא</h4>​
                <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-md-6">
                    <div className={classes.input_container}>
                      <input
                        {...register('lastName')}
                        placeholder="*שם משפחה..."
                        className={`form-control ${classes.input}`}
                      />
                      <InputError error={errors.lastName?.message} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={classes.input_container}>
                      <input
                        {...register('firstName')}
                        placeholder="*שם פרטי..."
                        className={`form-control ${classes.input}`}
                      />
                      <InputError error={errors.firstName?.message} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={classes.input_container}>
                      <input
                        {...register('phone')}
                        placeholder="*טלפון..."
                        className={`form-control ${classes.input}`}
                      />
                      <InputError error={errors.phone?.message} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={classes.input_container}>
                      <div className={classes.radio_container}>
                        {radioOptions.map((option, index) => (
                          <div className={classes.radio_input} key={index}>
                            <input
                              type="radio"
                              id={option.value}
                              className={classes.radio}
                              value={option.value}
                              {...register('gender', { required: true })}
                            />
                            <label htmlFor={option.value}>{option.label}</label>
                          </div>
                        ))}
                        <h3 className={classes.radio_label}>: מין*</h3>
                      </div>

                      <InputError
                        error={errors.gender?.message}
                        message="!נדרש מגדר*"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={classes.input_container}>
                      <input
                        type="password"
                        {...register('password')}
                        placeholder="*בחרו סיסמא..."
                        className={`form-control ${classes.input}`}
                      />

                      <InputError error={errors.password?.message} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={classes.input_container}>
                      <input
                        type="email"
                        {...register('email')}
                        placeholder="*מייל..."
                        className={`form-control ${classes.input}`}
                      />
                      <InputError error={errors.email?.message} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className={`form-check d-flex justify-content-center align-items-center ${classes.terms_checkbox}`}
                    >
                      <label
                        className={`form-check-label text-center ${classes.form_footer_text}`}
                        htmlFor="terms"
                      >
                        <Link
                          to="/privacy"
                          className={classes.form_footer_text}
                        >
                          אני מאשר.ת קבלת מיילים
                        </Link>
                      </label>
                      <input
                        className={`form-check-input text-center ${classes.check_box}`}
                        type="checkbox"
                        {...register('receiveEmails')}
                        checked={emailsChecked}
                        onChange={(e) => setEmailsChecked(e.target.checked)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className={`form-check d-flex justify-content-center align-items-center ${classes.terms_checkbox}`}
                    >
                      <label
                        className={`form-check-label text-center ${classes.form_footer_text}`}
                        htmlFor="terms"
                      >
                        <Link to="/policy" className={classes.form_footer_text}>
                          אני מאשר.ת תנאי השימוש והפרטיות
                        </Link>
                      </label>
                      <input
                        className={`form-check-input text-center ${classes.check_box}`}
                        type="checkbox"
                        value="terms"
                        onChange={(e) => setTermsChecked(e.target.checked)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Button
                      className={`w-100 ${classes.submit_btn}`}
                      disabled={!termsChecked}
                      loading={loading}
                    >
                      אישור
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Registration;

import React, { useEffect, useState, useCallback } from 'react';
import Button from '../../../components/UI/Button/Button';
import classes from './MemoryListDesktop.module.css';
import Section from '../../layout/Section';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import getFileType from '../../../utils/getFileType';
import { useSelector, useDispatch } from 'react-redux';
import { showModal } from '../../../features/modal/modalSlice';
import CreateMemoryModal from '../../../modals/CreateMemoryModal';
import moment from 'moment';
import VideoThumbnail from 'react-video-thumbnail'; 
import { FaPlay } from 'react-icons/fa';


const Player = ({ url }) => {
  const imgUrl = url.split('.').slice(0, -1).join('.') + '.jpg'; //replace last part after dot with jpg
  return <img src={imgUrl} alt="Video Thumbnail" width={260} height={260} />;
};

// const Player = ({ url }) =>
// {
//   return  <VideoThumbnail
//     videoUrl={url}
//     thumbnailHandler={(thumbnail) => console.log(thumbnail)}
//     width={260}
//     height={260}
//     /> 
// }

const MemoryList = ({ memories, profileId, isOwnerOrAdmin, isFriend }) =>
{
  const [showAll, setShowAll] = useState(false);
  const memoriesList = showAll ? memories : memories.slice(0, 4);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  
  const [memoryModal, setMemoryModal] = useState(false);

  return (
    <Section
      className={`${classes.memory_list} position-relative`}
      bg="#dcecf4"
      cloudPos="down-right"
      gap={false}
      padding
      id="memory"
    >
      <div className="container">
        <h2 className="profile_title">זכרונות</h2>
        {memoriesList.length > 0 ? (
          <div className="row gx-4 gx-lg-5 gy-4 gy-md-0 justify-content-center">
            {memoriesList.map((memory) =>
            {
              return (
                <div className="col-xl-3 col-md-4 col-6" key={memory._id}>
                  <Link to={`/memory/${memory._id}`}>
                    <div className={classes.memory_item}>
                      <div className="position-relative text-center">
                        {getFileType(memory.media) === 'image' && (
                          <img
                            src={memory.media}
                            alt="memory"
                            className={classes.media}
                          />
                        )}
                        {getFileType(memory.media) === 'video' && (
                          <div className={classes.media}>
                            <Player url={memory.media} />
                          </div>
                        )}
                        <div
                          className={`position-absolute w-100 text-center ${classes.social_icons}`}
                        >
                          <i className="fas fa-heart"></i>
                          <i className="fab fa-whatsapp"></i>
                          {getFileType(memory.media) === 'video' && (
                          <FaPlay className={classes.playIcon} />
                          )}
                        </div>
                      </div>
                      <div className={`${classes.memory_content} text-center`}>
                        <h3 dir="rtl">
                          {memory.title ||
                            moment(memory.createdAt).format('DD.MM.YYYY')}{' '}
                        </h3>
                        <div className="d-flex justify-content-center align-items-center gap-1">
                          <h6 dir="rtl">
                            {memory.guestName?memory.guestName:memory.originalUser?.firstName}{' '}
                            {memory.guestName?"":memory.originalUser?.lastName}
                          </h6>
                          <img
                            src={
                              memory.originalUser?.profilePicture ||
                              'https://res.cloudinary.com/lifecloud-qr/image/upload/v1664810217/avatar_zcocuh.png'
                            }
                            alt="profile"
                            className={`rounded-circle ${classes.profile}`}
                          />
                        </div>

                        <h6 dir="rtl">
                          תגובות (
                          {memory.comments.length > 0
                            ? memory.comments.length
                            : '+'}
                          )
                        </h6>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            }


            )}
          </div>
        ) : (
          <h5 className="not_found" dir="rtl">
            עוד לא הועלו זכרונות לפרופיל, בואו לשתף ראשונים ☁
          </h5>
        )}
        <div
          className={`d-flex justify-content-center gap-5 profile_button_group ${classes.button_group}`}
        >
          {memories.length > 4 && (
            <HashLink to="#memory">
              <Button
                outline={true}
                onClick={() => setShowAll((prev) => !prev)}
              >
                {showAll ? 'הראה פחות' : 'עוד זכרונות'}
              </Button>
            </HashLink>
          )}
 
          <Button
            // onClick={!user._id ? () => dispatch(showModal('loginModal')) : undefined}
            // outline={true}
            // data-bs-toggle={user._id ? "modal" : "modal"}
            // data-bs-target={user._id ? " #createMemoryModal" : "#createMemoryModal"}
            onClick={() => setMemoryModal(true)}
          >
            העלאת זכרון{' '}
          </Button>
        </div>
      </div>
      <CreateMemoryModal profileId={profileId} modalAction={{show:memoryModal,set:setMemoryModal}} />
    </Section>
  );
};

export default MemoryList;

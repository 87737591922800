import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
/* Custom Components */
import arrow from '../../assets/registration-img/arrow.png';
import Button from '../../components/UI/Button/Button';
import { userSignup } from '../../features/user/userSlice';
import toastCreator from '../../utils/toastifyCreator';
import classes from '../Form.module.css';
/* import alertCreator from '../../utils/alertCreator'; */
import InputError from '../../components/InputError/InputError';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import { Helmet } from 'react-helmet-async';

//Form validation schema
const schema = yup.object().shape({
  organizationName: yup.string().required('!שם משפחה הינו שדה חובה*'),
  phone: yup.string().required('!מספר טלפון הוא שדה חובה*'),
  email: yup
    .string()
    .email('!המייל שהוזן אינו תקין*')
    .required('!מייל הינו שדה חובה*'),
  password: yup
    .string()
    .required('!דרושה סיסמא*')
    .min(6, '!הסיסמא צריכה להכיל לפחות 6 תווים*'),
  passwordConfirm: yup
    .string()
    .required('!אימות סיסמא הינו שדה חובה*')
    .oneOf([yup.ref('password')], 'הסיסמאות שהוזנו אינן תואמות!*'),
});

const OrganizationRegistration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  /* Show Notification */
  const showNotification = (status, payload) => {
    if (status === 'error') return toastCreator('error', payload);
    navigate('/');

    /*  alertCreator({
      icon: 'success',
      title: 'Registration Successful',
      text: 'Please check your mail to activate account!',
    }).then(() => {
      navigate('/login');
    }); */
  };
  /* Form Submit Handler */
  const onSubmit = async (formData) => {
    dispatch(
      userSignup({
        formData: { role: 'organization', ...formData },
        showNotification,
      })
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | הרשמה לעמותה</title>
        <meta property="og:title" content="LifeCloud | הרשמה לעמותה" />
        <meta
          property="og:url"
          content="https://lifecloud-qr.com/organization-registration"
        />
        <meta
          name="description"
          content="ניתן להקים עמוד מרוכז לעמותה בו אפשר להקים מספר פרופילים ולנהל את כולם תחת בית אחד. ניתן למנות מספר מנהלים ולשלוח הודעות מרוכזות. לסיוע בתהליך צרו קשר."
        />
        <meta
          property="og:description"
          content="ניתן להקים עמוד מרוכז לעמותה בו אפשר להקים מספר פרופילים ולנהל את כולם תחת בית אחד. ניתן למנות מספר מנהלים ולשלוח הודעות מרוכזות. לסיוע בתהליך צרו קשר."
        />
      </Helmet>
      <CenterContentLayout cloudPos="right-left">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <h2 className="page_title">הרשמה</h2>
                <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-12">
                      <div className={classes.input_container}>
                        <input
                          {...register('organizationName')}
                          placeholder="*שם הארגון..."
                          className={`form-control ${classes.input}`}
                        />
                        <InputError error={errors.organizationName?.message} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className={classes.input_container}>
                        <input
                          type="tel"
                          {...register('phone')}
                          placeholder="*טלפון..."
                          className={`form-control ${classes.input}`}
                        />
                        <InputError error={errors.phone?.message} />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className={classes.input_container}>
                        <input
                          type="email"
                          {...register('email')}
                          placeholder="*מייל..."
                          className={`form-control ${classes.input}`}
                        />
                        <InputError error={errors.email?.message} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className={classes.input_container}>
                        <input
                          type="password"
                          {...register('password')}
                          placeholder="*בחרו סיסמא..."
                          className={`form-control ${classes.input}`}
                        />

                        <InputError error={errors.password?.message} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className={classes.input_container}>
                        <input
                          type="password"
                          {...register('passwordConfirm')}
                          placeholder="*הזינו סיסמא שנית..."
                          className={`form-control ${classes.input}`}
                        />
                        <InputError error={errors.passwordConfirm?.message} />
                      </div>
                    </div>

                    <div className="col-12">
                      <Button
                        className={`w-100 ${classes.submit_btn}`}
                        loading={loading}
                      >
                        הרשמה
                      </Button>
                      <img
                        src={arrow}
                        alt="Arrow"
                        className={`d-block ${classes.arrow}`}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </CenterContentLayout>
    </React.Fragment>
  );
};

export default OrganizationRegistration;

import React from 'react';
import { useForm } from 'react-hook-form';
import classes from '../Form.module.css';
import MainLayout from '../../components/layout/MainLayout';
import arrow from '../../assets/registration-img/arrow.png';
import Button from '../../components/UI/Button/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../features/user/userSlice';
import toastCreator from '../../utils/toastifyCreator';
import alertCreator from '../../utils/alertCreator';
import InputError from '../../components/InputError/InputError';
import { Helmet } from 'react-helmet-async';

//Form validation schema
const schema = yup.object().shape({
  password: yup
    .string()
    .required('!דרושה סיסמא*')
    .min(6, '!הסיסמא צריכה להכיל לפחות 6 תווים*'),
  passwordConfirm: yup
    .string()
    .required('!אימות סיסמא הינו שדה חובה*')
    .oneOf([yup.ref('password')], '*Passwords must match!'),
});

const ResetPassword = () => {
  const { loading } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const showNotification = (status, payload) => {
    if (status === 'error') return toastCreator('error', payload);
    alertCreator({
      icon: 'success',
      title: 'סיסמתך שונתה בהצלחה',
      text: 'כעת ניתן להתחבר עם סיסמתך החדשה',
    }).then(() => {
      navigate('/');
    });
  };

  const onSubmit = async (formData) => {
    dispatch(resetPassword({ token, formData, showNotification }));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title dir="rtl">LifeCloud | איפוס סיסמא</title>
        <meta
          property="og:url"
          content={`https://lifecloud-qr.com/reset-password/${token}`}
        />
      </Helmet>
      <MainLayout gap={true}>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <h2 className={`page_title`}>איפוס סיסמא</h2>
                <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-12">
                      <div className={classes.input_container}>
                        <input
                          type="password"
                          {...register('password')}
                          placeholder="בחרו סיסמא חדשה..."
                          className={`form-control ${classes.input}`}
                        />

                        <InputError error={errors.password?.message} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className={classes.input_container}>
                        <input
                          type="password"
                          {...register('passwordConfirm')}
                          placeholder="הזינו שנית את הסיסמא החדשה..."
                          className={`form-control ${classes.input}`}
                        />

                        <InputError error={errors.passwordConfirm?.message} />
                      </div>
                    </div>

                    <div className="col-12">
                      <Button
                        className={`${classes.submit_button} w-100 my-5`}
                        loading={loading}
                      >
                        יצירת סיסמא חדשה
                      </Button>
                      <img
                        src={arrow}
                        alt="Arrow"
                        className={`d-block ${classes.arrow}`}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </React.Fragment>
  );
};

export default ResetPassword;

import React, { useState } from 'react';
import Section from '../layout/Section';
import Button from '../UI/Button/Button';
import classes from './GalleryGrid.module.css';
import ReactPlayer from 'react-player';
import getFileType from '../../utils/getFileType';
import FsLightbox from 'fslightbox-react-premium';
import { HashLink } from 'react-router-hash-link';

const GalleryGrid = ({ gallery, setShowGallery/*, galleryProperties*/ }) => {
  const [toggler, setToggler] = useState(false);
  const [index, setIndex] = useState(0);
  const handleGalleryClick = (index) => {
    setToggler(!toggler);
    setIndex(index);
  };
  return (
    <Section
      className={`${classes.gallery} position-relative ${classes.gallery_grid}`}
      padding={false}
      gap={false}
      colorful
      showCloud={false}
      id="galleryGrid"
    >
      <div className="container">
        <h2 className="profile_title">גלריית תמונות</h2>
        <div className="row g-md-5 g-4">
          {gallery.map((image, index) => (
            <div className="col-lg-3 col-md-4 col-6" key={index}>
                  {
                  /*galleryProperties &&
                  galleryProperties.map((property, propIndex) =>
                    propIndex === property.index && gallery[propIndex] ? (
                      <h2 key={propIndex}>{property.title}</h2>
                    ) : (
                      null 
                    )
                  )
                  */
                  }
              <div className={classes.gallery_item}>
                {getFileType(image) === 'image' ? (
                  <img
                    src={image}
                    alt="gallery"
                    className={classes.media}
                    onClick={() => handleGalleryClick(index)}
                  />
                ) : (
                  <div
                    className={classes.media}
                    onClick={() => handleGalleryClick(index)}
                  >
                    <ReactPlayer
                      url={image}
                      controls
                      width="100%"
                      height="100%"
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={`d-flex justify-content-center mt-md-5 mt-4`}>
          <HashLink to="#gallery">
            <Button outline={true} onClick={() => setShowGallery(false)}>
              חזרה לפרופיל
            </Button>
          </HashLink>
        </div>
      </div>
      <FsLightbox
        toggler={toggler}
        sources={gallery}
        sourceIndex={index}
        type="image"
        types={gallery.map((image) => getFileType(image))}
      />
    </Section>
  );
};

export default GalleryGrid;

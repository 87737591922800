import React from 'react';
// import MainLayout from '../layout/MainLayout';
import classes from './ShareToPic.module.css';

import cross from '../../assets/candleflower-modal-img/x.png';

import header from '../../assets/sharetopic-img/story/header.png';
import flowers from '../../assets/sharetopic-img/story/flowers.png';
import profile from '../../assets/sharetopic-img/story/profile.png';
import logo from '../../assets/sharetopic-img/story/logo.svg';


const ShareToPic = () => {
  return (
    <React.Fragment>
      <section  style={{ position: 'absolute', top: '0', left: '0', right: '0', zIndex: '5000' }}>
           <div  className={`${classes.facepostwrapper} m-auto`}  >
               
                <div  className={`${classes.facepostimage} `}  >
                    <img src={ header } />
                
                </div>
                <div  className={`${classes.facecircleimg} m-auto`} >
                     <img src={ profile } />
                </div>
                 <div className={`${classes.bottomContent} `} >
                 <h3 >
                 הצטרפו אלי ול-
              <b>    החברים#   </b>
                  בקהילה הזוכרת של 
                    <b> PROFILE_FULLNAME 
                     ז”ל  </b>
                     חפשו אותנו ב

                   <img src={ logo } />
                 </h3>
                 
                   <img src={ flowers } />
                   
                 </div>
                 
                 <div className={`${classes.textBottom}`} >
                    <h3> <a> <b>
                      WWW.LIFECLOUD-QR.COM
                    </b>  </a> <br />
                       בית חדש לחיים שאחרי
                      </h3>
                 </div>
            
            </div> 
         
      </section>
     
    </React.Fragment>
  );
};

export default ShareToPic;

import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../../features/modal/modalSlice';
const TeaserVideoModal = () => {
  const { teaserVideoModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  return (
    <Modal
      show={teaserVideoModal}
      onHide={() => dispatch(hideModal('teaserVideoModal'))}
      size="lg"
      centered
    >
      <Modal.Body>
        <video width="100%" controls>
          <source
            src="https://res.cloudinary.com/lifecloud-qr/video/upload/v1670147935/LifeCloud_Guide.mp4"
            type="video/mp4"
          />
          Your browser does not support HTML video.
        </video>
      </Modal.Body>
    </Modal>
  );
};

export default TeaserVideoModal;

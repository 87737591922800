import Swal from 'sweetalert2';

//Alert generator
const alertCreator = async ({
  icon,
  title,
  text,
  confirmButtonColor = '#6097BF',
  showCancelButton = false,
  cancelButtonColor = '#d33',
  confirmButtonText = 'אישור',
  cancelButtonText = 'ביטול',
}) => {
  return await Swal.fire({
    icon,
    title,
    text,
    confirmButtonColor,
    showCancelButton,
    cancelButtonColor,
    confirmButtonText,
    cancelButtonText,
  });
};

export default alertCreator;

import React, { useEffect, useState } from 'react';
import classes from '../CreateMemory/CreateMemory.module.css';
import formClasses from '../Form.module.css';
import Button from '../../components/UI/Button/Button';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { editMemory, getMemory } from '../../features/memory/memorySlice';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import toastCreator from '../../utils/toastifyCreator';
import InputError from '../../components/InputError/InputError';
import { Helmet } from 'react-helmet-async';
import lifeAxios from '../../utils/lifeAxios';
import getFileType from '../../utils/getFileType';

//Form validation schema
const schema = yup.object().shape({
  description: yup.string().required('*Description is required!'),
  media: yup.mixed(),
});
const EditMemory = () => {
  const { memoryId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [media, setMedia] = useState(null);
  const { memory } = useSelector((state) => state.memory);
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.memory);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    (async () => {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/memories/${memoryId}`
      );
      const memoryResponse = response.data.data.data;
      const media =
        getFileType(memoryResponse.media) === 'video'
          ? 'https://res.cloudinary.com/lifecloud-qr/image/upload/v1672574174/photo-1611162616475-46b635cb6868_l2gzrj.avif'
          : memoryResponse.media;

      setMedia(media);
      reset(memoryResponse);
    })();
  }, [dispatch, memory, memoryId, reset]);

  const showNotification = (status, payload) => {
    if (status === 'error') return toastCreator('error', payload);
    navigate(`/memory/${payload}`);
  };
  const onSubmit = ({ description, media, title }) => {
    const formData = new FormData();
    formData.append('description', description);
    formData.append('title', title);
    if (media[0].type) formData.append('media', media[0]);
    dispatch(editMemory({ memoryId, formData, showNotification }));
  };
  const onChangeMedia = (event) => {
    if (event.target.files[0]) {
      const src = URL.createObjectURL(event.target.files[0]);
      setMedia(src);
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | העלאת זכרון חדש</title>
        <meta
          property="og:url"
          content={`https://lifecloud-qr.com/edit-memory/${memoryId}`}
        />
      </Helmet>
      <CenterContentLayout cloudPos="right-left">
        <section className={classes.create_memory}>
          <h2>עריכת זיכרון</h2>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <form onSubmit={handleSubmit(onSubmit)} className="text-center">
                  <div className="d-flex justify-content-center">
                    <div className={classes.social}>
                      <label htmlFor="media">
                        {media ? (
                          <img
                            src={media}
                            alt="upload"
                            className={classes.preview_img}
                          />
                        ) : (
                          <div className={classes.img_container}>
                            <div className={classes.plus}>
                              <div className={`${classes.line}`}></div>
                              <div className={`${classes.line}`}></div>
                            </div>
                          </div>
                        )}
                        בחרו תמונה/סרטון/מצגת להעלאה
                        <input
                          type="file"
                          id="media"
                          name="media"
                          accept="image/*, video/*,.pptx"
                          className="d-none"
                          {...register('media', {
                            onChange: onChangeMedia,
                          })}
                        />
                      </label>
                    </div>
                  </div>
                  <div className={`row gx-5 ${classes.input_container}`}>
                    <div className="col-12">
                      <input
                        type="text"
                        className={`form-control ${formClasses.input} ${classes.input}`}
                        // placeholder="שם הזכרון."
                        {...register('title')}
                        // maxLength="15"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <textarea
                        {...register('description')}
                        className={`form-control ${formClasses.input} ${formClasses.textarea} ${classes.textarea}`}
                        placeholder="תוכן הזכרון..."
                        dir="rtl"
                      ></textarea>
                      <InputError error={errors.description?.message} />
                    </div>
                  </div>

                  <div
                    className={`row gy-4 gy-md-5 ${classes.button_container}`}
                  >
                    <Button className={classes.button} loading={loading}>
                      פרסם
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </CenterContentLayout>
    </React.Fragment>
  );
};

export default EditMemory;

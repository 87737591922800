import React, { useState } from 'react';
import Section from '../layout/Section';
import classes from './ProfileDescription.module.css';

const ProfileDescription = ({ description, title, isOrganization }) => {
  const [showAll, setShowAll] = useState(false);
  const slicedDescription = showAll ? description : description.slice(0, 300);
  return (
    <Section
      className={`${classes.profile_description} ${
        isOrganization
          ? classes.organization_profile_description
          : classes.normal_profile_description
      }`}
      cloudPos="down-left"
      showCloud={!isOrganization}
      gap={false}
      colorful
    >
      <div className="container text-center">
        <h2 className="profile_title" dir="rtl">
          {title ? title : 'סיפור חיים'}
        </h2>
        <div className="row">
          <div className="col-lg-8 col-md-10 mx-auto">
            <p dir="rtl">
              {description.length === 0 && 'לא נוסף סיפור חיים, כדאי להוסיף ☁'}
              {slicedDescription}
              {description.length > 300 && (
                <span
                  onClick={() => setShowAll((prev) => !prev)}
                  className={classes.see_more}
                >
                  {showAll ? 'הראה פחות' : 'הראה עוד...'}
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ProfileDescription;

import React, { useState } from 'react';
import classes from './QrLanding.module.css';
import MainLayout from '../../components/layout/MainLayout';
import menubar from '../../assets/header-img/bar.png';
import { HashLink } from 'react-router-hash-link';
import menucross from '../../assets/header-img/bar_cross.png';
import { Link } from 'react-router-dom';
const QrLanding = () => {
  const [showNav, setShowNav] = useState(false);
  return (
    <React.Fragment>
      <header className={classes.qr_hero}>
        <div className={classes.hero_menu}>
          <div>
            <Link to="/?landingpage">
              <img
                src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1674545641/logo-blue_2_nkz5p0.png"
                alt="LifeCloud Logo"
                className={classes.logo}
              />
            </Link>
          </div>
          <div className="position-relative">
            <img
              src={showNav ? menucross : menubar}
              alt="Menu Burger"
              className={classes.menu_burger}
              onClick={() => setShowNav(!showNav)}
            />
            {showNav && (
              <ul className={classes.navigation}>
                <li>
                  <HashLink to="#anchor1">למי רלוונטי?</HashLink>
                </li>
                <li>
                  <HashLink to="#anchor2">המטרה שלנו</HashLink>
                </li>
                <li>
                  <HashLink to="#anchor3">מי אנחנו</HashLink>
                </li>
                <li>
                  <Link to="/?landingpagemenu">כניסה לאתר</Link>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className={classes.hero_content}>
          <h3>
            איבדתם אדם יקר, חוויתם אובדן ואתם רוצים לשתף את החוויות והזיכרונות
            שלכם עם חברים ובני משפחה? הגעתם למקום הנכון.
          </h3>
          <div className="d-flex justify-content-center align-items-center gap-md-5 gap-0 flex-wrap-reverse">
            <Link
              to="/profile/62930e650fc791cf90ac210c?landingpage5"
              className={`${classes.header_btn} ${classes.header_btn_02}`}
            >
              צפייה בפרופיל לדוגמא
            </Link>
            <Link to="/?reg=true" className={classes.header_btn}>
              להקמת עמוד ליקירכם - <b>ללא עלות </b>
            </Link>
          </div>

          <h4>
            <a
              href="https://api.whatsapp.com/send/?text=מה קורה? ☁️%0aראיתי אתר שיכול להיות מאוד רלוונטי אלייך, קוראים לו LifeCloud-qr.com, הוא בנושא הנצחה וזה בטח יעניין אותך…"
              className="text-white"
            >
              רלוונטי יותר למישהו אחר ? <br className="d-md-none d-block" />
              לחצו לשיתוף בWhatsApp
            </a>
          </h4>
        </div>
      </header>
      <MainLayout bg="#F5FCFF" header={false} className={classes.main}>
        <div className={`container ${classes.qr_container}`}>
          <div className={classes.inner_content} id="anchor1">
            <div
              className="row align-items-center text-md-end text-center"
              id="#firstAnchor"
            >
              <div className="col-md-6">
                <img
                  src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1674735290/iPhone-X-mockup-hover-seperated_1_h8z1fr_1_i5zsvf.png"
                  alt="iPhone-X-mockup"
                  className={`img-fluid ${classes.mobile_image}`}
                />
              </div>
              <div className="col-md-6">
                <h3>
                  למי זה בעצם <b>מתאים?</b>
                </h3>
                <hr />
                <p>
                  כולנו חווינו אובדן, הורים שנפטרו בשיבה טובה או חברים ובני
                  משפחה שנפטרו בנסיבות מצערות.{' '}
                  <b>אחרי הצער והאבל הבנו שיש חיים </b> שלמים של זכרונות וחוויות
                  שעלינו <b>לחלוק עם סביבתנו.</b>
                </p>

                <Link to="/?reg=true">להרשמה ללא עלות לחצו כאן</Link>
              </div>
            </div>
            <hr className={classes.section_hr} />
            <div
              className="row align-items-center text-md-end text-center"
              id="anchor2"
            >
              <div className="col-md-6 order-2 order-md-1">
                <h3>
                  <b>המטרה שלנו: </b>הזיכרון שלכם
                </h3>
                <hr />
                <p>
                  בעולם המתקדם של רשתות חברתיות, מדיה דיגיטלית ואין סוף
                  אפשרויות, מצאנו שצריך לתת{' '}
                  <b>מקום מכבד ונפרד לחברים ומשפחה שכבר לא איתנו.</b> <br />
                  במהלך תקופה ארוכה ניסינו להבין יותר, לחקור, לשאול, למצוא את
                  הנוסחה הנכונה וכך בעצם הקמנו את{' '}
                  <img
                    src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1674654558/download_1_dmbtd8.png"
                    alt="Logo"
                    className={classes.text_logo}
                  />
                  , על מנת ליצור בית חדש לחיים שאחרי.
                  <br /> אנחנו מגישים ופותחים בפניכם את המיזם שלנו{' '}
                  <b>ללא עלות</b>, שנולד כולו מתוך שליחות אמיתית, לכן טבעי שנרצה
                  להציע אותו לכולם.
                </p>
                <Link to="/profile/62930e650fc791cf90ac210c?landingpage3">
                  צפו בפרופיל לדוגמא
                </Link>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <img
                  src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1674392371/Mockup_1_u01dkp.png"
                  alt="mac"
                  className="img-fluid"
                />
              </div>
            </div>
            <hr className={classes.section_hr} />
            <div
              className="row align-items-center text-md-end text-center"
              id="anchor3"
            >
              <div className="col-md-6">
                <img
                  src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1674392528/Help_1_rkxwuo.png"
                  alt="group"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-6" id="#secondAnchor">
                <h3>
                  מי <b>אנחנו?</b>
                </h3>
                <hr />
                <p>
                  <img
                    src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1674654558/download_1_dmbtd8.png"
                    alt="Logo"
                    className={classes.text_logo}
                  />{' '}
                  הוקמה ע”י קבוצת חברים שהגיעו כל אחד מתחומו - אומנות, צבא
                  וביטחון, עסקים ותקשרות. כל אחד והקרבה האישית שלו לתחום ההנצחה.{' '}
                </p>
                <Link to="/?landingpage4">לצפייה באתר המלא</Link>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default QrLanding;

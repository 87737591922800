import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Gallery from '../../components/Gallery/Gallery';
/* import GraveLocation from '../../components/GraveLocation/GraveLocation'; */
import MainLayout from '../../components/layout/MainLayout';
import ProfileDescription from '../../components/ProfileDescription/ProfileDescription';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { useState } from 'react';
import SocialFooter from '../../components/SocialFooter/SocialFooter';
// import TributeCandleFlower from '../../components/TributeCandleFlower/TributeCandleFlower';
import GalleryGrid from '../../components/Gallery/GalleryGrid';
import { getOrganizationProfile } from '../../features/organizationProfile/organizationProfileSlice';
import ProfileInfo from '../../components/ProfileInfo/ProfileInfo';
import ProfileCover from '../../components/ProfileCover/ProfileCover';
import WallFriend from '../../components/WallFriend/WallFriend';
import SubProfiles from '../../components/SubProfiles/SubProfile';
import OrganizationProfileNavigator from '../../components/ProfileNavigator/OrganizationProfileNavigator';
import Memorial from '../../components/Memorial/Memorial';
import PhysicalQrModal from '../../modals/PhysicalQrModal';
import { Helmet } from 'react-helmet-async';
const OrganizationProfileDetails = () => {
  /* Redux State */
  const { organizationProfile } = useSelector(
    (state) => state.organizationProfile
  );
  const { user } = useSelector((state) => state.user);
  /* Profile Id (Getting from URL) */
  const { profileId } = useParams();
  /* Hide or Show section according to the state value */
  const [showSection, setShowSection] = useState('wall');
  const dispatch = useDispatch();
  /* Get the profile details accordion to the profile id */
  useEffect(() => {
    dispatch(getOrganizationProfile(profileId));
  }, [dispatch, profileId]);
  /* If profile not loaded then loading spinner will show */
  if (!organizationProfile) return <Loader />;
  /* Check if the current login user is the owner or admin of visited profile */
  const isOwnerOrAdmin =
    organizationProfile.originalUser._id === user._id ||
    organizationProfile.profileAdmins.find((admin) => admin._id === user._id);
  const isFriend = organizationProfile.profileFriends.find(
    (friend) => friend._id === user._id
  );
  
  // kicdev , e.s. 28.03.2023 task# 49
  var title=  "אודות העמותה"
  if(organizationProfile.aboutTitle!==undefined) 
  {
    title=organizationProfile.aboutTitle;
  }
  
  const pUrl = `${window.location.protocol}//${window.location.host}/organization-profile/${profileId}`;
  
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | {organizationProfile.organizationName} </title>
        <meta
          property="og:url"
          content={`https://lifecloud-qr.com/organization-profile/${profileId}`}
        />
        <meta
          property="og:title"
          content={`LifeCloud | ${organizationProfile.organizationName}`}
        />
        <meta property="og:image" content={organizationProfile.wallImg} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <link rel="canonical" href={pUrl} />
      </Helmet>
      <MainLayout bg="#fff">
        <ProfileCover
          wallImg={organizationProfile.wallImg}
          profileId={organizationProfile.id}
          userId={user._id}
          candleFlower={false}
        />
        <ProfileInfo profile={organizationProfile} />
        <OrganizationProfileNavigator
          showSection={showSection}
          setShowSection={setShowSection}
          isFriend={isFriend}
          isOwnerOrAdmin={isOwnerOrAdmin}
          user={user}
          profile={organizationProfile}
        />
        {(organizationProfile.privacy === 'public' ||
          isOwnerOrAdmin ||
          isFriend) && (
          <React.Fragment>
            {showSection === 'wall' && (
              <React.Fragment>
                <ProfileDescription
                  description={organizationProfile.description}
                  title={title}
                  isOrganization={organizationProfile.isOrganization}
                />
                {organizationProfile.id !== '63ce4f9dcadfc7b0e2918a97' && (
                  <Memorial
                    profile={organizationProfile}
                    title="מיקום האנדרטה"
                    isOrganization={organizationProfile.isOrganization}
                    isOwnerOrAdmin={isOwnerOrAdmin}
                  />
                )}
                <Gallery
                  gallery={organizationProfile.gallery}
                  setShowSection={setShowSection}
                />

                <SubProfiles profiles={organizationProfile.profiles} memoryTitle={organizationProfile?.memoryTitle} />
              </React.Fragment>
            )}

            {showSection === 'gallery' && (
              <GalleryGrid
                gallery={organizationProfile.gallery}
                setShowSection={setShowSection}
              />
            )}

            {showSection === 'wallFriend' && (
              <WallFriend
                profile={organizationProfile}
                setShowSection={setShowSection}
                user={user}
                isOwnerOrAdmin={isOwnerOrAdmin}
              />
            )}
          </React.Fragment>
        )}
        <SocialFooter cloudPos="down-right" />
        <PhysicalQrModal user={user} profile={organizationProfile} />
        {/* 
      <PhysicalQrModal user={user} profile={profile} /> */}
      </MainLayout>
    </React.Fragment>
  );
};

export default OrganizationProfileDetails;

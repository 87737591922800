import React from 'react';
import classes from './Plans.module.css';
import cloud from '../../assets/plans-img/cloud.png';
import Button from '../../components/UI/Button/Button';
import MainLayout from '../../components/layout/MainLayout';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Plans = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | תינכות רחב</title>
        <meta property="og:url" content="https://lifecloud-qr.com/plans" />
      </Helmet>
      <MainLayout gap={true}>
        <section className={classes.plans}>
          <div className="container">
            <h2 className="page_title">תינכות רחב</h2>
            <div
              className={`row gx-0 gx-md-5 gy-5 gy-md-0 justify-content-center`}
            >
              <div className="col-xl-5 col-md-6">
                <div className={classes.plan_card}>
                  <div className="cloud">
                    <img src={cloud} alt="Cloud" />
                  </div>
                  <h3>Life Book</h3>
                  <h4>םינוגריאל</h4>
                  <ul>
                    <li>םיישיא םיטרפ</li>
                    <li>הנומת</li>
                    <li>רבקה םוקימ</li>
                    <li>חונמה לע טוטיצ</li>
                    <li>החפשמ ץע</li>
                    <li>ואידוו תונומת תיירלג</li>
                    <li>תובוגתו תונורכז תאלעה</li>
                    <li>םידחוימ םיעוריאו הרכזאל תורוכזת</li>
                    <li>םיעוריאו יח רודישל תורשפא</li>
                    <li>םיבקוע תפסוה </li>
                    <li>תויטרפ תורדגה</li>
                  </ul>
                  <div className={classes.first_card_texts}>
                    <h5>םאתומ Life Book</h5>
                    <h5>יפל ןוגרא לכל</h5>
                    <h5>וינייפאמו ויכרצ</h5>
                  </div>
                  <Button className={classes.first_plan_button}>
                    <Link to="/contact">רשק רוצ</Link>
                  </Button>
                </div>
              </div>
              <div className="col-xl-5 col-md-6">
                <div className={`bg-white ${classes.plan_card}`}>
                  <div className="cloud">
                    <img src={cloud} alt="Cloud" />
                  </div>
                  <h3>Life Book</h3>
                  <h4>חונמה דומע</h4>
                  <ul>
                    <li>םיישיא םיטרפ</li>
                    <li>הנומת</li>
                    <li>רבקה םוקימ</li>
                    <li>חונמה לע טוטיצ</li>
                    <li>החפשמ ץע</li>
                    <li>ואידוו תונומת תיירלג</li>
                    <li>תובוגתו תונורכז תאלעה</li>
                    <li>םידחוימ םיעוריאו הרכזאל תורוכזת</li>
                    <li>םיעוריאו יח רודישל תורשפא</li>
                    <li>םיבקוע תפסוה </li>
                    <li>תויטרפ תורדגה</li>
                  </ul>
                  <ul className={classes.second_list}>
                    <li>GB 1.5 ןוסחא</li>
                    <li>תינכט הכימתו יוויל</li>
                    <li>ישיא QR</li>
                  </ul>
                  <Button outline={true} className={classes.second_plan_button}>
                    הנשל םניח
                    <br /> הנשל ₪99 ןכמ רחאל*
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </React.Fragment>
  );
};

export default Plans;

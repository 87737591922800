import React from 'react';
import classes from './PhysicalQrModal.module.css';
import QRCode from 'qrcode.react';
import Button from '../components/UI/Button/Button';
import { saveAs } from 'file-saver';
import whatsAppImg from '../assets/wall-friend-img/whatsapp.png';

const PhysicalQrModal = ({ user, profile }) => {
  const downloadQRCode = () => {
    saveAs(
      document.getElementById('profile-qr').toDataURL('image/png'),
      `${profile.firstName}-profile-qr.png`
    );
  };
  const shareURL = profile.organizationName
    ? `
    https://api.whatsapp.com/send/?text=חולקים כבוד עם ${profile.organizationName}, אני מזמין אותך להציץ בעמוד ההנצחה לזכרם ☁️
    %0a %0a https://lifecloud-qr.com${window.location.pathname}`
    : `
    https://api.whatsapp.com/send/?text=זוכרים%20את%20${profile.firstName}%20${profile.lastName}
    🌺%0aהכנסו%20ללינק%20הרשמו%20ובקשו%20חברות%20,הצטרפו%20לקהילה%20שזוכרת%20,%20תקראו%20תשתפו%20תעלו%20זכרונות%20תמונות%20ועוד.%0a
    https://lifecloud-qr.com/profile/${profile.id}%0aLifeCloud%20☁️%20בית%20חדש%20לחיים%20שאחרי`;
  

  return (
    <div
      className="modal fade "
      id="physicalQrModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className={`modal-content py-5 ${classes.modal_content}`}>
          <div className="modal-header border-0">
            <button
              type="button"
              className={`btn-close me-auto ${classes.btn_close}`}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-center">
            <QRCode
              id="profile-qr"
              value={window.location.href}
              size={256}
              level="Q"
              fgColor="#1A4E74"
            />
          </div>
          <div
            className={`modal-footer d-flex justify-content-center gap-5 border-0 ${classes.footer_buttons}`}
          >
            <Button
              type="button"
              outline={true}
              className="rounded-pill px-5"
              dir="rtl"
              onClick={downloadQRCode}
            >
              + הורדת QR כתמונה
            </Button>

            <a href={shareURL} target="_blank" rel="noreferrer">
              <Button
                dir="rtl"
                className={`d-flex align-items-center ${classes.share_button}`}
              >
                + שיתוף בWhatsApp
                <img
                  src={whatsAppImg}
                  alt="whatsApp"
                  className="me-2 img-fluid"
                />
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhysicalQrModal;

import React, { useEffect } from 'react';
import candle from '../../assets/profileDetails-img/roundCandle.png';
import flower from '../../assets/profileDetails-img/roundFlower.png';
import classes from './CandleFlowerWidget.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getCandlesFlowers } from '../../features/candleFlower/candleFlowerSlice';
import { HashLink } from 'react-router-hash-link';

const CandleFlowerWidget = ({ profileId, userId, height }) => {
  const { candlesFlowers } = useSelector((state) => state.candleFlower);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCandlesFlowers(profileId));
  }, [dispatch, profileId]);

  const totalCandles = candlesFlowers.reduce(
    (acc, curr) => acc + curr.candles,
    0
  );
  const totalFlowers = candlesFlowers.reduce(
    (acc, curr) => acc + curr.flowers,
    0
  );
  return (
    <div
      className={`${classes.candle_flower_buttons} d-flex flex-column gap-5`}
      style={{ height: `${height + 135 + (userId ? 0 : 80)}px` }}
    >
      <div className={classes.candle}>
        
        <div
          className={`${classes.candle_img} ${
            totalCandles === 0 && classes.zero_candle
          }`}
          data-bs-toggle="modal"
          data-bs-target="#candleFlower"
        >
          <img src={candle} alt="candle" className="rounded-circle" />
        </div>
        <div className={classes.candle_users}>

          <HashLink to="#virtual-memory">
            <h5
              className={classes.candle_count}
              /*  onClick={() => setShowCandleList((prev) => !prev)} */
            >
              {totalCandles}
            </h5>
          </HashLink>

        </div>
      </div>
      <div className={classes.flower}>
        <div
          className={`${classes.flower_img}
            ${totalFlowers === 0 && classes.zero_flower}
            
            `}
          data-bs-toggle="modal"
          data-bs-target="#candleFlower"
        >
          <img src={flower} alt="flower" className="rounded-circle" />
        </div>
        <div className={classes.flower_users}>

          <HashLink to="#virtual-memory">
            <h5
              className={classes.flower_count}
              /* onClick={() => setShowFlowerList((prev) => !prev)} */
            >
              {totalFlowers}
            </h5>
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default CandleFlowerWidget;

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '../features/modal/modalSlice';
import { showModal } from '../features/modal/modalSlice';
import classes from './GuestCFPurchaseModal.module.css';
import Button from '../components/UI/Button/Button';
import SocialLogin from '../components/SocialLogin/SocialLogin';
import lifeAxios from '../utils/lifeAxios';

import cross from '../assets/candleflower-modal-img/x.png';
import profileImageDemo from '../assets/candleflower-modal-img/candle.png';
import google from '../assets/candleflower-modal-img/googleLogo.png';
import facebook from '../assets/candleflower-modal-img/facebookLogo.png';
import gmail from '../assets/candleflower-modal-img/gmailLogo.png';
import prfilepic_a from '../assets/candleflower-modal-img/profilesamps/prfilepic_a.jpeg';
import prfilepic_b from '../assets/candleflower-modal-img/profilesamps/prfilepic_b.jpeg';
import prfilepic_c from '../assets/candleflower-modal-img/profilesamps/prfilepic_c.jpeg';
import prfilepic_z from '../assets/candleflower-modal-img/profilesamps/prfilepic_z.jpeg';
import { createFundedCandleFlower, getCandlesFlowers } from '../features/candleFlower/candleFlowerSlice';


const GuestCreateMemoryModal = ({onSubmit, guestAction}) =>
{
  const { guestCreateMemoryModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);

  const handleNameChange = (event) =>
  {
    guestAction.set({...guestAction.show,guestName: event.target.value});
  };

  const handleEmailChange = (event) =>
  {
    guestAction.set({...guestAction.show,guestEmail: event.target.value});
  };
  
  const handleFormSubmit = async (event) =>
  {
    event.preventDefault();
    localStorage.setItem('profileId', profile._id);
    onSubmit();
  };

    

return (
    <React.Fragment>
      <Modal
        show={guestCreateMemoryModal}
        onHide={() => dispatch(hideModal('guestCreateMemoryModal'))}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        contentClassName={classes.modal_content}
        className={` ${classes.guestCFModal}`}
        centered
      >
        <div className={`modal-dialog modal-dialog-centered modal-md  ${classes.modal_dialog}`} style={{ margin: 0 }}>
          <div className={`auth-modal modal-content text-center ${classes.modal_content}`}>

            <div className={`modal-header justify-content-between ${classes.modal_header}`}   >

              <button
                type="button"
                className={`${classes.btn_close}  btn align-self-start`}
                aria-label="Close"
              >
                <img src={cross} alt="Close" />
              </button>
              <div className="m-auto">
                <h5
                  className={`${classes.header_title} m-auto`} >
                  תודה שבחרתם לחלוק כבוד ל
                  <b>{profile.firstName}</b>
                </h5>
              </div>
            </div>

            <div className={`modal-body ${classes.modal_body}`}>
              <div className="container">
                <div className={`row ${classes.rowGuest} `}>
                  <span>
                    בתור אורח/ת, יש למלא את שמך וכתובת מייל
                  </span>
                 
                  <form className={`row g-4 ${classes.guestForm} `} onSubmit={(e) =>
                  {
                    dispatch({ type: 'RESET' });
                    handleFormSubmit(e);
                  }}
                  >
                    <div className={classes.dedicated_texts}>
                      <input
                        type="text"
                        placeholder="שם מלא*"
                        className="form-control"
                        onChange={handleNameChange}
                        value={guestAction.show.guestName}
                        required
                      />
                      <br />
                      <input
                        type="email"
                        placeholder="כתובת מייל*"
                        className="form-control"
                        onChange={handleEmailChange}
                        value={guestAction.show.guestEmail}
                        required

                      />
                    </div>
                    <div className="col-md-12">
                      <Button
                        className={`w-100 ${classes.submit_btn}`}
                      >
                        המשך להעלאת זכרון
                      </Button>
                    </div>
                  </form>


                </div>
                <div className={`row ${classes.rowGuest} `}>
                  <span className={` ${classes.txt_title}`}   >
                    הצטרפו ל{profile.profileFriends.length == 0 ? "" : "-" + profile.profileFriends.length + " "}חברים שכבר בקהילה לזכר
                    <span className={` ${classes.txt_title}`}   > {profile.firstName} </span>
                  </span>
                  <div className={` ${classes.profilesContain} `} >
                    <div className={` ${classes.profileImage} `} >
                      <img src={prfilepic_a} />
                    </div>
                    <div className={` ${classes.profileImage} `} >
                      <img src={prfilepic_b} />
                    </div>
                    <div className={` ${classes.profileImage} `} >
                      <img src={prfilepic_c} />
                    </div>
                    <div className={` ${classes.profileImage} `} >
                      <img src={prfilepic_z} />
                    </div>
                    <div className={` ${classes.profileImageEmpty}  ${classes.profileImage} `} >
                      <span>
                        ?אתם
                      </span>
                    </div>
                  </div>
                  <div className={`row ${classes.likeBtn} `}>
                    <span>
                      התחברות עם חשבון:
                    </span>
                    <SocialLogin bigSizedDesign={false} />
                    |
                    <div className={` ${classes.socialIcons} `} onClick={() => dispatch(showModal('registrationModal'))}>
                      <img src={gmail} />
                    </div>
                  </div>
                </div>
                <div className={` ${classes.linkLogin} `} >
                  <span onClick={() => dispatch(showModal('loginModal'))} >
                    !יש לכם כבר משתמש? לחצו כאן
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
      );

};

export default GuestCreateMemoryModal;

import React from 'react';
import classes from './ProfileModal.module.css';
import parse from 'html-react-parser';
const ProfileModal = ({ src, bsTarget, modalText, modalTitle }) => {
  return (
    <div
      className="modal fade"
      id={bsTarget}
      tabIndex="-1"
      aria-labelledby={`${bsTarget}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className={`${classes.modal_content} modal-content`}>
          <div className={`${classes.modal_header} modal-header`}>
            {/* <h3>Hello</h3> */}
            {modalTitle && (
              <h3 className={classes.modal_title}>{modalTitle}</h3>
            )}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className={`${classes.modal_body} modal-body`}>
            <h2 className={classes.modal_text} dir="rtl">
              {/* {parse(modalText)} */}
              {modalText}
            </h2>
            {src && <img src={src} alt="profile" className="img-fluid" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;

import { useState } from 'react';

const useGeoLocation = ({ getLatLng }) => {
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: '', lng: '' },
  });

  const onSuccess = (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });
    getLatLng(location.coords.latitude, location.coords.longitude);
  };
  const removeGeoLocation = () => {
    setLocation({
      loaded: false,
      coordinates: { lat: '', lng: '' },
    });
  };
  const onError = (error) => {
    setLocation({
      loaded: true,
      error: {
        code: error.code,
        message: error.message,
      },
    });
  };
  const getGeoLocation = () => {
    if (!('geolocation' in navigator)) {
      onError({
        code: 0,
        message: 'Geolocation not supported',
      });
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  };

  return { location, getGeoLocation, removeGeoLocation };
};

export default useGeoLocation;

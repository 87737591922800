import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader/Loader';

export { PrivateRoute };

function PrivateRoute({ children }) {
  const { user, loading } = useSelector((state) => state.user);

  if (loading) return <Loader />;
  // Not logged in so redirect to login page with the return url
  if (!user._id) return <Navigate to="/login" />;
  // Authorized so return child components
  return children;
}

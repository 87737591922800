import React from 'react';
import classes from './PillBox.module.css';
import moment from 'moment';

const PillBox = ({
  img,
  date,
  event,
  imgPos = 'left',
  position = 'evenly',
  className = '',
  userName,
  onClick,
}) => {
  const time = moment(date).format('DD.MM.YYYY');
  const day = moment(date).format('hh:mm');

  return (
    <div
      className={`${classes.wrapper} d-flex align-items-center justify-content-${position} ${className}`}
    >
      {imgPos === 'left' && (
        <div className={classes.img_container}>
          <img src={img} alt="notification created user" />
        </div>
      )}
      {onClick && (
        <button
          type="button"
          className={`me-auto ${classes.pill_button}`}
          onClick={onClick}
        >
          מחק
        </button>
      )}
      <div className={classes.text_container}>
        <p className={classes.event} dir="rtl">
          {event}
        </p>
        <div className={classes.time}>
          {userName && <span className="mx-2"> :{userName}</span>}{' '}
          <span>{time}</span>
          <span className={classes.bar}>|</span>
          <span>{day}</span>
        </div>
      </div>
      {imgPos === 'right' && (
        <div className={classes.img_container}>
          <img src={img} alt="notification created user" />
        </div>
      )}
    </div>
  );
};

export default PillBox;

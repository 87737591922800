import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Action.module.css';

const Action = ({ children, bg, to }) => {
  return (
    <section className={classes.action} style={{ background: bg }}>
      <Link to={to} className={classes.action_text}>
        {children}
      </Link>
    </section>
  );
};

export default Action;

import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import lifeAxios from '../../utils/lifeAxios';

/* Initial State for profile */
const initialState = {
  contacts: [],
  loading: false,
  error: '',
};
/* For Getting All Contacts */
export const getAllContacts = createAsyncThunk(
  'contact/getAllContacts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        '/contacts'
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Creating a new Contact */
export const createContact = createAsyncThunk(
  'contact/createContact',
  async ({ formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/contacts`,
        formData
      );
      showNotification();
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const contact = createSlice({
  name: 'contact',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllContacts.fulfilled, (state, action) => {
      state.contacts = action.payload;
    });
    builder.addMatcher(
      isAnyOf(getAllContacts.pending, createContact.pending),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllContacts.fulfilled, createContact.fulfilled),
      (state) => {
        state.loading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllContacts.rejected, createContact.rejected),
      (state, action) => {
        state.loading = true;
        state.error = action.payload;
      }
    );
  },
});

export default contact.reducer;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import AccordionPage from './AccordionPage';

const QA = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | שאלות ותשובות</title>
        <meta property="og:title" content="LifeCloud | שאלות ותשובות" />
        <meta property="og:url" content="https://lifecloud-qr.com/qa" />
        <meta
          name="description"
          content="שאלות ותשובות נפוצות שנשאלנו לגבי הפלטפורמה לקהילה מנציחה. במידה ויש לכם שאלות נוספות, אל תהססו ליצור איתנו קשר, אנחנו זמינים בשבילכם."
        />

        <meta
          property="og:description"
          content="שאלות ותשובות נפוצות שנשאלנו לגבי הפלטפורמה לקהילה מנציחה. במידה ויש לכם שאלות נוספות, אל תהססו ליצור איתנו קשר, אנחנו זמינים בשבילכם."
        />
      </Helmet>
      <AccordionPage content={qa} title="שאלות ותשובות" />
    </React.Fragment>
  );
};
const qa = [
  {
    title: `⦁ מה מכיל פרופיל בLifeCloud?
      `,
    sections: [
      {
        paragraphs: [
          {
            p: 'הפרופיל משמש בעצם כאנדרטה דיגיטלית, אשר מנציחה את חיי המנוח/ה – ',
          },
          {
            p: `פרטים אישיים, תאריך לידה ותאריך קבורה, מיקום הקבר, סיפור חיים ואבני דרך, תמונות וקבצי מדיה, הוספת חברויות, שיתוף זכרונות ועוד.
              `,
          },
          {
            p: 'הפרופיל מנגיש את סיפור החיים על פלטפורמה מוכרת כרשת חברתית.',
          },
        ],
      },
    ],
  },
  {
    title: `⦁ מה הצורך בפרופיל בLifeCloud?`,
    sections: [
      {
        paragraphs: [
          {
            p: `כשיקירנו או יקירתנו הולכים לעולמם הם משאירים אחריהם בעיקר זכרונות – חלקם מתועדים (תמונות, סרטונים, חומר כתוב) וחלקם לא.`,
          },
          {
            p: `פרופיל עוזר לך לשמור את הזכרונות האלו לעולמים ולהנגיש אותם לקרובים לך ולמנוח/ה.
              `,
          },
        ],
      },
    ],
  },
  {
    title: `⦁ מה ההבדל בין פרופיל בLifeCloud לאתר שאקים בעצמי?
      `,
    sections: [
      {
        paragraphs: [
          {
            p: `ראשית, כל אדם שמקים אתר עבור יקיר או יקירה שנפטר, הרי זה מבורך.
              `,
          },
          {
            p: `אנו מציעים פלטפורמה, נוחה ונגישה שחוסכת זמן, מבטיחה שימור של המידע לאורך זמן - ללא עלות.
              `,
          },
        ],
      },
    ],
  },

  {
    title: `⦁ מי יכול ליצור פרופיל בLifeCloud?
      `,
    sections: [
      {
        paragraphs: [
          {
            p: `כל אדם. פשוט נרשמים ועוקבים אחרי ההנחיות באתר.
              `,
          },
        ],
      },
    ],
  },
  // {
  //   title: '⦁ כמה זה עולה?',
  //   sections: [
  //     {
  //       paragraphs: [
  //         {
  //           p: 'יצירת עמוד בסיסי עבור אדם הינה בחינם. ',
  //         },
  //         {
  //           p: `העלויות ליצירת ספר חיים מפורט יותר כרוכה בעלות. את פירוט העלויות ראה בעמוד התוכניות`,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    title: `⦁ האם יש מתחרים בשוק?
      `,
    sections: [
      {
        paragraphs: [
          {
            p: `כן, יש מתחרים בארץ ובחו"ל ואנו מברכים על התחרות.
              `,
          },
          {
            p: `אנחנו בLifeCloud מאפשרים לאנשים שאיבדו את יקיריהם ומעוניינים להנציח את זכרם בדרך המכובדת`,
          },
          {
            p: `והמפרגנת ביותר לעשות זאת ללא עלות באמונה שאנו מספקים את השירות הנוח, הידידותי והחם ביותר.`,
          },
        ],
      },
    ],
  },
  {
    title: `⦁ איך משתפים חברים?
      `,
    sections: [
      {
        paragraphs: [
          {
            p: `ישנן מספר דרכים לשיתוף עוקבים.
              `,
          },
          {
            p: `ניתן לשלוח קישור לעמוד באמצעות ווטסאפ, אימייל ו-SMS.
              `,
          },
          {
            p: `בנוסף ניתן לסרוק את קוד ה- QR בנייד אם נמצאים ליד לוחית הברקוד (בחלקת הקבר למשל).
              `,
          },
        ],
      },
    ],
  },
  {
    title: `⦁ האם ישנן קטגוריות שונות בהגדרות הפרטיות של כל פרופיל?
      `,
    sections: [
      {
        paragraphs: [
          {
            p: `כן. כל מנהל פרופיל קובע בתחילת התהליך את הגדרת הפרטיות של העמוד שלו.
              `,
          },
          {
            p: `ישנן 2 קטגוריות:
              `,
          },
          {
            p: `1. פרופיל פומבי - פרופיל שכל המבקרים יכולים לצפות בו, בין אם הם חברים של אותו פרופיל ובין אם לאו.
              `,
          },
          {
            p: `2. פרופיל פרטי- פרופיל שמבקרים יכולים לראות פרטים מינימליים ובו רק מי שאושר כחבר על ידי מנהל/י העמוד (לאחר בקשת חברות) יכול לצפות ולשתף תכנים.
              `,
          },
        ],
      },
    ],
  },
  {
    title: `⦁ לכמה זמן נשמר המידע בLifeCloud?
      `,
    sections: [
      {
        paragraphs: [
          {
            p: `המידע נשמר ללא הגבלת זמן. אנו מתחייבים לתמוך את מאגר המידע באופן שוטף. `,
          },
          {
            p: `משתמש שהיה פעיל ובחר להפסיק את השתתפותו – המידע שלו נשמר ולא נמחק, אך הוא אינו זכאי לתמיכה שוטפת. `,
          },
        ],
      },
    ],
  },
  {
    title: `⦁ האם אתם מלכ"ר או חברה עסקית?
      `,
    sections: [
      {
        paragraphs: [
          {
            p: `מאחורי הרעיון להקמת הפלטפורמה עומד סיפור אישי של היזמים, אולם LifeCloud הינה חברה עסקית למטרות `,
          },
          {
            p: `רווח, אשר מקפידה על שקיפות והגינות כלפי לקוחותיה ובכלל.`,
          },
        ],
      },
    ],
  },
];
export default QA;

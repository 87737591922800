import React from 'react';
import { Helmet } from 'react-helmet-async';
import AccordionPage from './AccordionPage';

const Policy = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | מדיניות ופרטיות</title>
        <meta property="og:title" content="LifeCloud | מדיניות ופרטיות" />
        <meta property="og:url" content="https://lifecloud-qr.com/policy" />
        <meta
          name="description"
          content="אנו מזמינים אתכם לעיין במדיניות הפרטיות בכל הקשור לשימוש באתר. השימוש בפלטפורמה מהווה אישור למדיניות זו. צרו קשר להבהרות נוספות."
        />

        <meta
          property="og:description"
          content="אנו מזמינים אתכם לעיין במדיניות הפרטיות בכל הקשור לשימוש באתר. השימוש בפלטפורמה מהווה אישור למדיניות זו. צרו קשר להבהרות נוספות."
        />
      </Helmet>
      <AccordionPage content={policies} title="מדיניות פרטיות" />
    </React.Fragment>
  );
};

const policies = [
  {
    title: '1. כללי ',
    sections: [
      {
        paragraphs: [
          {
            p: 'חברת חיים בענן בע"מ (להלן: "החברה" או "אנו") מתייחסת בכבוד לפרטיותך ומחויבת לשמור ולהגן על המידע האישי שהיא אוספת ומחזיקה אודותייך. מדיניות הפרטיות להלן מתארת את האופן בו אנו אוספים מידע, סוגי המידע הנאסף, אופן השימוש במידע וכן הדרך בה אתה, כנושא המידע, יכול לעיין בו, לעדכנו או לבקש למחקו.',
          },
          {
            p: 'אנא קרא בעיון את מדיניות הפרטיות לפני שאתה משתמש באתר ו/או בשירותים המוצעים בו. מדיניות פרטיות זו הינה חלק בלתי נפרד מתנאי השימוש שלנו, בהם ניתן לעיין כאן.',
          },
          {
            p: 'בעצם הרישום, הכניסה, ההתחברות, הגישה ו/או השימוש באתר או בשירותים המוצעים בו, אתה מביע את הסכמתך לתנאים המופיעים במדיניות פרטיות זו, לרבות הסכמתך לאיסוף המידע האישי (כהגדרתו להלן), לעיבודו ולשמירתו במאגרי המידע של החברה ולשיתופו עם צדדים שלישיים, כמפורט להלן. אם אינך מסכים לתנאי מדיניות הפרטיות, אינך מורשה לגשת ו/או להשתמש באתר ו/או בשירותים המוצעים בו.',
          },
          {
            p: 'החלוקה במדיניות פרטיות זו לסעיפים וכותרות הסעיפים הינם לשם נוחות בלבד ולא תשמש לצרכי פרשנות. כל האמור במסמך זה בלשון זכר - אף נקבה במשמע. כל האמור במסמך זה בלשון יחיד - אף רבים במשמע.',
          },
          {
            p: 'מונחים אשר אין להם משמעות במדיניות פרטיות זו, תינתן להם המשמעות הנודעת להם בתנאי השימוש של האתר או בחוק הגנת הפרטיות, התשמ"א – 1981 (להלן: "חוק הגנת הפרטיות").',
          },
        ],
      },
    ],
  },
  {
    title: '2. איזה מידע אנו עשויים לאסוף? ',
    sections: [
      {
        paragraphs: [
          {
            p: 'השימוש באתר ובשירותים המוצעים בו, כולל איסוף של מידע אישי המזהה אותך או צדדים שלישיים או אשר מאפשר לנו לזהות אותך או צדדים שלישיים באופן אישי. כאשר אתה משתמש באתר, אנו עשויים לאסוף ולעבד אודותיך או אודות צדדים שלישיים אחד או יותר מסוגי המידע האישי המפורטים להלן.',
          },
        ],
      },
      {
        subTitle: '2.1 מידע אישי שנאסף ממך באופן ישיר',
        paragraphs: [
          {
            p: '2.1.1',
            noMargin: true,
          },
          {
            p: `הרשמה ופתיחת חשבון: מידע אישי ייאסף מתוך הפרטים שנמסרו על ידך במסגרת פתיחת חשבון באתר, הכוללים בין היתר, שם מלא, תאריך לידה, מספר טלפון, מס' תעודת זהות, כתובת, מקום תושבות, סטטוס משפחתי, אמצעי תשלום, פרטי חשבון בנק וכו'. פתיחת החשבון תתאפשר גם באמצעות שימוש בחשבון מדיה חברתית (בהתאם לאמור בסעיף 2.4 להלן).`,
          },
          {
            p: '2.1.2',
            noMargin: true,
          },
          {
            p: 'מידע אודות ואגב שימוש בשירותים: אנו נאסוף מידע אישי אודותיך או אודות צדדים שלישיים אשר תמסור לנו כחלק מהשימוש בשירותי האתר, לרבות מידע הנאסף במסגרת הליך ההנצחה, לדוגמא, אילן יוחסין, תמונות, קבצי וידאו, קבצי קול, סיפורי הנצחה, קורות חיים, תאריכי פטירה ואזכרות, וכן מידע אודות השימוש שלך בשירותים שלנו ו/או ההתקשרות בינינו (פעולות שתבצע, הוראות והודעות שתמסור, לרבות בקשות לצרכי סיוע טכני, מתן משובים ומענה לסקרים, וכיוצא בזאת), ובין היתר מידע אשר עשוי להימסר על ידך כחלק מההתכתבויות עמנו באמצעות הדואר האלקטרוני, בטלפון, או בכל אמצעי תקשורת אחר.',
          },
          {
            p: '2.1.3',
            noMargin: true,
            bold: true,
          },
          {
            p: 'המידע האישי שתמסור נמסר על ידיך ללא כל מחויבות חוקית לעשות כן ומרצונך החופשי ובהסכמתך. ככל שתמסור לנו מידע אישי גם אודות צדדים שלישיים, הנך מצהיר כי יש בידיך את כל ההרשאות החוקיות ו/או קיבלת את הסכמתם כל נושאי המידע או צדדים שלישיים לחלוק עמנו מידע אישי זה, למטרות המפורטות במדיניות פרטיות זו ובמידת הצורך, הבאת לידיעתם של אותם צדדים שלישיים הוראות מדיניות פרטיות זו. אנו לא נישא באחריות בכל הנוגע לאי קבלת הסכמה, מסירת מידע ללא הרשאה חוקית או יידוע אותם צדדים שלישיים בקשר לאיסוף ועיבוד מידע אודותיהם על ידי החברה.',
            bold: true,
          },
        ],
      },
      {
        subTitle: '',
        paragraphs: [
          {
            p: `המידע האישי שתמסור נמסר על ידיך ללא כל מחויבות חוקית לעשות כן ומרצונך החופשי ובהסכמתך. ככל שתמסור לנו מידע אישי גם אודות צדדים שלישיים, הנך מצהיר כי יש בידיך את כל ההרשאות החוקיות ו/או קיבלת את הסכמתם כל נושאי המידע או צדדים שלישיים לחלוק עמנו מידע אישי זה, למטרות המפורטות במדיניות פרטיות זו ובמידת הצורך, הבאת לידיעתם של אותם צדדים שלישיים הוראות מדיניות פרטיות זו. אנו לא נישא באחריות בכל הנוגע לאי קבלת הסכמה, מסירת מידע ללא הרשאה חוקית או יידוע אותם צדדים שלישיים בקשר לאיסוף ועיבוד מידע אודותיהם על ידי החברה.`,
          },
        ],
      },
      {
        subTitle: '2.2 מידע אישי שאנו עשויים להפיק לגביך',
        paragraphs: [
          {
            p: `כאשר אתה משתמש באתר ובשירותים שלנו, הנתונים והמידע שאנו אוספים לגביך (למשל, פעולות שביצעת או הוראות שמסרת) עשויים להיות מעובדים, הן באופן ידני והן באמצעות כלים ממוחשבים ואוטומטיים, על מנת להפיק תובנות אשר יאפשרו לנו לשפר את השירותים, להתאימם לפעילויות שלך וכו'.`,
          },
        ],
      },
      {
        subTitle: '2.3 מידע נוסף',
        paragraphs: [
          {
            p: '2.3.1',
            noMargin: true,
          },
          {
            p: `נתוני מיקום גיאוגרפי: בעת השימוש שלך באתר ובשירותים המוצעים בו (לדוג' ביקורים בבתי עלמין או אתרי הנצחה), אנו נאסוף ונעבד נתונים אודות מיקומך הגיאוגרפי, לרבות באמצעות ניתוח של כתובות IP, ומידע דומה אחר על מנת לקבוע את מיקומך למטרות הנגשת השירותים ושימוש אנליטי במידע לצורך שיפור והתאמה של השירותים.`,
          },
          {
            p: '2.3.2',
            noMargin: true,
          },
          {
            p: `מידע טכני והתנהגותי: איסוף מידע אנונימי ואינו מזהה (להלן: "מידע לא אישי") מהמכשיר שלך, כדוגמת סוג והגרסה של המכשיר שלך, מערכת ההפעלה, סוג הדפדפן, רזולוציית המסך, שפת המקלדת, קישוריות Wi-Fi וכו'.`,
          },
          {
            p: '2.3.3',
            noMargin: true,
          },
          {
            p: 'מזהים: כאשר אתה משתמש באתר, אתה מספק לנו באופן אוטומטי את כתובת ה-IP  שלך (או מספר ה-Unique Device Identifier, לפי המקרה), או מזהים אחרים בהתאם לרשת או למכשיר בו אתה משתמש. מידע זה נאסף על ידינו על מנת לשפר את חוויית המשתמש שלך, כדי לקבוע את מיקומך הגיאוגרפי ולמטרות אבטחה.',
          },
          {
            p: '2.3.4',
            noMargin: true,
          },
          {
            p: 'סטטיסטיקות ומדידות: אנו עשויים, בעצמנו או באמצעות ספקי שירותים חיצוניים כגון Google Analytics כדי להפיק מדידות, סטטיסטיקות וניתוחים מפורטים על התנהגותך באתר, דפי הנחיתה ובשירותים שלנו באופן כללי.',
          },
          {
            subTitle: 'מידע שנתקבל מחשבונות מדיה חברתית',
            paragraphs: [
              {
                p: `האתר מאפשר למשתמשים בו ליצור חשבון באתר באמצעות פרטי חשבון המדיה החברתית של צד שלישי בבעלות המשתמש, כגון Facebook או Google או כל פלטפורמת רשת חברתית, כפי שתוצע באתר, מעת לעת ("חשבון המדיה החברתית"). ככל והמשתמש בחר ליצור חשבון ו/או להירשם לאתר באמצעות חשבון המדיה החברתית, תינתן לנו גישה לפרטים מסוימים אודותיך הכלולים בפרופיל המשתמש בחשבון המדיה החברתית שלו שיתקבלו מספק המדיה החברתית. מידע זה עשוי להשתנות בהתאם לספק, אולם מידע כאמור עשוי לכלול שם, כתובת דוא"ל, תמונת פרופיל, רשימת חברים, ומידע נוסף אותו סיווג המשתמש כפומבי.`,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    title: '3. כיצד אנו משתמשים במידע? ',
    sections: [
      {
        subTitle: '3.1 אנו נעשה שימוש במידע אישי למטרות עסקיות לגיטימיות ובהן:',
        paragraphs: [
          {
            p: '(1) כדי לאפשר לך להשתמש באתר האינטרנט של החברה ובשירותים המוצעים בו',
          },
          {
            p: '(2) על-מנת להעניק לך שירותים שונים, בהתאם לאופי וסוג ההתקשרות בינך לבין החברה, לרבות ניהול וגביית תשלומים;',
          },
          {
            p: '(3) ליצירת קשר בהמשך לפנייתך אלינו, או כאשר אנו סבורים שקיים צורך בכך לקיום הוראה אחרת על פי דין;',
          },
          {
            p: '(4) לספק תמיכה (לרבות שליחת הודעות שירות ותחזוקה) ולטפל בבקשות או בתלונות, וכן על מנת לגלות, למנוע או לטפל באופן אחר בתרמית, הונאה או בסוגיות אבטחה ובטחון;',
          },
          {
            p: '(5) לצורך דיוור שיווקי (כמפורט במדיניות פרטיות זו) ופרסום, לרבות פרסום מותאם אישית, בהתאם ובכפוף להוראות הדין;',
          },
          {
            p: '(6) לצרכים ניהוליים, תפעוליים ואחרים הנדרשים לצורך תפעול האתר ו/או השירותים המוצעים בו וכן לכל צורך עסקי לגיטימי של החברה;',
          },
          {
            p: '(7) לצורך מחקר וניתוח סטטיסטי, ביצוע סקרים ומשובים, על מנת לשפר את השירות ו/או התאמת מוצרים או שירותים ו/או תפעול עסקי החברה;',
          },
          {
            p: '(8) על מנת למלא אחר דרישות כל חוק, תקנה או דבר חקיקה אחר וכן על מנת לסייע לרשויות מוסמכות ולכל צד שלישי, כשאנו סבורים בתום לב כי עלינו לעשות כן.',
          },
          {
            p: '(9) מבלי לגרוע מהאמור לעיל, אנו נהיה רשאים לעשות שימוש במידע לא אישי למטרות לגיטימיות שונות, למשל: לצורך שיפור שירותים ויישומים; התאמה של תכנים, שירותים ופרסומות; מחקר סטטיסטי, וכיו"ב.',
          },
        ],
      },
    ],
  },
  {
    title: '4. שיתוף מידע עם צדדים שלישיים ',
    sections: [
      {
        subTitle:
          '4.1 אנו לא נעביר לצדדים שלישיים את המידע האישי שנאסף אודותיך, בלא הסכמתך, אלא במקרים המפורטים להלן:',
        paragraphs: [
          {
            p: `(1)ככל שהדבר דרוש לצורך הפעלת האתר ואספקה תקינה של שירותים ותכנים המוצעים דרכו, לרבות ספקי דיוור, חברות אחסון מידע וכו';`,
          },
          {
            p: '(2) כדי להגן על זכויותינו ועל מנת למלא כל חובה חוקית החלה עלינו.',
          },
          {
            p: '(3) שיתוף מידע עם ספקי דיוור ופרסום, בהתאם ובכפוף לדרישות הדין;',
          },
          {
            p: '(4) אם נידרש לעשות כן על פי צו שיפוטי, דרישה של רשות מוסמכת או לפי דין;',
          },
          {
            p: '(5) אם נקבל התראה לנקיטה בהליכים משפטיים נגדנו בגין פעולות שבוצעו על ידיך וכן בכל מחלוקת, טענה, תביעה, דרישה או הליכים משפטיים, אם יהיו, בינך לבין החברה ו/או מי מטעמה;',
          },
          {
            p: '(6) במסגרת שינוי המבנה הארגוני והתפעולי של החברה (לרבות במצב שבו החברה תארגן את פעילותה במסגרת גוף אחר - לרבות מיזוג עם גוף אחר) – אז נהיה זכאים להעביר לגוף האחר את המידע האישי או כל מידע אחר שנצבר אודותיך, ובלבד שהגוף האחר יקבל על עצמו כלפיך את הוראות מדיניות פרטיות זו;',
          },
          {
            p: '(7) אם תועלה טענה או יתעורר חשד אצל החברה כי ביצעת מעשה ו/או מחדל הפוגעים ו/או העלולים לפגוע בה ו/או במי מטעמה ו/או בצדדים שלישיים כלשהם;',
          },
          {
            p: '(8) על פי בקשתך המפורשת.',
          },
        ],
      },
      {
        subTitle:
          '4.2 הנך מסכים לכך שהחברה תהיה רשאית להעביר, על פי שיקול דעתה, את המידע האישי אל מחוץ לגבולות ישראל, הגם שדין המדינה אליה מועבר ו/או בה מאוחסן המידע האישי מבטיח רמת הגנה שונה מרמת ההגנה על מידע הקבועה בדין הישראלי.',
      },
    ],
  },
  {
    title: '5. דיוור שיווקי ',
    sections: [
      {
        paragraphs: [
          {
            p: '5.1 החברה רשאית, אך לא חייבת, לשלוח למשתמש פרסומים, מבצעים, הנחות, עדכונים, חידושים וכדומה (ביחד: "דיוור שיווקי") מעת לעת, בין היתר באמצעות הודעות דואר אלקטרוני או SMS, בכפוף לקבלת הסכמת המשתמש בהתאם לאמור בסעיף 30א לחוק התקשורת (בזק ושידורים), התשמ"ב – 1982 או בהתאם לסעיף 30א(ג) לחוק התקשורת (בזק ושידורים), התשמ"ב – 1982. דיוור שיווקי זה עשוי להכיל מוצרים ושירותים של החברה או מוצרים ושירותים של צדדים שלישיים כגון שותפיה העסקיים של החברה.',
          },
          {
            p: '5.2 אם הנך מעוניין שלא לקבל פניות באמצעות דיוור שיווקי הנך רשאי, בכל עת, להודיע על כך לחברה בהתאם לפרטי ההתקשרות המופיעים במדיניות זו להלן או בהתאם לפרטים המופיעים בדיוור עצמו, לפי בחירתך. מובהר כי החברה תהיה רשאית לשלוח לך דיוור שיווקי על סמך תוצרי עיבוד ואפיון המידע האישי שלך שנמסר על ידך ו/או מידע אודות פעילותך באתר ו/או מידע האגור במערכות החברה, וזאת על מנת להציע לך מוצרים או שירותים שונים שעשוי להיות לך עניין בהם.',
          },
        ],
      },
    ],
  },
  {
    title: '6. Cookies ',
    sections: [
      {
        paragraphs: [
          {
            p: '6.1 החברה ו/או מי מטעמה עושה שימוש באתר בקבצים המכונים cookies ופיקסלים או כלי תוכנה אחרים (להלן: "Cookies") שמקורם בצדדים שלישיים בהתאם לתנאי השימוש והפרטיות של אותם צדדים שלישיים, שמותקנים על המערכות והתוכנות של המשתמש כאשר זה עושה שימוש באינטרנט, בין היתר לצורך אפיון, ניטור ומעקב אחר הפעילות של המשתמש, לזהות דפוסי שימוש, בירור העדפותיו, מתן אפשרות להצגת פרסומות המותאמות להעדפותיו של המשתמש בהתאם לניתוחים ובכדי להפוך את האתר מותאם יותר לחוויה של המשתמש, לצרכיו ולמאפייניו הייחודים. הקבצים הללו מאפשרים לחברה ו/או מי מטעמה לייחס מידע הנוגע לשימוש באתר למשתמש ספציפי ולמידע הנוגע לאותו משתמש.',
          },
          {
            p: '6.2 קבצי ה-Cookies הם למעשה קבצי טקסט (או שורות קוד), הנוצרים במכשיר הקצה, ככל שאפשר המשתמש זאת בהגדרות מכשיר הקצה, ואשר אוספים מידע כגון משך השהייה של המשתמש בעמוד/מסך מסוים באתר, אופן הגלישה של המשתמש באתר, פעולות שביצע המשתמש באתר, זיהוי כתובת IP, מיקום, מועדי התחברות ועוד. ',
          },
          {
            p: '6.3מובהר כי באפשרות המשתמש לעדכן או לשנות בכל עת את הגדרות מכשיר הקצה ולחסום באופן גורף או חלקי את השימוש בקבצי Cookies, אך ייתכן כי במקרה זה שירותים מסוימים, כולם או חלקם, לא יפעלו כראוי. שינוי הגדרות כאמור הנו באחריות המשתמש בלבד. דפדפנים מודרניים כוללים אפשרות להימנע מקבלת עוגיות. אם אינך יודע כיצד לעשות זאת, בדוק בקובץ העזרה של הדפדפן שבו אתה משתמש על מנת לבחון אפשרות להסיר את אופציית ה-Cookies.',
          },
        ],
      },
    ],
  },
  {
    title: '7. זכות לעיין במידע ',
    sections: [
      {
        paragraphs: [
          {
            p: '7.1 על-פי חוק הגנת הפרטיות, כל אדם זכאי לעיין בעצמו, או על ידי בא-כוחו שהרשהו בכתב או על ידי אפוטרופוס, במידע שעליו המוחזק במאגר מידע. אדם שעיין במידע שעליו ומצא שאינו נכון, שלם, ברור או מעודכן, רשאי לפנות לבעל מאגר המידע בבקשה לתקן את המידע או למחקו. אם בעל המאגר סירב, עליו להודיע על כך למבקש באופן ובדרך שנקבעו בתקנות. על סירובו של בעל מאגר מידע לאפשר עיון ועל הודעת סירוב לתקן או למחוק מידע, רשאי מבקש המידע לערער באופן ובדרך שנקבעו בתקנות.',
          },
          {
            p: '7.2 פנייה כזאת יש להפנות לחברה, באמצעות דואר אלקטרוני: office@lifecloud-qr.com.',
          },
          {
            p: '7.3 בנוסף, אם המידע שבמאגר משמש לצורך פניה אישית אליך,  אתה זכאי על-פי חוק הגנת הפרטיות לדרוש בכתב שהמידע המתייחס אליך יימחק ממאגר המידע.',
          },
        ],
      },
    ],
  },
  {
    title: '8. אבטחת מידע ',
    sections: [
      {
        paragraphs: [
          {
            p: '8.1 החברה משקיעה מאמצים רבים ביישום ובשמירה על אבטחת המידע האישי אודותיך, בהתאם להוראות הדין ובסטנדרטים גבוהים וכמקובל בענף, והכל על מנת להבטיח את אבטחת המידע אודותיך ועל מנת למנוע כל שימוש לא מורשה במידע זה.',
          },
          {
            p: '8.2 רשתות תקשורת, מחשבים, שרתים ואתרי אינטרנט חשופים להתקפות וניסיונות פריצה מצד גורמים שונים. עליך לדעת כי לא ניתן להבטיח אבטחת מידע הרמטית וייתכנו פריצות אבטחה וחדירות לאתר. החברה אינה מתחייבת שהאתר יהא חסין באופן מוחלט מפני גישה בלתי-מורשית למידע, לרבות למידע האישי, המאוחסן בהם. בגלישה באתר, בהרשמה ובמסירת מידע אישי, הנך משחרר את החברה ו/או מי מטעמה מאחריות לכל נזק שייגרם לו ו/או למי מטעמו עקב התקפות, ניסיונות פריצה וחדירות למידע כאמור ומוותר על כל טענה כנגד החברה ו/או מי מטעמה בקשר לכך.',
          },
        ],
      },
    ],
  },
  {
    title: '9. שינויים במדיניות הפרטיות ',
    sections: [
      {
        paragraphs: [
          {
            p: 'החברה שומרת לעצמה את הזכות להוסיף, לשנות, לגרוע ולהחליף בכל עת את הוראות מדיניות פרטיות זו, כולן או חלקן, לפי שיקול דעתה, וללא קבלת הסכמת המשתמשים או יידועם מראש, למעט במקרה של יידוע המשתמשים בעת עריכת שינויים מהותיים. שינויים מהותיים ייכנסו לתוקפם בתום שלושים (30) ימים ממתן ההודעה עליהם, למעט אם השינוי נובע מדרישה חוקית או רגולטורית או אז ייכנס השינוי לתוקפו על פי אותה דרישה. נוסחה המחייב של מדיניות הפרטיות הוא זו שתופיע מעת לעת באתר. באחריותך להתעדכן בשינויים שחלו, אם חלו, במדיניות הפרטיות. המשך השימוש באתר ובשירותים המוצעים בו לאחר ביצוע שינוי כאמור יהווה ראיה לאישורך בדבר קבלת השינויים. מומלץ לעיין בהוראות מדיניות הפרטיות בכל כניסה מחודשת לאתר על מנת לעיין בשינויים שחלו (אם חלו) במדיניות הפרטיות. ',
          },
          {
            p: 'תאריך עדכון אחרון: 07/05/2022',
          },
        ],
      },
    ],
  },
];

export default Policy;

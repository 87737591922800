import React from 'react';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import classes from '../PaymentStatus/PaymentStatus.module.css';
import Button from '../../components/UI/Button/Button';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const PaymentStatus = () => {
  const { status } = useParams();
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | בית חדש לחיים שאחרי</title>
        <meta
          property="og:url"
          content={`https://lifecloud-qr.com/process/${status}`}
        />
      </Helmet>
      <CenterContentLayout bg="#EDF5F9">
        {status.startsWith('success') ? (
          <div className="container">
            <h2 className={classes.payment_status_title}>
              :) <br />
              !תשלום עבר בהצלחה
            </h2>
            <Link to={`/profile/${localStorage.getItem('profileId')}`}>
              <Button className={classes.payment_status_button}>
                חזרה לדף
              </Button>
            </Link>
          </div>
        ) : (
          <div className="container">
            <h2 className={classes.payment_status_title}>
              :( <br />
              !תשלום לא עבר
            </h2>
            <div className="d-flex gap-3 gap-md-5 justify-content-center">
              <Link to={`/profile/${localStorage.getItem('profileId')}`}>
                <Button className={classes.payment_status_button}>
                  חזרה לדף
                </Button>{' '}
              </Link>
              <Link to={`/profile/${localStorage.getItem('profileId')}`}>
                <Button
                  className={`${classes.payment_status_button} ${classes.payment_status_button_second}`}
                >
                  נסה שנית
                </Button>
              </Link>
            </div>
          </div>
        )}
      </CenterContentLayout>
    </React.Fragment>
  );
};

export default PaymentStatus;

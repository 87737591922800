import React, { useState, useEffect  } from 'react';
import classes from './AddressFormModal.module.css';
import cross from '../assets/candleflower-modal-img/x.png';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../features/modal/modalSlice';

const AddressFormModal = ({ register, onSubmit, handleSubmit, type }) => {
  const { addressModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [city, setCity] = useState('');
  const [streetAddress, setstreetAddress] = useState('');
  const [postBox, setpostBox] = useState('');
  const [fullAddress, setFullAddress] = useState('');

  useEffect(() => {
    const fa = `${city} ${streetAddress} ${postBox}`
    setFullAddress(fa);
    }, [city, streetAddress, postBox])
    

  console.log('Rendering AddressFormModal...'); 
  return (
    <React.Fragment>
      <Modal
        show={addressModal}
        onHide={() => dispatch(hideModal('addressModal'))}
        size="lg"
        centered
      >
        <Modal.Header style={{ border: 0, background: '#1A4E74' }}>
          <h5 className={`${classes.modal_title} m-auto`} dir="rtl">
            כתובת משלוח ל-QR שלכם!
          </h5>
          <button
            type="button"
            className={`${classes.btn_close}  btn align-self-start`}
            onClick={() => dispatch(hideModal('addressModal'))}
          >
            <img src={cross} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          <div className={`row ${classes.input_container}`}>
            <div className="col-md-8 col-12 m-auto">
              <div className="row">
          <div className={classes.address_form_text}>
            <p>
              * יש להזין את פרטי יצירת הקשר עם האדם שיקבל את החבילה וכתובת המשלוח אליה תרצו לקבל את הברקודים החדשים שהזמנתם.
            </p>
          </div>
                <div className="col-12">
                  <div className={classes.input_item}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" *שם מלא"
                      {...register('fullName', { required: addressModal })}
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className={classes.input_item}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="*מס' טלפון"
                      {...register('phone2', { required: addressModal })}
                      required
                    />
                    <input
                      type="text"
                      className="form-control"
                      value= {fullAddress}
                      {...register('address', { value: fullAddress })}
                      style={{ display: 'none' }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className={classes.input_item} style={{ marginTop: 10 }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ככתובת מלאה (שם רחוב, מספר בית, מספר דירה)"
                      onChange={(e) => {
                      setstreetAddress(e.target.value);
                      }}
                    />
                  </div>                
                <div className={classes.input_item} style={{ marginTop: 10 }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="*עיר"
                      onChange={(e) => {
                      setCity(e.target.value);
                      }}
                    />
                  </div>
                  <div className={classes.input_item} style={{ marginTop: 10 }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="מיקוד"
                      onChange={(e) => {
                      setpostBox(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <div className={`${classes.button_container}`}>
            <button
            disabled = {city.trim()===''}
              onClick={(e) => {
                handleSubmit((data) => onSubmit(data, type))(e)
              }}
            >
              להמשך ורכישה
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddressFormModal;

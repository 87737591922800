import React, { useReducer, useState } from 'react';
import classes from './CandleFlowerModal.module.css';
import candle from '../assets/candleflower-modal-img/candle.png';
import flower from '../assets/candleflower-modal-img/flower.png';
import cross from '../assets/candleflower-modal-img/x.png';
import cross2 from '../assets/candleflower-modal-img/white.png';
import Button from '../components/UI/Button/Button';
const initialState = {
  candle: 0,
  flower: 0,
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'INCREASE_FLOWER':
      return {
        ...state,
        flower: state.flower + 1,
      };
    case 'DECREASE_FLOWER':
      return {
        ...state,
        flower: state.flower > 0 ? state.flower - 1 : 0,
      };
    case 'INCREASE_CANDLE':
      return {
        ...state,
        candle: state.candle + 1,
      };
    case 'DECREASE_CANDLE':
      return {
        ...state,
        candle: state.candle > 0 ? state.candle - 1 : 0,
      };
    case 'RESET_FLOWER':
      return {
        ...state,
        flower: 0,
      };
    case 'RESET_CANDLE':
      return {
        ...state,
        candle: 0,
      };
    case 'RESET':
      return {
        flower: 0,
        candle: 0,
      };

    default:
      return state;
  }
};
const CandleFlower = ({ handleFormSubmit }) => {
  const [candleFlowerState, dispatch] = useReducer(reducer, initialState);
  const [dedication, setDedication] = useState('');

  return (
    <React.Fragment>
      <div
        className="modal fade qr-modal"
        id="candleFlower"
        tabIndex="-1"
        aria-labelledby="candleFlowerLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className={`modal-content ${classes.modal_content}`}>
            <div className="modal-header justify-content-between">
              <h5
                className={`${classes.modal_title} m-auto`}
                id="candleFlowerLabel"
              >
                חולקים כבוד עם המשפחה והחברים
              </h5>
              <button
                type="button"
                className={`${classes.btn_close}  btn align-self-start`}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src={cross} alt="Close" />
              </button>
            </div>

            <div
              className={`modal-body text-center p-0 overflow-hidden ${classes.profile_candle_modal}`}
            >
              <div className="row">
                <div className="col-6 p-0 position-relative overflow-hidden">
                  <div
                    className={`position-absolute ${classes.popup_image_text}`}
                  >
                    <h3>הדלק נר וירטאולי</h3>
                    <h6>בעלות של 5 ש״ח</h6>
                    {/* <p>בעלות של 5 ש״ח</p> */}
                  </div>
                  <img className="img-fluid" src={candle} alt="FlowerLight" />

                  <Button
                    className={`position-absolute ${classes.profile_candle_btn} w-50`}
                    onClick={() => dispatch({ type: 'INCREASE_CANDLE' })}
                  >
                    בחר
                  </Button>
                </div>
                <div className=" col-6 p-0 position-relative overflow-hidden">
                  <div
                    className={`position-absolute ${classes.popup_image_text}`}
                  >
                    <h3>הנחת פרח וירטאולי</h3>
                    <h6>בעלות של 5 ש״ח</h6>
                    {/* <p>בעלות של 5 ש״ח</p> */}
                  </div>
                  <img className="img-fluid" src={flower} alt="FlowerLight" />

                  <Button
                    className={`position-absolute ${classes.profile_candle_btn} w-50`}
                    onClick={() => dispatch({ type: 'INCREASE_FLOWER' })}
                  >
                    בחר
                  </Button>
                </div>
              </div>

              {(candleFlowerState.candle > 0 ||
                candleFlowerState.flower > 0) && (
                <form
                  className="container"
                  onSubmit={(e) => {
                    dispatch({ type: 'RESET' });
                    handleFormSubmit(e, candleFlowerState, dedication);
                  }}
                >
                  <h4 className={`fw-bold mb-3 ${classes.counter_text}`}>
                    סיכום
                  </h4>
                  <div className="row">
                    <div className="col-lg-10 mx-auto">
                      {candleFlowerState.candle > 0 && (
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div
                            className={`d-flex justify-content-between text-white align-items-center px-3 rounded-3 w-100 ${classes.counter}`}
                          >
                            <div>
                              <span
                                className="fw-bold pointer"
                                onClick={() =>
                                  dispatch({ type: 'INCREASE_CANDLE' })
                                }
                              >
                                +
                              </span>
                              <span className="mx-2 fw-bold">
                                {candleFlowerState.candle}
                              </span>
                              <span
                                className="fw-bold pointer"
                                onClick={() =>
                                  dispatch({ type: 'DECREASE_CANDLE' })
                                }
                              >
                                -
                              </span>
                            </div>
                            <div>
                              <h5 className="text-white m-0">נר וירטואלי</h5>
                            </div>
                          </div>
                          <button
                            type="button"
                            className={classes.btn_close_sm}
                            onClick={() => dispatch({ type: 'RESET_CANDLE' })}
                          >
                            <img src={cross2} alt="Close" />
                          </button>
                        </div>
                      )}
                      {candleFlowerState.flower > 0 && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className={`d-flex justify-content-between text-white align-items-center px-3 rounded-3 w-100 ${classes.counter}`}
                          >
                            <div>
                              <span
                                className="fw-bold pointer"
                                onClick={() =>
                                  dispatch({ type: 'INCREASE_FLOWER' })
                                }
                              >
                                +
                              </span>
                              <span className="mx-2 fw-bold">
                                {candleFlowerState.flower}
                              </span>
                              <span
                                className="fw-bold pointer"
                                onClick={() =>
                                  dispatch({ type: 'DECREASE_FLOWER' })
                                }
                              >
                                -
                              </span>
                            </div>
                            <div>
                              <h5 className="text-white m-0">פרח וירטואלי</h5>
                            </div>
                          </div>
                          <button
                            type="button"
                            className={classes.btn_close_sm}
                            onClick={() => dispatch({ type: 'RESET_FLOWER' })}
                          >
                            <img src={cross2} alt="Close" />
                          </button>
                        </div>
                      )}
                      <div className={classes.dedicated_texts}>
                        <h4 className="mt-3">הוסיפו הקדשה אישית</h4>
                        <input
                          type="text"
                          placeholder="כתבו הקדשה ליקיריכם או למשפחתם שתופיע עם הנר/פרח..."
                          className="form-control"
                          onChange={(e) => setDedication(e.target.value)}
                          value={dedication}
                        />
                      </div>
                      <Button className={`w-50 my-4`} type="submit">
                        לחץ להנחת נר\פרח
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        {/* Here need to design the payment */}
        {/* <div></div> */}
      </div>
    </React.Fragment>
  );
};

export default CandleFlower;

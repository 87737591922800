const getFileType = (fileName) => {
  if (!fileName) return null;
  const imageExt = ['gif', 'jpg', 'jpeg', 'png', 'avif', 'webp', 'svg'];
  const videoExt = ['webm', 'mov', 'mkv', 'mp4'];
  const pdfExt = ['pdf'];
  /* Getting the file extension */
  const fileExt = fileName.split('.').pop();
  /* Return according the file extension */
  if (imageExt.includes(fileExt)) return 'image';
  if (videoExt.includes(fileExt)) return 'video';
  if (pdfExt.includes(fileExt)) return 'pdf';
};
export default getFileType;
